import React, { useEffect } from "react"
import  config  from "react-global-configuration";

let isDisconnected = false

function withNetworkDetector(WrappedComponent: React.FC<any>): React.FC<any> {
  return (props): any => {
    useEffect(() => {
      const handleChangeConnection = () => {
        const condition = navigator.onLine ? "online" : "offline"
        if (condition === "offline") {
          isDisconnected = true
        }
        if (isDisconnected && condition === "online") {
          isDisconnected = false
          window.location.reload()
        }
      }
      const interval = setInterval(handleChangeConnection, config.get("checkInternetLobbyInterval") || 5000)

      return () => {
        clearInterval(interval)
      }
    }, [])

    return (
      <div>
        <WrappedComponent {...props} />
      </div>
    )
  }
}

export default withNetworkDetector
