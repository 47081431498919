import { Button } from "components/Button"
import { Buttons, Card } from "components/Card/Card"
import PreviousButton from "components/Previous/PreviousButton"
import { SURVEY_PAGE } from "core/constants"
import { useEffect } from "react"
import { connect } from "react-redux"
import { Action, ActionWithoutPayload, Message } from "types/redux"
import { ReaderState } from "types/sesam"
import { NirReaderResponse, NirReaderStore } from "types/store"
import {
  cancelNirReader,
  requestNirReader,
  resetNirReader,
} from "./services/actions"
import { cdn } from "core/cdn"
import Loader from "components/Loaders/Loader/Loader"
import { useLanguage } from "locales"

interface ParentProps {
  onFinish: (a: NirReaderResponse) => void
  hasNir: (hasNir: boolean) => void
  manual?: boolean
}

const UiReader = (props: {
  message?: Message
  title: string
  loading?: boolean
  cancel?: () => void
  request?: () => void
  reset?: () => void
  buttonText?: string
  hideButtons?: boolean
}) => {
  const { languages } = useLanguage()
  return (
    <Card
      title={props.title}
      message={props.message}
      imgUrl={cdn("/images/carte_vitale_illustration.svg")}
    >
      <PreviousButton
        onClick={() => props.reset && props.reset()}
        to={SURVEY_PAGE}
      />

      {props.hideButtons ? null : (
        <Buttons direction="vertical">
          <Button
            type="primary"
            wide="long"
            disabled={props.loading}
            loading={props.loading}
            className="disable-like-able"
            onClick={() => {
              props.request && props.request()
            }}
          >
            {props.buttonText || languages.next}
          </Button>
          <Button onClick={() => props.cancel && props.cancel()} type="link">
            {languages.nirManualInput}
          </Button>
        </Buttons>
      )}
    </Card>
  )
}

const NirReader = (props: NirReaderStore & ParentProps): JSX.Element => {
  const { languages } = useLanguage()
  useEffect(() => {
    if (props.readerState === ReaderState.INITIALIZE) props.request()
  }, [])

  switch (props.readerState) {
    case ReaderState.INITIALIZE:
      return (
        <UiReader
          {...props}
          title={languages.readerInitialize}
          loading={true}
          buttonText={languages.waitingForNirReader}
        />
      )
    case ReaderState.INSERT:
      return (
        <UiReader
          {...props}
          title={languages.insertYourNir}
          loading={true}
          buttonText={languages.waintingForCardInsertion}
        />
      )
    case ReaderState.REMOVE:
      return <UiReader title={languages.removeYourNir} hideButtons={true} />
    case ReaderState.REMOVED:
      if (props.loading === false) {
        const nirReaderResponse: NirReaderResponse =
          props.response as NirReaderResponse
        props.reset()
        props.onFinish({ ...nirReaderResponse })
      }
      return <UiReader title={languages.removeYourNir} hideButtons={true} />
    case ReaderState.ERROR:
      return (
        <UiReader
          {...props}
          title={languages.relaunchReader}
          loading={false}
          buttonText={languages.tryAgain}
        />
      )
    case ReaderState.CANCELED:
      props.reset()
      props.hasNir(false)
      return <Loader />
  }
}

const mapStateToProps = ({
  nirReader,
}: {
  nirReader: NirReaderStore
}): NirReaderStore => {
  return nirReader
}

const mapDispatchToProps = (
  dispatch: (a: Action<unknown> | ActionWithoutPayload) => void
): Partial<NirReaderStore> => {
  return {
    request: () => dispatch(requestNirReader()),
    cancel: () => dispatch(cancelNirReader()),
    reset: () => dispatch(resetNirReader()),
  } as Partial<NirReaderStore>
}

export default connect(mapStateToProps, mapDispatchToProps)(NirReader)
