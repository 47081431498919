import { omit } from "lodash"
import config from "react-global-configuration"
import { all, put, takeLatest } from "redux-saga/effects"

import Compliance from "lib/api-compliance"
import { request } from "lib/request"
import { Carer, Relationship, RELATIONSHIP } from "types/entity"
import { CarerApi, ResponseAPI } from "types/payload"
import { Action, ActionWithoutPayload, GWatcher } from "types/redux"

import { ADD_PATIENT_CARERS_ERROR, ADD_PATIENT_CARERS_REQUEST, ADD_PATIENT_CARERS_SUCCESS, ADD_PHARMACY_CARERS_ERROR, ADD_PHARMACY_CARERS_REQUEST, ADD_PHARMACY_CARERS_SUCCESS, ADD_RELATIONSHIP_PATIENT_CARERS_ERROR, ADD_RELATIONSHIP_PATIENT_CARERS_REQUEST, ADD_RELATIONSHIP_PATIENT_CARERS_SUCCESS, DELETE_PATIENT_CARER_ERROR, DELETE_PATIENT_CARER_REQUEST, DELETE_PATIENT_CARER_SUCCESS, DELETE_PHARMACY_CARER_ERROR, DELETE_PHARMACY_CARER_REQUEST, DELETE_PHARMACY_CARER_SUCCESS, GET_IDENTITY_FROM_RPPS_ERROR, GET_IDENTITY_FROM_RPPS_REQUEST, GET_IDENTITY_FROM_RPPS_SUCCESS, GET_PATIENT_CARERS_ERROR, GET_PATIENT_CARERS_REQUEST, GET_PATIENT_CARERS_SUCCESS, GET_PHARMACY_CARERS_ERROR, GET_PHARMACY_CARERS_REQUEST, GET_PHARMACY_CARERS_SUCCESS, GET_RELATIONSHIP_PATIENT_CARERS_ERROR, GET_RELATIONSHIP_PATIENT_CARERS_REQUEST, GET_RELATIONSHIP_PATIENT_CARERS_SUCCESS } from "./constants"
import { languages } from "locales/languages"

async function getPharmacyCarersApi() {
  return await request(config.get("admin.carers"), {
    method: "GET",
  })
}
async function addPharmacyCarersApi(payload: Carer) {
  return await request(config.get("admin.carers"), {
    method: "POST",
    payload,
  })
}
async function deletePharmacyCarerApi(payload: Carer) {
  return await request(config.get("admin.carers") + `/${payload.guid}`, {
    method: "DELETE",
  })
}

async function deletePatientCarerApi(payload: Carer) {
  return await request(
    config.get("patient.delete.carers") + `/${payload.guid}`,
    {
      method: "DELETE",
    }
  )
}

async function getPatientCarersApi() {
  return await request(config.get("patient.get.carers"), {
    method: "GET",
  })
}

async function addPatientCarersApi(payload: Carer) {
  return await request(config.get("patient.post.carers"), {
    method: "POST",
    payload: omit(payload, "guid"),
  })
}
async function getRelationshipPatientCarersApi({
  relativeId,
}: {
  relativeId?: string
}) {
  return await request(
    `${config.get("patient.get.carers_relationship")}${
      relativeId ? `?relative_id=${relativeId}` : ``
    }`,
    {
      method: "GET",
    }
  )
}

async function addRelationshipPatientCarersApi(payload: {
  carer: Carer
  relationship: RELATIONSHIP
  relativeId?: string
}) {
  return await request(
    `${config.get("patient.put.carers")}/${payload.carer.guid}/relationship${
      payload.relativeId ? `?relative_id=${payload.relativeId}` : ``
    }`,
    {
      method: "PUT",
      payload: {
        code: payload.relationship,
      },
    }
  )
}

async function getIdentityfromRPPSApi(payload: Partial<Carer>) {
  return await request(config.get("admin.rpps"), {
    method: "POST",
    payload,
  })
}
function* getIdentityFromRPPSFlow(action: Action<Partial<Carer>>) {
  try {
    const responses = yield getIdentityfromRPPSApi(action.payload)
    if (responses.status === "ok")
      yield put({
        type: GET_IDENTITY_FROM_RPPS_SUCCESS,
        payload: responses.data,
      })
    else throw responses
  } catch (e) {
    yield put({
      type: GET_IDENTITY_FROM_RPPS_ERROR,
      payload: {
        type: "error",
        text: languages.cannotRetrievedProfessionalIdentity,
      },
    })
  }
}
function* getPharmacyCarersFlow() {
  try {
    const response = yield getPharmacyCarersApi()
    yield put({ type: GET_PHARMACY_CARERS_SUCCESS, payload: response.data })
  } catch (e) {
    yield put({
      type: GET_PHARMACY_CARERS_ERROR,
      payload: {
        type: "error",
        text: languages.cannotRetrieveCompanions,
      },
    })
  }
}

function* addPharmacyCarersFlow(action: Action<Carer>) {
  try {
    const response: ResponseAPI<Carer[]> = yield addPharmacyCarersApi(
      action.payload
    )
    if (response.status === "ok") {
      yield put({ type: GET_PHARMACY_CARERS_REQUEST })
    } else throw response
  } catch (e) {
    yield put({
      type: ADD_PHARMACY_CARERS_ERROR,
      payload: {
        type: "error",
        text:
          languages.cannotCreateCompanion +
          (typeof e === "string" ? e.toLowerCase() : ""),
      },
    })
  }
}
function* getPatientCarersFlow(action: ActionWithoutPayload) {
  try {
    const response: ResponseAPI<CarerApi[]> = yield getPatientCarersApi()
    if (response.data) {
      yield all([
        put({
          type: GET_PATIENT_CARERS_SUCCESS,
          payload: response.data.map(
            (carerApi: Partial<CarerApi>) =>
              new Compliance<Partial<CarerApi>, Partial<Carer>>(
                carerApi
              ).please() as Partial<Carer>
          ),
        }),
      ])
    } else throw response
  } catch (e) {
    yield put({
      type: GET_PATIENT_CARERS_ERROR,
      payload: {
        type: "error",
        text: languages.cannotRetrieveCompanions,
      },
    })
  }
}

function* deletePharmacyCarerFLow(action: Action<Carer>) {
  try {
    const response: ResponseAPI<Carer[]> = yield deletePharmacyCarerApi(
      action.payload
    )
    yield put({ type: DELETE_PHARMACY_CARER_SUCCESS })
  } catch (e) {
    yield put({
      type: DELETE_PHARMACY_CARER_ERROR,
      payload: {
        type: "error",
        text: languages.cannotDeleteCompanion,
      },
    })
  }
}

function* deletePatientCarerFLow(action: Action<Carer>) {
  try {
    const response: ResponseAPI<Carer[]> = yield deletePatientCarerApi(
      action.payload
    )
    if (response.status === "ok")
      yield all([
        put({ type: DELETE_PATIENT_CARER_SUCCESS }),
        put({ type: GET_PATIENT_CARERS_REQUEST }),
      ])
    else throw response
  } catch (e) {
    yield put({
      type: DELETE_PATIENT_CARER_ERROR,
      payload: {
        type: "error",
        text: languages.cannotDeleteCompanion,
      },
    })
  }
}
function* getRelationshipPatientCarersFlow(
  action: Action<{ relativeId?: string }>
) {
  try {
    const response: ResponseAPI<Relationship[]> =
      yield getRelationshipPatientCarersApi(action.payload)
    if (response.status === "ok") {
      yield put({
        type: GET_RELATIONSHIP_PATIENT_CARERS_SUCCESS,
        payload: response.data,
      })
    } else throw response
  } catch (e) {
    yield put({
      type: GET_RELATIONSHIP_PATIENT_CARERS_ERROR,
      payload: {
        type: "error",
        text:
        languages.cannotGetCompanionsRelationship +
          (typeof e === "string" ? e.toLowerCase() : ""),
      },
    })
  }
}

function* addRelationShipPatientCarersFlow(
  action: Action<{
    carer: Carer
    relativeId?: string
    relationship: RELATIONSHIP
  }>
) {
  try {
    const response: ResponseAPI<Relationship[]> =
      yield addRelationshipPatientCarersApi(action.payload)
    if (response.status === "ok") {
      yield all([
        put({
          type: GET_RELATIONSHIP_PATIENT_CARERS_REQUEST,
          payload: { relativeId: action.payload.relativeId },
        }),
      ])
    } else throw response
  } catch (e) {
    yield put({
      type: ADD_RELATIONSHIP_PATIENT_CARERS_ERROR,
      payload: { type: "error", text: "" },
    })
  }
}

function* addPatientCarersFlow(action: Action<Carer>) {
  try {
    const response: ResponseAPI<Carer[]> = yield addPatientCarersApi(
      action.payload
    )
    if (response.status === "ok" && response.data) {
      yield all([
        put({ type: ADD_PATIENT_CARERS_SUCCESS, payload: response.data }),
        put({ type: GET_PATIENT_CARERS_REQUEST }),
      ])
    } else {
      throw response
    }
  } catch (e) {
    yield put({
      type: ADD_PATIENT_CARERS_ERROR,
      payload: {
        type: "error",
        text:
          languages.cannotCreateCompanion +
          (typeof e === "string" ? e.toLowerCase() : ""),
      },
    })
  }
}

export function* changePatientCarersFlow() {
  yield put({ type: GET_PATIENT_CARERS_REQUEST })
}

export function* changePharmacyCarersFlow() {
  yield put({ type: GET_PHARMACY_CARERS_REQUEST })
}
export function* CarerWatcher(): GWatcher {
  yield takeLatest(ADD_PHARMACY_CARERS_REQUEST, addPharmacyCarersFlow)
  yield takeLatest(GET_PHARMACY_CARERS_REQUEST, getPharmacyCarersFlow)
  yield takeLatest(GET_PATIENT_CARERS_REQUEST, getPatientCarersFlow)
  yield takeLatest(DELETE_PHARMACY_CARER_REQUEST, deletePharmacyCarerFLow)
  yield takeLatest(DELETE_PATIENT_CARER_REQUEST, deletePatientCarerFLow)
  yield takeLatest(
    GET_RELATIONSHIP_PATIENT_CARERS_REQUEST,
    getRelationshipPatientCarersFlow
  )
  yield takeLatest(
    ADD_RELATIONSHIP_PATIENT_CARERS_REQUEST,
    addRelationShipPatientCarersFlow
  )
  yield takeLatest(ADD_PATIENT_CARERS_REQUEST, addPatientCarersFlow)
  yield takeLatest(GET_IDENTITY_FROM_RPPS_REQUEST, getIdentityFromRPPSFlow)

  // Lists changed
  yield takeLatest(ADD_PATIENT_CARERS_SUCCESS, changePatientCarersFlow)
  yield takeLatest(
    [ADD_PHARMACY_CARERS_SUCCESS, DELETE_PHARMACY_CARER_SUCCESS],
    changePharmacyCarersFlow
  )
}

export default CarerWatcher
