import { isEmpty } from "lodash"
import config from "react-global-configuration"
import { all, delay, put, select, takeLatest } from "redux-saga/effects"

import { request } from "lib/request"
import { getPatientsFromDataCarteVitale } from "lib/sesam"

import {
  cancelSocketNirReader,
  requestSocketNirReader,
  resetSocketNirReader,
} from "../../../client/socket/actions"
import {
  CHANGE_NIR_READER_TYPE,
  SOCKET_CANCEL_CARD_VITALE_READER,
  SOCKET_ERROR_CARD_VITALE_READER,
  SOCKET_INIT_CARD_VITALE_READER,
  SOCKET_LAUNCH_CARD_VITALE_READER,
  SOCKET_NIR_READER_RESPONSE,
  SOCKET_REMOVE_CARD_VITALE_READER,
  SOCKET_REMOVED_CARD_VITALE_READER,
  SOCKET_RESET_CARD_VITALE_READER,
} from "../../../client/socket/constants"
import { Action, GFlow, GWatcher, Message } from "../../../types/redux"
import {
  DataCarteVitale,
  NirReaderType,
  ReaderState,
} from "../../../types/sesam"
import { NirReaderResponse } from "../../../types/store"
import {
  nirReaderResponse,
  setNirReaderLoading,
  setNirReaderMessage,
  setNirReaderState,
} from "./actions"
import {
  CALL_API_NIR_READER,
  CANCEL_NIR_READER,
  REQUEST_NIR_READER,
  RESET_NIR_READER,
} from "./constants"
import { getNirReaderState } from "./selectors"

let NIR_READER_TYPE: NirReaderType = "websocket"

async function nirReaderApi(): Promise<DataCarteVitale> {
  return await request(config.get("nirReader.get"), {
    method: "GET",
  })
}

function* apiFlow(
  action: Action<{ countedRequest?: number }>
): GFlow<Action<NirReaderResponse>> {
  const countRequest = (action.payload?.countedRequest || 0) + 1
  const currentState = yield select(getNirReaderState)
  const limit =
    config.get("timeoutNirReaderInS") / config.get("intervalNirReaderApiInS")
  try {
    if (countRequest < limit && currentState === ReaderState.INSERT) {
      const { data }: { data: DataCarteVitale | undefined } =
        yield nirReaderApi()
      if (!isEmpty(data) && typeof data !== "string") {
        // data looks like a good data nir
        const format: NirReaderResponse = getPatientsFromDataCarteVitale(
          data as DataCarteVitale
        )
        yield all([
          put(nirReaderResponse(format)),
          put(setNirReaderState(ReaderState.REMOVED)),
          put(setNirReaderLoading(false)),
        ])
      } else {
        // delai avant le prochain lancement ping/data
        yield delay(config.get("intervalNirReaderApiInS") * 1000)
        yield put({
          type: CALL_API_NIR_READER,
          payload: { countedRequest: countRequest },
        })
      }
    } else if (currentState !== ReaderState.INITIALIZE) {
      yield all([put(setNirReaderState(ReaderState.ERROR))])
    }
  } catch (e) {
    console.error(e, {route: config.get("nirReader.get")})
    yield all([put(setNirReaderState(ReaderState.ERROR))])
  }
}

function* cancelFlow(): GFlow<Action<boolean | ReaderState>> {
  yield all([
    put(setNirReaderState(ReaderState.CANCELED)),
    put(setNirReaderLoading(false)),
  ])

  switch (NIR_READER_TYPE) {
    case "websocket":
      yield put(cancelSocketNirReader())
      break
  }
}

function* requestFlow(): GFlow<Action<NirReaderResponse>> {
  switch (NIR_READER_TYPE) {
    case "websocket":
      yield put(requestSocketNirReader())
      break
    case "api":
    default:
      yield all([
        put(setNirReaderState(ReaderState.INSERT)),
        put({ type: "CALL_API_NIR_READER" }),
      ])
      break
  }
}
function* resetFlow() {
  yield put(setNirReaderState(ReaderState.INITIALIZE))

  switch (NIR_READER_TYPE) {
    case "websocket":
      yield put(resetSocketNirReader())
      break
  }
}

function* socketFlow(
  action: Action<
    NirReaderResponse | Message | ReaderState | boolean | NirReaderType
  >
) {
  switch (action.type) {
    case SOCKET_NIR_READER_RESPONSE:
      yield put(nirReaderResponse(action.payload as NirReaderResponse))
      break
    case SOCKET_REMOVE_CARD_VITALE_READER:
      yield put(setNirReaderState(ReaderState.REMOVE))
      break
    case SOCKET_LAUNCH_CARD_VITALE_READER:
      yield put(setNirReaderState(ReaderState.INSERT))
      break
    case SOCKET_CANCEL_CARD_VITALE_READER:
      yield put(setNirReaderState(ReaderState.CANCELED))
      break
    case SOCKET_INIT_CARD_VITALE_READER:
    case SOCKET_RESET_CARD_VITALE_READER:
      yield put(setNirReaderState(ReaderState.INITIALIZE))
      break
    case SOCKET_REMOVED_CARD_VITALE_READER:
      yield all([
        put(setNirReaderState(ReaderState.REMOVED)),
        put(setNirReaderLoading(false)),
      ])
      break
    case SOCKET_ERROR_CARD_VITALE_READER:
      yield all([
        put(setNirReaderState(ReaderState.ERROR)),
        put(setNirReaderMessage(action.payload as Message)),
      ])
      break
  }
}

function* changeTypeFlow(action: Action<NirReaderType>) {
  console.log("Le type de lecteur à changé [" + action.payload + "]")
  NIR_READER_TYPE = action.payload
  yield requestFlow()
}

function* nirReaderWatcher(): GWatcher {
  yield takeLatest(RESET_NIR_READER, resetFlow)
  yield takeLatest(REQUEST_NIR_READER, requestFlow)
  yield takeLatest(CANCEL_NIR_READER, cancelFlow)
  yield takeLatest(CHANGE_NIR_READER_TYPE, changeTypeFlow)

  yield takeLatest(
    [
      SOCKET_NIR_READER_RESPONSE,
      SOCKET_CANCEL_CARD_VITALE_READER,
      SOCKET_ERROR_CARD_VITALE_READER,
      SOCKET_INIT_CARD_VITALE_READER,
      SOCKET_LAUNCH_CARD_VITALE_READER,
      SOCKET_REMOVED_CARD_VITALE_READER,
      SOCKET_REMOVE_CARD_VITALE_READER,
      SOCKET_RESET_CARD_VITALE_READER,
    ],
    socketFlow
  )

  yield takeLatest(CALL_API_NIR_READER, apiFlow)
}

export default nirReaderWatcher
