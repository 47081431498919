
import { jsPDF } from "jspdf";

  export async function addImageProcess(srcImg) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.src = srcImg;
      img.onload = () => resolve(img);
      img.onerror = reject;
    });
  }
  
  export async function generatePdf(imageUrls) {
    const doc = new jsPDF();
    const docHeight = doc.internal.pageSize.height;
    const docWidth = doc.internal.pageSize.width;  

    for (const [i, url] of imageUrls.entries()) {
      const image = await addImageProcess(url);
      doc.addImage(image as string, "png", 0, 0, docWidth, docHeight, "", "FAST");
      if (i !== imageUrls.length - 1) {
        doc.addPage();
      }
    }
    return doc;
  }
  
  export async function savePdf(imageUrls) {
    const multiPng = await generatePdf(imageUrls);
    const blobPdf = multiPng.output("blob");
    return blobPdf;
    
  }

