export const CARDS_REQUEST = "CARDS_REQUEST"
export const CARDS_SUCCESS = "CARDS_REQUEST_SUCCESS"
export const CARDS_ERROR = "CARDS_REQUEST_ERROR"

export const SETUPINTENT_ATTACH_REQUEST = "SETUPINTENT_ATTACH_REQUEST"
export const SETUPINTENT_ATTACH_SUCCESS = "SETUPINTENT_ATTACH_SUCCESS"
export const SETUPINTENT_ATTACH_ERROR = "SETUPINTENT_ATTACH_ERROR"

export const SETUPINTENT_CREATE_REQUEST = "SETUPINTENT_CREATE_REQUEST"
export const SETUPINTENT_CREATE_SUCCESS = "SETUPINTENT_CREATE_SUCCESS"
export const SETUPINTENT_CREATE_ERROR = "SETUPINTENT_CREATE_ERROR"

export const DELETE_CARD_REQUEST = "DELETE_CARD_REQUEST"
export const DELETE_CARD_SUCCESS = "DELETE_CARD_SUCCESS"
export const DELETE_CARD_ERROR = "DELETE_CARD_ERROR"
export const RESET_CARD_STORE = "RESET_CARD_STORE"

export const GET_TOKEN_ERROR = "GET_TOKEN_ERROR"
export const GET_TOKEN_SUCCESS = "GET_TOKEN_SUCCESS"
export const GET_TOKEN_REQUEST = "GET_TOKEN_REQUEST"

export const HAS_STRIPE_ERROR = "HAS_STRIPE_ERROR"
