import config from "react-global-configuration"
import { all, call, put, takeLatest } from "redux-saga/effects"
import { v4 as uuidv4 } from "uuid"

import configurationActions from "client/config/actions"
import { callHasChanged, getCall } from "containers/Call/services/actions"
import { CALL_HAS_CHANGED } from "containers/Call/services/constants"
import { getCurrentCallApi } from "containers/Call/services/saga"
import { INSERT_RELATIVE_SUCCESS } from "containers/InsertRelative/services/constants"
import { HOME_PAGE } from "core/constants"
import { request } from "lib/request"
import { Admin } from "types/entity"
import {
  Call,
  CallState,
  CustomerApi,
  InstallationRequest,
  ResponseCall,
  ResponseInstallation,
  ResponseRelatives,
} from "types/payload"
import { Action, GFlow, GWatcher } from "types/redux"

import actions from "./actions"
import {
  INSTALLATION_REQUEST,
  LOGGED_OUT,
  RELATIVES_ERROR,
  RELATIVES_REQUEST,
  RESET_STORE,
  SET_ADMIN,
  SET_CUSTOMER,
} from "./constants"

/*
 *  API REQUESTS
 */
async function installationApi(): Promise<ResponseInstallation> {
  const identifier: string = localStorage.getItem("identifier") || uuidv4()
  localStorage.setItem("identifier", identifier)

  const params: InstallationRequest = {
    ...config.get("mock.installation"),
    installation_identifier: identifier,
    device_model: navigator.userAgent,
  }

  return request(config.get("installation"), {
    method: "POST",
    payload: params,
  })
}

async function getRelativesApi(): Promise<ResponseRelatives> {
  return await request(config.get("patient.get.relatives"), { method: "GET" })
}

/*
 *  FLOWS
 */
function* installationFlow(): GFlow<ResponseInstallation> {
  try {
    const response: ResponseInstallation = yield call(installationApi)
    yield all([
      put(actions.setAdmin(response.pharmacy)),
      put(actions.setCustomer(response.customer)),
    ])

    if (response.customer && response.customer.is_verified) {
      // Si un utilisateur existe,
      // Checker dans un premier temps s'il a une TLC en cours
      // avant de signifier le succès de l'installation
      try {
        const responseCall: ResponseCall = yield call(getCurrentCallApi)
        yield put(callHasChanged(responseCall.call))
      } catch (error) {
        console.error(error, {
          route: config.get("call.get.current")
        })
        put(callHasChanged(undefined))
      }
    }

    yield put(actions.success)
  } catch (error) {
    console.error(error, {
      route: config.get("installation")
    })
    yield put(actions.error(error as string))
  }
}

function* loginFlow({
  payload,
}: {
  type: string
  payload: CustomerApi
}): GFlow<Action<CustomerApi | undefined>> {
  if (payload) yield put(actions.loggedIn(payload))
  if (payload && payload.is_verified) {
    yield put({ type: RELATIVES_REQUEST })
  }
}

function* getRelativesFlow() {
  try {
    const response: ResponseRelatives = yield call(getRelativesApi)
    yield put(actions.setRelatives(response.relatives))
  } catch (error) {
    console.error(error, {
      route: config.get("patient.get.relatives")
    })
    yield put({ type: RELATIVES_ERROR, payload: error })
  }
}
function* loggedOutFlow() {
  window.location.href = HOME_PAGE
}

function* callFlow(action: Action<Call>): GFlow<Call> {
  yield put(actions.setCall(action.payload))
}
function* adminFlow(action: Action<Admin>) {
  const admin = action.payload
  if (admin) {
    yield put(configurationActions.request())
  }
}
function* clientWatcher(): GWatcher {
  yield takeLatest(INSTALLATION_REQUEST, installationFlow)
  yield takeLatest(SET_ADMIN, adminFlow)
  yield takeLatest(LOGGED_OUT, loggedOutFlow)
  yield takeLatest(
    [RELATIVES_REQUEST, INSERT_RELATIVE_SUCCESS],
    getRelativesFlow
  )
  yield takeLatest(SET_CUSTOMER, loginFlow)
  yield takeLatest(CALL_HAS_CHANGED, callFlow)
}

export default clientWatcher
