export const TLC_REQUEST = "TLC_REQUEST"
export const TLC_SUCCESS = "TLC_SUCCESS"
export const TLC_ERROR = "TLC_ERROR"
export const RESET_PAYMENT_STORE = "RESET_PAYMENT_STORE"
export const [PRICE_SUCCESS, PRICE_REQUEST, PRICE_ERROR] = [
  "PRICE_SUCCESS",
  "PRICE_REQUEST",
  "PRICE_ERROR",
]
export const PAYMENT_REQUIRES_ACTION = "PAYMENT_REQUIRES_ACTION"
