import { Action, Message } from "types/redux"
import { DocumentsStore } from "types/store"
import {
  SEND_DOCUMENTS_ERROR,
  SEND_DOCUMENTS_REQUEST,
  SEND_DOCUMENTS_SUCCESS,
} from "./constants"

const initialStore = {
  loading: false,
  success: false,
  canSendEmail: true,
}

const reducer = (
  store: Partial<DocumentsStore> = initialStore,
  action: Action<{ id: number }> | Action<Message>
): Partial<DocumentsStore> => {
  switch (action.type) {
    case SEND_DOCUMENTS_REQUEST:
      return { ...store, loading: true, success: false, message: undefined }
    case SEND_DOCUMENTS_SUCCESS:
      return {
        ...store,
        loading: false,
        success: true,
        message: action?.payload as Message,
        canSendEmail: false,
      }
    case SEND_DOCUMENTS_ERROR:
      return {
        ...store,
        loading: false,
        success: false,
        message: action?.payload as Message,
      }
    default:
      return { ...store }
  }
}
export default reducer
