import LocalizedStrings, {
  GlobalStrings,
  LocalizedStringsMethods,
} from "react-localization"
import en from "./langs/en"
import fr from "./langs/fr"

export function getCurrentLanguage(){
  return localStorage.getItem('lang') || 'fr'
}

export type IStrings = LocalizedStringsMethods & {
  [key: string]: any
}

const languages: IStrings = new LocalizedStrings({
  fr,
  en,
} as GlobalStrings<{
  [key: string]: any;
}>);

languages.setLanguage(getCurrentLanguage())

export { languages };