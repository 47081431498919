import Compliance from "lib/api-compliance"
import { Relative } from "types/entity"
import { InsertRelativeRequest, RelativeApi } from "types/payload"
import { Action, ActionWithoutPayload, Message } from "types/redux"
import {
  INSERT_RELATIVE_ERROR,
  INSERT_RELATIVE_SUCCESS,
  INSERT_RELATIVE_REQUEST,
  RESET_RELATIVE_STORE,
  INSERT_RELATIVES_REQUEST,
} from "./constants"

export const insertRelativeRequest = (
  payload: Relative
): Action<RelativeApi> => ({
  type: INSERT_RELATIVE_REQUEST,
  payload: new Compliance<Relative, RelativeApi>(
    payload
  ).please() as RelativeApi,
})

export const insertRelativesRequest = (
  relatives: Relative[]
): Action<RelativeApi[]> => ({
  type: INSERT_RELATIVES_REQUEST,
  payload: relatives.map(
    (relative) =>
      new Compliance<Relative, RelativeApi>(relative).please() as RelativeApi
  ),
})

export const insertRelativeSuccess = (
  payload: RelativeApi
): Action<Relative> => {
  return {
    type: INSERT_RELATIVE_SUCCESS,
    payload: new Compliance<RelativeApi, Relative>(
      payload
    ).please() as Relative,
  }
}

export const insertRelativeError = (error: string): Action<Message> => ({
  type: INSERT_RELATIVE_ERROR,
  payload: { text: error, type: "error" },
})

export const resetRelativeStore = (): ActionWithoutPayload => ({
  type: RESET_RELATIVE_STORE,
})
