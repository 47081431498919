import { PAYMENT_REQUIRES_ACTION } from "containers/Payment/services/constants"
import { Action, Message } from "types/redux"
import { CardsStore } from "types/store"
import { CardInformation } from "types/stripe"

import {
  CARDS_ERROR,
  CARDS_REQUEST,
  CARDS_SUCCESS,
  DELETE_CARD_ERROR,
  DELETE_CARD_REQUEST,
  DELETE_CARD_SUCCESS,
  GET_TOKEN_SUCCESS,
  HAS_STRIPE_ERROR,
  RESET_CARD_STORE,
  SETUPINTENT_ATTACH_ERROR,
  SETUPINTENT_ATTACH_REQUEST,
  SETUPINTENT_ATTACH_SUCCESS,
  SETUPINTENT_CREATE_ERROR,
  SETUPINTENT_CREATE_REQUEST,
  SETUPINTENT_CREATE_SUCCESS,
} from "./constants"

const initialState: Partial<CardsStore> = {
  success: false,
  loading: false,
  hasPaymentError: false,
}

const cardsReducer = (
  state = initialState,
  action: Action<CardInformation[] | Message | string | boolean>
): Partial<CardsStore> => {
  switch (action.type) {
    case HAS_STRIPE_ERROR:
      return { ...state, hasPaymentError: action.payload as boolean }
    case CARDS_REQUEST:
    case SETUPINTENT_CREATE_REQUEST:
    case SETUPINTENT_ATTACH_REQUEST:
    case DELETE_CARD_REQUEST:
      return {
        ...state,
        message: undefined,
        loading: true,
      }
    case CARDS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        cards: [...(action.payload as CardInformation[])],
      }

    case PAYMENT_REQUIRES_ACTION:
      return {
        ...state,
        payment_intent_client_secret: action.payload as string,
      }
    case SETUPINTENT_ATTACH_SUCCESS:
    case DELETE_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        message: action.payload as Message,
      }
    case SETUPINTENT_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        clientSecret: action.payload as string,
      }
    case SETUPINTENT_ATTACH_ERROR:
    case SETUPINTENT_CREATE_ERROR:
    case CARDS_ERROR:
    case DELETE_CARD_ERROR:
      return {
        ...state,
        loading: false,
        message: action.payload as Message,
      }

    case GET_TOKEN_SUCCESS:
      return {
        ...state,
        public_stripe_key: action.payload as string,
      }
    case RESET_CARD_STORE:
      return { ...initialState, public_stripe_key: state.public_stripe_key }
    default:
      return { ...state, message: undefined }
  }
}

export default cardsReducer
