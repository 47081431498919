
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Card } from "components/Card/Card"
import { FormPage } from "components/Form/Form"
import { GenericModal } from "components/Modal/Modal"
import SelectPatient from "components/SelectPatient"
import { Information } from "components/Title/Title"
import { cdn } from "core/cdn"
import { SURVEY_PAGE } from "core/constants"
import { withNirReader } from "hocs"
import { birthdateValidator } from "lib/form"
import { API_DATE_FORMAT, capitalize, formatNirFromMask } from "lib/utils"
import { BirthPlace, Customer, Relative } from "types/entity"
import { Dispatcher } from "types/redux"
import { Patient } from "types/sesam"
import { InsertRelativeStore, NirReaderResponse } from "types/store"

import { insertRelativeRequest, resetRelativeStore } from "./services/actions"
import { InputNames } from "types/props"
import { CarteVitaleInfoPage } from "containers/Signup/carteVitaleInfoDisplayer"
import { isEmpty } from "lodash"
import { useLanguage } from "locales"
const SHOW_CV_DATA_STEP = "SHOW_CV_DATA_STEP"
const INSERT_REQUIRED_DATA_STEP = "INSERT_REQUIRED_DATA_STEP"

const InsertRelative = (
  props: InsertRelativeStore & { withNirReaderResponse?: NirReaderResponse }
) => {
  const { languages } = useLanguage()
  const [relative, setRelative] = useState<
    Partial<Relative | Customer> | undefined
  >()
  const navigate = useNavigate()
  const [consentRelativeVisible, setConsentRelativeVisible] = useState(false)
  const [newRelative, setNewRelative] = useState<Partial<Relative> | undefined>(
    undefined
  )
  const [signupByCv, setSignupByCv] = useState(false)
  const [step, setStep] = useState(INSERT_REQUIRED_DATA_STEP)

  const handleInsert = (relative: Partial<Relative>) => {
    const formatNir = relative.nir
      ? formatNirFromMask(relative.nir as string)
      : undefined
    const formatted = {
      ...relative,
      nir: formatNir,
      signup_by_carte_vitale: signupByCv
    }
    props.insert(formatted as Relative)
  }

  useEffect(() => {
    if (props.success) {
      props.reset()
      navigate(SURVEY_PAGE)
    }
  }, [props.success])

  const patientsInNir = props.withNirReaderResponse
    ? { ...props.withNirReaderResponse }
    : undefined
  if (patientsInNir && !relative) {
    return (
      <SelectPatient
        patients={patientsInNir as NirReaderResponse}
        onSelect={(patient: Partial<Patient>) => {
          setRelative(patient)
          if (!patient || isEmpty(patient)){
            setStep(INSERT_REQUIRED_DATA_STEP)
            setSignupByCv(false)
          }
          else {
            setStep(SHOW_CV_DATA_STEP)
            setSignupByCv(true)
          }
        }}
      />
    )
  }


  const inputs = [
    {
      name: "gender",
      required: true,
      label: capitalize(languages.inputThe("gender")),
    },
    { name: "first_birth_firstname",
      label: <span>
        {`${languages.label_input_the_male} ${languages.first} ` }
          <b style={{textDecoration: "underline" }}>
            {languages.first_firstname}
          </b>
          { languages.of_birth }
        </span>,
    keepLabelFormat: true, 
    condition: {
      label: languages.relativeUseAnotherFirstname,
      type: "checkbox", filled: { name: "firstname", required: true, label: capitalize(languages.inputThe("preferedFirstname"))},
    } },
    { name: "birth_lastname",
      label: capitalize(languages.inputThe("birthLastname")),
      condition: {
        label: languages.relativeUseAnotherLastname,
      type: "checkbox", filled: { name: "lastname", required: true, label: capitalize(languages.inputThe("preferedLastname"))},
    } },
    {
      label: capitalize(languages.inputThe("nir")),
      name: "nir",
      required: false,
      default: {
        value: "",
        warning: {
          title: languages.noNirTitleModal,
          contentText: languages.noNirContentModal,
          acceptText: languages.byPassNir_relative,
          cancelText: languages.RelativeHasNir,
        },
        label: languages.byPassNir_relative,
      },
    },
    {
      name: InputNames.INSEE_CODE,
      label: "",
      type: "hidden",
    },
    {
      name: "birthdate",
      label: capitalize(languages.inputThe("birthdate")),
      required: true,
      rules: [birthdateValidator],
    },
    { name: InputNames.BIRTH_COUNTRY, type: "hidden", default: {
      value: `${BirthPlace.FRANCE}`, label: ""
    }},
    { name: InputNames.BIRTH_LOCATION, required: true, readOnly: false, label: languages.inputYourRelativeBirthCity },
  ]

  const formInputs = signupByCv ? [
    {
      name: "gender",
      required: true,
      label: capitalize(languages.inputThe("gender")),
    },
    {
      name: InputNames.INSEE_CODE,
      label: "",
      type: "hidden",
    },
    {
      name: "birthdate",
      label: capitalize(languages.inputThe("birthdate")),
      required: true,
      rules: [birthdateValidator],
      type: "hidden",
    },
    { name: InputNames.BIRTH_COUNTRY, type: "hidden", default: {
      value: `${BirthPlace.FRANCE}`, label: ""
    }},
    { name: InputNames.BIRTH_LOCATION, required: true, readOnly: false, label: languages.inputYourRelativeBirthCity },
  ] : inputs


  const handleNext = (relative:Partial<Patient>) =>{
    setRelative(relative)
    setStep(INSERT_REQUIRED_DATA_STEP)
  }

  switch(step){
    case SHOW_CV_DATA_STEP:
      return (  
        <CarteVitaleInfoPage
          useFor='relative'
          patient ={relative} 
          loading={props.loading} 
          handlePseudoNameUpdate={handleNext}
          onPrevious={()=> navigate(SURVEY_PAGE)}
        />
      )
    case INSERT_REQUIRED_DATA_STEP:
      return (
        <Card
          subtitle={languages.enterRelativeInformations}
          message={props.message}
        >
          <FormPage
            loading={props.loading}
            prospect={relative}
            inputs={formInputs}
            onFinish={(values) => {
              setConsentRelativeVisible(true)
              const newRelative = {...relative, ...values}
              setNewRelative(newRelative as Partial<Relative>)
            }}
            acceptText={languages.addRelative}
            onCancel={() => {
              setRelative(undefined)
              props.reset()
              navigate(SURVEY_PAGE)
            }}
          />
          <GenericModal
            visible={consentRelativeVisible}
            title={languages.enterRelativeInformations}
            acceptText={languages.IHaveRelativeConsent}
            acceptButtonProps={{
              danger: false,
              loading: props.loading,
              disabled: props.loading,
            }}
            onAccept={() => {
              newRelative && handleInsert(newRelative)
              props.reset()
            }}
            onCancel={() => {
              setConsentRelativeVisible(false)
            }}
          >
            <div style={{ textAlign: "center" }}>
              <img
                style={{ paddingBottom: "50px" }}
                src={cdn("/images/proche_picto.svg")}
                alt=""
              />
              <Information
                className={"font-size-xlarge"}
                text={languages.relativeConsent}
              />
            </div>
          </GenericModal>
        </Card>
      )
  }
}

const mapStateToProps = ({
  insertRelative,
}: {
  insertRelative: InsertRelativeStore
}) => {
  return insertRelative
}

const mapDispatchToProps = (dispatch: Dispatcher) => {
  return {
    insert: (relativeValues: Relative) =>
      dispatch(insertRelativeRequest(relativeValues)),
    reset: () => dispatch(resetRelativeStore()),
  } as Partial<InsertRelativeStore>
}

const InsertRelativeWithNirReader = withNirReader(InsertRelative)
const InsertRelativePage = InsertRelativeWithNirReader

export default connect(mapStateToProps, mapDispatchToProps)(InsertRelativePage)
