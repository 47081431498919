import config from "react-global-configuration"
import { all, call, put, takeLatest } from "redux-saga/effects"

import { languages } from "locales/languages"
import { request } from "lib/request"
import { ResponseGetDoctors } from "types/payload"
import { GFlow, GWatcher } from "types/redux"

import actions from "./actions"
import { GET_DOCTORS_REQUEST } from "./constants"

/*
 *  API REQUESTS
 */
async function getDoctorsApi(): Promise<ResponseGetDoctors> {
  return await request(
    `${config.get("admin.doctors")}?&request=&page=0&limit=0`,
    {
      method: "GET",
    }
  )
}

/*
 * FLOWS
 */
function* getDoctorsFlow(): GFlow<ResponseGetDoctors> {
  try {
    const response: ResponseGetDoctors = yield call(getDoctorsApi)
    if (response.doctors) {
      yield all([put(actions.success(response.doctors))])
    }
  } catch (error) {
    console.error(error, {
      route: `${config.get("admin.doctors")}?&request=&page=0&limit=0`
    })
    yield put(
      actions.error(
        typeof error === "string" ? error : languages.genericAPIError
      )
    )
  }
}

function* doctorWatcher(): GWatcher {
  yield takeLatest(GET_DOCTORS_REQUEST, getDoctorsFlow)
}

export default doctorWatcher
