import styles from "./Loader.module.scss"
import LOGO_GIF from "assets/loaders/gimmick-video.gif"
import React from "react"

const Loader: React.FC<{ size?: string }> = (props) => (
  <div className={styles.Loader} data-testid="Loader">
    <img src={LOGO_GIF} alt="" width={props.size ?? "200px"} />
  </div>
)

export default Loader
