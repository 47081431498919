import React, { useEffect } from "react"
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"

import { Card } from "components/Card/Card"
import { FormPage } from "components/Form/Form"
import { HOME_PAGE } from "core/constants"
import { Action, ActionWithoutPayload } from "types/redux"
import { AdminStore } from "types/store"

import { adminLoginRequest } from "./services/actions"
import { useLanguage } from "locales"

const Admin = (props: AdminStore): JSX.Element => {
  const navigate = useNavigate()
  const { languages } = useLanguage()

  useEffect(() => {
    if (props.success) navigate(HOME_PAGE)
  }, [props.success])

  const handleLogin = (credentials: { email: string; password: string }) => {
    props.login(credentials)
  }
  const inputs = [
    { name: "email", required: true, readOnly: false },
    { name: "password", required: true, readOnly: false },
  ]
  return (
    <Card subtitle={languages.myAdministratorAccount} message={props.message}>
      <FormPage
        hidePrevious
        readOnly={false}
        inputs={inputs}
        onFinish={(v) => handleLogin(v as { email: string; password: string })}
        acceptText={languages.login}
        loading={props.loading}
        onCancel={() => navigate(HOME_PAGE)}
      />
    </Card>
  )
}

const mapStateToProps = (state: { admin: AdminStore }): AdminStore => {
  return state.admin
}

const mapDispatchToProps = (
  dispatch: (e: Action<unknown | ActionWithoutPayload>) => void
): Partial<AdminStore> => {
  return {
    login: (credentials: { email: string; password: string }) =>
      dispatch(adminLoginRequest(credentials)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Admin)
