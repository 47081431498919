import { Customer, Relative } from "types/entity"
import { ReaderState } from "types/sesam"
import { NirReaderStore } from "types/store"

export const getNirReaderState = ({
  nirReader,
}: {
  nirReader: NirReaderStore
}): ReaderState => nirReader.readerState

export const getNirReaderPatient = ({
  nirReader,
}: {
  nirReader: NirReaderStore
}): Partial<Customer> | undefined => nirReader.response?.patient

export const getNirReaderRelatives = ({
  nirReader,
}: {
  nirReader: NirReaderStore
}): Partial<Relative[]> | undefined =>
  nirReader.response?.relatives as Relative[]
