import { InputProps } from "antd"
import React, { useMemo } from "react"
import InputMask from "react-input-mask"
import { useLanguage } from "locales"

import styles from "./Code.module.scss"

// CODE_MASK = 9 9 9 9
export interface CodeInputProps extends Omit<InputProps, "onChange"> {
  onChange?: (code: string) => void
  numberOfChar?: number
}


const maskSize = (nb) => {
  if (!nb) return "9 9 9 9"
  return [...Array(nb)].map(() => "9").join(" ")
}

export const Code: React.FC<CodeInputProps> = (props): JSX.Element => {
  const { languages } = useLanguage()
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const unmaskedValue = e.target.value.replaceAll("_", "")
    const code = unmaskedValue.replaceAll(languages.codeSpacer, "")
    props.onChange && props.onChange(code)
  }
  const placeholder = useMemo(
    () => maskSize(props.numberOfChar),
    [props.numberOfChar]
  )
  return (
    <InputMask
      className={styles.Input}
      alwaysShowMask
      mask={placeholder}
      onChange={onChange}
      value={props.value}
      readOnly={props.readOnly}
    />
  )
}
