import config from "react-global-configuration"
import { all, call, put, takeLatest } from "redux-saga/effects"

import { SET_CALL } from "client/services/constants"
import { getCall } from "containers/Call/services/actions"
import stripeActions from "containers/Stripe/services/actions"

import { request } from "lib/request"
import { ERROR_API } from "types/error"
import { Call, CallRequest, ResponseCall, ResponsePrice } from "types/payload"
import { Action, GFlow, GWatcher, Message } from "types/redux"

import actions from "./actions"
import { PRICE_REQUEST, TLC_ERROR, TLC_REQUEST } from "./constants"
import { languages } from "locales/languages"

async function createConsultationApi(
  payload: CallRequest
): Promise<ResponseCall> {
  const orderUrl = config.get("call.post")
  return await request(orderUrl, {
    method: "POST",
    payload,
  })
}

async function getPriceApi(payload: { relativeId?: string }) {
  return request(
    `${config.get("call.get.price")}` +
      `?final=true&doctor=false&doctor_name=false&doctor_available=false&doctor_report=false` +
      `&relative_id=${payload.relativeId}`,
    {
      method: "GET",
    }
  )
}
function* priceFlow({
  payload,
}: Action<{ relativeId?: string }>): GFlow<ResponsePrice | Message> {
  try {
    const response: ResponsePrice = yield call(getPriceApi, payload)
    yield all([put(actions.priceSuccess(response))])
  } catch (error) {

    console.error(error, {
      route: `${config.get("call.get.price")}` +
      `?final=true&doctor=false&doctor_name=false&doctor_available=false&doctor_report=false` +
      `&relative_id=${payload.relativeId}`
    })
    yield put(
      actions.priceError(
        typeof error === "string" ? error : languages.genericAPIError
      )
    )
  }
}

function* tlcFlow({
  payload,
}: Action<CallRequest>): GFlow<ResponseCall | Message> {
  yield put(stripeActions.hasStripeError(false))
  try {
    const response: ResponseCall = yield createConsultationApi(payload)
    const { requires_action, payment_intent_client_secret } = response
    if (requires_action && payment_intent_client_secret)
      yield put(actions.paymentRequiresAction(payment_intent_client_secret))
    else if (response.status === "ok" && response.call) {
      yield put({ type: SET_CALL, payload: response.call })
      yield put(getCall())
      yield put(actions.success(response.call as Call))
    } else {
      yield put(actions.error(languages.cannotLoadTLC))
    }
  } catch (error: any) {
    console.error(error, {
      route: config.get("call.post")
    })
    if (error?.code && error.code === ERROR_API.STRIPE)
      yield put(stripeActions.hasStripeError(true))

    if (error?.message) yield put(actions.error(error.message))
    else
      yield put(
        actions.error((error as string) ?? languages.cannotLoadTLC)
      )
  }
}

function* paymentWatcher(): GWatcher {
  yield takeLatest(TLC_REQUEST, tlcFlow)
  yield takeLatest(PRICE_REQUEST, priceFlow)
}

export default paymentWatcher
