import { w3cwebsocket as W3CWebSocket } from "websocket"
import {
  SET_SOCKET,
  SOCKET_CANCEL_CARD_VITALE_READER,
  SOCKET_EMIT_MESSAGE,
  SOCKET_ERROR_CARD_VITALE_READER,
  SOCKET_IN_OPEN_STATE,
  SOCKET_LAUNCH_WORKFLOW,
  SOCKET_REMOVE_CARD_VITALE_READER,
  SOCKET_REMOVED_CARD_VITALE_READER,
  SOCKET_RESET_CARD_VITALE_READER,
  SOCKET_RETRY_CONNECT_DECREMENT_RETRY,
  SOCKET_RETRY_CONNECT_RESET,
  SOCKET_SEND_CANCELED_CALL,
  SOCKET_SEND_MESSAGE,
  SOCKET_LOG_TERMINAL_INFORMATION,
  SOCKET_INIT_CARD_VITALE_READER,
  SOCKET_NIR_READER_RESPONSE,
} from "./constants"

import { SocketSendMessage } from "types/payload"
import { SocketPayload } from "./utils"
import { Action, ActionWithoutPayload, Message } from "types/redux"
import { NirReaderResponse } from "types/store"

export function cancelSocketNirReader(): Action<undefined> {
  return {
    type: SOCKET_CANCEL_CARD_VITALE_READER,
    payload: undefined,
  }
}

export function decrementRetryBy(step: number): Action<number> {
  return {
    type: SOCKET_RETRY_CONNECT_DECREMENT_RETRY,
    payload: step,
  }
}

export function emitMessage(payload: SocketPayload): Action<SocketPayload> {
  return {
    type: SOCKET_EMIT_MESSAGE,
    payload,
  }
}

export function errorCardSocketNirReader(error: string): Action<Message> {
  return {
    type: SOCKET_ERROR_CARD_VITALE_READER,
    payload: { text: error, type: "error" },
  }
}

export function launchWorkflow(): ActionWithoutPayload {
  return {
    type: SOCKET_LAUNCH_WORKFLOW,
  }
}

export const removeCardSocketNirReader = (): ActionWithoutPayload => {
  return {
    type: SOCKET_REMOVE_CARD_VITALE_READER,
  }
}

export function removedCardSocketNirReader(): ActionWithoutPayload {
  return {
    type: SOCKET_REMOVED_CARD_VITALE_READER,
  }
}

export function requestSocketNirReader(): ActionWithoutPayload {
  return {
    type: SOCKET_INIT_CARD_VITALE_READER,
  }
}

export const resetNumberOfRetry = (): ActionWithoutPayload => ({
  type: SOCKET_RETRY_CONNECT_RESET,
})

export function resetSocketNirReader(): ActionWithoutPayload {
  return { type: SOCKET_RESET_CARD_VITALE_READER }
}

export const sendCallCanceled = (): ActionWithoutPayload => ({
  type: SOCKET_SEND_CANCELED_CALL,
})

export function sendMessageToSocket(
  payload: SocketSendMessage
): Action<SocketSendMessage> {
  return { type: SOCKET_SEND_MESSAGE, payload }
}

export function setSocket(payload: W3CWebSocket): Action<W3CWebSocket> {
  return { type: SET_SOCKET, payload }
}

export const setSocketInOpenState = (): ActionWithoutPayload => ({
  type: SOCKET_IN_OPEN_STATE,
})

export const socketNirReaderResponse = (
  payload: NirReaderResponse
): Action<NirReaderResponse> => {
  return { type: SOCKET_NIR_READER_RESPONSE, payload }
}

export const logTerminalInformation = () : ActionWithoutPayload => ({
  type: SOCKET_LOG_TERMINAL_INFORMATION
})
