import { useState, useEffect } from "react"
import config from "react-global-configuration"

export default function useUpdator() {
  const [needToUpdate, setNeedToUpdate] = useState<boolean>(false)
  const [intervalUpdatorInMS] = useState(
    config.get("intervalUpdatorInMS") || 900000
  )
  const [currentVersion, setVersion] = useState<null | string>(
    localStorage.getItem("app_version")
  )

  // clear interval when pathname changes
  const [pathname] = useState(window.location.pathname)

  useEffect(() => {
    const intervalVersion = setInterval(() => {
      fetch(`/env.json?&_t=${new Date().getTime()}`)
        .then((response) => response.json())
        .then((json) => {
          if (window.location.pathname !== pathname) {
            console.log(
              `Clear > Interval Updator [${window.location.pathname}] | version in storage [${currentVersion}]`
            )
            clearInterval(intervalVersion)
            return
          }
          console.log(`Version > [${json.REACT_APP_VERSION}]`)
          localStorage.setItem("app_version", json.REACT_APP_VERSION)
          localStorage.setItem("CDN_URL", json.REACT_APP_CDN_URL)
          if (currentVersion) {
            const lastVersion = json.REACT_APP_VERSION
            if (currentVersion !== lastVersion) setNeedToUpdate(true)
          } else setVersion(json.REACT_APP_VERSION)
        })
    }, intervalUpdatorInMS)
    if (needToUpdate) clearInterval(intervalVersion)
  }, [])

  return { version: currentVersion, needToUpdate }
}
