import { connect } from "react-redux"
import React from "react"
import { Button } from "components/Button"
import { Buttons } from "components/Card/Card"
import { PaymentStore } from "types/store"
import { useLanguage } from "locales"

const Expired: React.FC<{
  loading?: boolean
  createCall: () => void
  logout: () => void
}> = ({ loading, createCall, logout }): JSX.Element => {
  const { languages } = useLanguage()
  return (
    <Buttons>
      <Button type="link" onClick={() => logout()}>
        {languages.logout}
      </Button>
      <Button
        type="primary"
        loading={loading}
        disabled={loading}
        onClick={createCall}
      >
        {languages.askAgain}
      </Button>
    </Buttons>
  )
}
const mapStateToProps = ({ payment }: { payment: PaymentStore }) => {
  return { loading: payment.loading }
}

export default connect(mapStateToProps, null)(Expired)
