import { Action, ActionWithoutPayload, Message } from "types/redux"
import {
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_STORE,
  RESET_PASSWORD_REQUEST_IS_MADE,
} from "./constants"

const actions = {
  resetPasswordRequest: (payload: {
    phone: string
  }): Action<{ phone: string }> => {
    return {
      type: RESET_PASSWORD_REQUEST,
      payload,
    }
  },

  resetPasswordRequestIsMade: (
    response: string
  ): Action<Message> => {
    return {
      type: RESET_PASSWORD_REQUEST_IS_MADE,
      payload: { type: "success", text: response },
    }
  },

  resetPasswordStore: (): ActionWithoutPayload => ({
    type: RESET_PASSWORD_STORE,
  }),
}

export default actions
