import React, { useEffect } from "react"
import { ConnectedComponent, connect } from "react-redux"

import { Answer } from "containers/Survey/types"
import { Customer } from "types/entity"
import { Dispatcher } from "types/redux"
import { CardsStore, ClientStore, PaymentStore } from "types/store"
import { CardInformation } from "types/stripe"

import { CreditCardForm } from "./AddCard"
import { Cards } from "./DisplayCard"
import { PaymentCardForm } from "./PaymentCard"
import Stripe from "./provider"
import actions from "./services/actions"

const StripeAddCard: React.FC<CardsStore> = (props): JSX.Element => (
  <Stripe>
    <CreditCardForm {...props} />
  </Stripe>
)

const StripePaymentCard: React.FC<
  { onPay: (a: Answer[]) => void } & Partial<CardsStore>
> = (props): JSX.Element => (
  <Stripe>
    <PaymentCardForm {...props} />
  </Stripe>
)

const History: React.FC<CardsStore> = (props): JSX.Element => {
  useEffect(() => {
    !props.cards && props.get()
  }, [props.cards])
  return <Cards {...props} />
}

const mapStateToProps = ({
  stripe,
  client,
  payment,
}: {
  stripe: CardsStore
  client: ClientStore
  payment: PaymentStore
}): CardsStore => ({
  ...stripe,
  loading: stripe.loading || payment.loading,
  patient: client.customer as Customer,
})

const mapDispatchToProps = (dispatch: Dispatcher): Partial<CardsStore> => ({
  attach: (d: { setupintent_id: string }) => dispatch(actions.attachRequest(d)),
  create: () => dispatch(actions.createRequest()),
  get: () => {
    dispatch(actions.cardsRequest())
  },
  delete: (card: CardInformation) => dispatch(actions.deleteCardRequest(card)),
})

export const PaymentCard: React.FC<
  { onPay: (a: Answer[]) => void } & Partial<CardsStore>
> = connect(mapStateToProps, mapDispatchToProps)(StripePaymentCard)

export const AddCard: ConnectedComponent<React.FC<CardsStore>, any> = connect(
  mapStateToProps,
  mapDispatchToProps
)(StripeAddCard)

export const CardHistory: ConnectedComponent<React.FC<CardsStore>, any> = connect(
  mapStateToProps,
  mapDispatchToProps
)(History)
