import { Configuration } from "types/props"
import { Action, ActionWithoutPayload, Message } from "types/redux"
import {
  CONFIGURATION_REQUEST,
  CONFIGURATION_SUCCESS,
  CONFIGURATION_ERROR,
} from "./constant"

const configActions = {
  request: (): ActionWithoutPayload => ({ type: CONFIGURATION_REQUEST }),
  success: (payload: Configuration): Action<Configuration> => ({
    type: CONFIGURATION_SUCCESS,
    payload,
  }),
  error: (e: string): Action<Message> => ({
    type: CONFIGURATION_ERROR,
    payload: { type: "error", text: e },
  }),
}
export default configActions
