import { request } from "lib/request"
import { put, takeLatest } from "redux-saga/effects"
import { ResponseStatistics } from "types/payload"
import { GET_STATISTICS_REQUEST } from "./constants"
import { actions } from "./actions"
import config from "react-global-configuration"
import { Action, GFlow, GWatcher, Message } from "types/redux"

async function getStatisticsApi(): Promise<ResponseStatistics> {
  return request(config.get("admin.statistics"), {
    method: "GET",
  })
}

function* statisticFlow(): GFlow<Action<ResponseStatistics | Message>> {
  try {
    const response: ResponseStatistics = yield getStatisticsApi()
    if (response) {
      yield put(actions.getStatisticsSuccess(response.statistics))
    }
  } catch (e) {
    console.error(e, {
      route: config.get("admin.statistics")
    })
    yield put(actions.getStatisticsError(e as string))
  }
}

function* statisticsWatcher(): GWatcher {
  yield takeLatest(GET_STATISTICS_REQUEST, statisticFlow)
}

export default statisticsWatcher
