import { REGISTER_PAGE, LOGIN_PAGE, HOME_PAGE } from "core/constants"
import { useNavigate } from "react-router-dom"
import { Buttons, Card } from "components/Card/Card"
import PreviousButton from "components/Previous/PreviousButton"
import { Steps } from "components/Steps"
import { Button } from "components/Button"
import { cdn } from "core/cdn"
import React from "react"
import { useLanguage } from "locales"


export const Lobby: React.FC<unknown> = ({}): JSX.Element => {
  const { languages } = useLanguage()
  const navigate = useNavigate()

  return (
    <Card
      title={languages.doYouHaveAnAccount}
      imgUrl={cdn("/images/compte_medadom_illustration.svg")}
    >
      <Steps array={[""]} current={0} />
      <PreviousButton to={HOME_PAGE} />
      <Buttons>
        <Button
          wide="long"
          onClick={() => navigate(LOGIN_PAGE)}
          type="primary"
        >
          {languages.yes}
        </Button>
        <Button wide="long" onClick={() => navigate(REGISTER_PAGE)}>
          {languages.no}
        </Button>
      </Buttons>
    </Card>
  )
}

const LobbyPage = Lobby
export default LobbyPage
