import { Divider, Tabs } from "antd"
import React, { useEffect, useState } from "react"
import { connect, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { LocalAudioTrack, LocalVideoTrack } from "twilio-video"

import { actions as documentActions } from "client/documents/actions"
import { DocumentType } from "client/documents/types"
import { Button } from "components/Button"
import { Buttons, Card } from "components/Card/Card"
import Loader from "components/Loaders/Loader/Loader"
import { GenericModal } from "components/Modal/Modal"
import PreviousButton from "components/Previous/PreviousButton"
import { Subtitle } from "components/Title/Title"
import { createUserLocalTracks } from "containers/Call/utils"
import { Diagnosis } from "containers/Diagnosis/Diagnosis"
import { logout } from "containers/Login/services/actions"
import Newsletter from "containers/Newsletter"
import paymentActions from "containers/Payment/services/actions"
import { History } from "containers/Profile/History/History"
import { AddCard, CardHistory } from "containers/Stripe"
import surveyActions from "containers/Survey/services/actions"
import { cdn } from "core/cdn"
import { SURVEY_PAGE } from "core/constants"
import { Customer, Prospect, Relative } from "types/entity"
import { Dispatcher, Message } from "types/redux"
import { ClientStore, ProfileStore } from "types/store"

import { areConnectedDeviceOff } from "../Call/utils/index"
import { PatientInformation } from "./PatientInformation"
import styles from "./Profile.module.scss"
import actions from "./services/actions"
import dayjs from "dayjs"
import { ChangePasswordModal } from "components/Modal/ChangePasswordModal"
import { GET_CALL } from "containers/Call/services/constants"
import { useLanguage } from "locales"

const checkConnectedDeviceOff = () => {
  return areConnectedDeviceOff().then((res) => {
    if (!res) {
      throw "Device still on"
    }
    return res
  })
}

const Profile = (props: ProfileStore) => {
  const { languages } = useLanguage()
  const navigate = useNavigate()
  const [logoutModalVisible, setLogoutModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [diagnosis, setDiagnosis] = useState(false)
  const [shouldResetPassword, setShouldResetPassword] = useState(false)
  const welcomeTitle = `${languages.welcome}, ${props.patient.firstname}`
  const dispatch = useDispatch()
  const [TLC, INFORMATIONS, HISTORY, PAIEMENT, OTHERS] = [
    "TLC",
    "INFORMATIONS",
    "HISTORY",
    "PAIEMENT",
    "OTHERS",
  ]

  useEffect(() => {
    const now = dayjs()
    const daysDiff = (props.patient.password_expiration_date).diff(now, "days")
    if (daysDiff <= 7) {
      // Propose le changement de mot de passe
      setShouldResetPassword(true)
    }

  }, [])

  useEffect(() => {
    if (props.success) props.resetProfile()
  }, [props.success])


  const onFinish = () => {
    setDiagnosis(false)
    dispatch(surveyActions.reset())
    dispatch(paymentActions.reset())
    props.resetProfile()
    navigate(SURVEY_PAGE)
  }

  const startDiagnostic = () => {
    setLoading(true)
    checkLocalDevices()
      .then(checkConnectedDeviceOff)
      .then(() => onFinish())
      .catch(() => setDiagnosis(true))
      .finally(() => {
        setLoading(false)
      })
  }

  const checkLocalDevices = () => {
    setLoading(true)
    return Promise.race([
      createUserLocalTracks().then((e) => {
        e.forEach(
          (track: LocalVideoTrack | LocalAudioTrack) =>
            track.stop && track.stop()
        )
        return e
      }),
      new Promise((_, rej) => setTimeout(() => rej(), 3000)),
    ])
  }

  if (shouldResetPassword)
    return <ChangePasswordModal
      patient={props.patient}
      onCancel={() => setShouldResetPassword(false)}
      onFinish={() => {
        setShouldResetPassword(false)
        dispatch({ type: GET_CALL })
      }} />

  if (diagnosis)
    return <Diagnosis onStop={() => setDiagnosis(false)} onFinish={onFinish} />

  return (
    <Card subtitle={welcomeTitle} message={props.message}>
      <PreviousButton
        danger
        text={languages.logout}
        onClick={() => setLogoutModalVisible(true)}
      />
      <Tabs
        onChange={(key: string) => {
          if (key === HISTORY && !props.calls) props.getHistory()
        }}
      >
        {/* TLC */}
        <Tabs.TabPane
          className={styles.Scroller}
          tab={<Subtitle text={languages.launch_tlc} />}
          key={TLC}
        >
          <Buttons direction="vertical">
            <img src={cdn("images/medecin_tlc_illustration.svg")} alt="" />
            <Divider />
            <Button
              type="primary"
              disabled={loading}
              loading={loading}
              onClick={startDiagnostic}
            >
              {languages.launch_tlc}
            </Button>
          </Buttons>
        </Tabs.TabPane>
        {/* INFORMATIONS */}
        <Tabs.TabPane
          className={styles.Scroller}
          tab={<Subtitle text={languages.profile_myInformations} />}
          key={INFORMATIONS}
        >
          <PatientInformation
            loading={props.loading}
            update_requires_action={props.update_requires_action}
            patient={props.patient}
            relatives={props.relatives}
            change={props.change}
            changeRelative={props.changeRelative}
          />
        </Tabs.TabPane>
        {/* HISTORY */}
        <Tabs.TabPane
          className={styles.Scroller}
          tab={<Subtitle text={languages.profile_myHistory} />}
          key={HISTORY}
        >
          {props.calls && <History calls={props.calls} />}
          {props.loading && <Loader />}
        </Tabs.TabPane>

        {/* PAIEMENT */}
        <Tabs.TabPane
          className={styles.Scroller}
          tab={<Subtitle text={languages.profile_myPayments} />}
          key={PAIEMENT}
        >
          <AddCard />
          <CardHistory />
        </Tabs.TabPane>
        <Tabs.TabPane
          className={styles.Scroller}
          tab={<Subtitle text={languages.profile_mySettings} />}
          key={OTHERS}
        >
          <Newsletter />
        </Tabs.TabPane>
      </Tabs>
      <GenericModal
        visible={logoutModalVisible}
        title={languages.logoutTitle}
        acceptText={languages.logout}
        onCancel={() => setLogoutModalVisible(false)}
        onAccept={() => {
          props.logout()
        }}
      />
    </Card>
  )
}

const mapStateToProps = ({
  profile,
  client,
  stripe,
}: {
  profile: ProfileStore
  client: ClientStore
  stripe: { message?: Message }
}): ProfileStore => {
  const stripeMessage = stripe.message
  return {
    ...profile,
    patient: client.customer as Customer,
    relatives: client.relatives as Relative[],
    message: stripeMessage || profile.message,
  }
}
const mapDispatchToProps = (dispatch: Dispatcher): Partial<ProfileStore> => ({
  change: (p: Partial<Prospect>) => dispatch(actions.changePatientRequest(p)),
  changeRelative: (r: Partial<Relative>) =>
    dispatch(actions.changeRelativeRequest(r)),
  getHistory: () => dispatch(actions.historyRequest()),
  logout: () => dispatch(logout()),
  getDocuments: (id: string, d?: DocumentType[]) =>
    dispatch(documentActions.request(id, d)),
  resetProfile: () => dispatch(actions.reset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
