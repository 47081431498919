import React from "react"

import { splitByChars } from "lib/utils"

import styles from "./FatButton.module.scss"

import * as DOMPurify from "dompurify";

const MAX_CHAR = 15
interface FatButtonProps {
  onClick?: () => void
  clicked?: boolean
  img?: string
  type?: string
  children: string | JSX.Element
  disabled?: boolean
  doNotFormat?: boolean
  font?: "default" | "medium" | "large" | "xlarge" | "xxxlarge"
  style?: any
  size?: "small"
}

const Span = (props: { children: string }) => {
  const length = props.children.length

  const className = `${styles.Span} ${
    length > MAX_CHAR ? styles.smallSize : styles.inline
  }`
  return <span className={className}>{props.children}</span>
}

const FatButton: React.FC<FatButtonProps> = (props) => {
  const spans =
    typeof props.children === "string"
      ? splitByChars(props.children as string, MAX_CHAR).map(
          (text: string, key: number) => <Span key={key}>{text}</Span>
        )
      : props.children
  const classWrapped = `${styles.FatButton} ${
    props.img ? styles.Pictured : null
  } ${props.clicked ? styles.Clicked : null} ${
    props.type
      ? props.type === "accent"
        ? styles.Accent
        : props.type === "ghost"
        ? styles.Ghost
        : styles.Primary
      : null
  } ${
    props.font ? (props.font === "xlarge" ? styles.XlargeFontSize : null) : null
  } ${props.size === "small" ? styles.Small : null}`
  return (
    <div
      className={classWrapped}
      onClick={() =>
        !props.disabled && props.onClick ? props.onClick() : undefined
      }
      data-testid="FatButton"
      style={props.style}
    >
      {props.img ? <img src={props.img} style={{objectFit: "contain"}} alt="" /> : null}
      {props.doNotFormat ? (
        typeof props.children === "string" ? (
          <span
            style={{
              width: "100%",
              lineHeight: "37px",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
            dangerouslySetInnerHTML={{
              __html: (DOMPurify.sanitize(props.children as any, { USE_PROFILES: { html: true }}) as string) || "",
            }}
          />
        ) : (
          <span>{props.children}</span>
        )
      ) : (
        spans
      )}
    </div>
  )
}

export default FatButton
