import { Col, InputProps, Row } from "antd"
import dayjs, { Dayjs } from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import { ChangeEvent, useEffect, useRef, useState } from "react"

import Input from "../Input/Input"
import styles from "./DateInput.module.scss"
import DateSelector, { isApiFormat } from "./DateSelector"
import { useLanguage } from "locales"

dayjs.extend(customParseFormat)

const MASK = "#"
export interface DateProps extends Omit<InputProps, "onChange" | "value"> {
  value?: Dayjs | string
  initialValue?: Dayjs | string
  onChange?: (e: Dayjs | string) => void
}

const maskReplacer = (
  tmpValue: string,
  start: number,
  end: number,
  language: string
) => {
  const search = Array.from(Array(end - start).keys())
    .map(() => MASK)
    .join("")

  return tmpValue.substring(start, end) === search
    ? language
    : tmpValue.substring(start, end).replaceAll(MASK, "")
}


const DateInput = (props: DateProps) => {
  const { languages } = useLanguage()
  const [dateValues, setDate] = useState<{
    day: string
    month: string
    year: string
  }>({
    day: languages.DD,
    month: languages.MM,
    year: languages.YYYY,
  })
  const refs = {
    day: useRef<HTMLInputElement>(),
    month: useRef<HTMLInputElement>(),
    year: useRef<HTMLInputElement>(),
  }

  useEffect(() => {
    const date = props.value
    if (typeof date === "string") {
      if(isApiFormat(date)){
        const values = date.split('-')
        const newDateValues = {
          day: values[2],
          month: values[1],
          year: values[0],
        }
        setDate(newDateValues)
        return 
      }else{
        const tmpValue = date.padEnd(8, MASK)
        const newDateValues = {
          day: maskReplacer(tmpValue, 0, 2, languages.DD),
          month: maskReplacer(tmpValue, 2, 4, languages.MM),
          year: maskReplacer(tmpValue, 4, 8, languages.YYYY),
        }
        setDate(newDateValues)
      }
    } else if (date && date?.isValid()) {
      const newDateValues = {
        day: date.get("date").toString().padStart(2, "0"),
        month: (date.get("month") + 1).toString().padStart(2, "0"),
        year: date.get("year").toString().padStart(4, "0"),
      }
      props.onChange &&
        props.onChange(
          `${newDateValues.day}${newDateValues.month}${newDateValues.year}`
        )
      setDate(newDateValues)
    }
  }, [props.value])

  if (props.disabled) return <DateSelector {...props} />
  return (
    <div>
      <Row gutter={[50, 0]} justify="center">
        <Col span="4">
        <div className={styles.DateInput}>
          {dateValues.day}
        </div>
        </Col>
        <Col>
          <span className={styles.Slasher}>/</span>
        </Col>
        <Col span="4">
        <div className={styles.DateInput}>
          {dateValues.month}
        </div>
        </Col>
        <Col>
          <span className={styles.Slasher}>/</span>
        </Col>
        <Col span="5">
        <div className={styles.DateInput}>
          {dateValues.year}
        </div>
        </Col>
      </Row>
      <input type="text" name={props.name} hidden maxLength={8} />
    </div>
  )
}

export default DateInput
