import {
  INSTALLATION_SUCCESS,
  INSTALLATION_ERROR,
  INSTALLATION_REQUEST,
  SET_ADMIN,
  SET_CUSTOMER,
  SET_RELATIVES,
  RESET_CLIENT,
  SET_CALL,
  SAVE_NIR_READER_RESPONSE,
  LOGGED_OUT,
} from "./constants"

import { Admin, Customer, Relative, Prospect } from "types/entity"
import { ClientStore, NirReaderResponse } from "types/store"
import { Call } from "types/payload"
import { DOCUMENTS_REQUEST, DOCUMENTS_SUCCESS } from "client/documents/constants"
import { Action } from "types/redux"
import {
  NIR_READER_RESPONSE,
  REQUEST_NIR_READER,
} from "components/NirReader/services/constants"
import { CANCEL_SIGNUP_SUCCESS } from "containers/VerifyPhone/services/constants"

const initialState: Partial<ClientStore> = {
  success: false,
  loading: true,
}

type Payload =
  | Relative[]
  | Admin
  | Customer
  | Prospect
  | Call
  | string
  | NirReaderResponse

const reducer = function clientReducer(
  state: Partial<ClientStore> = initialState,
  action: Action<Payload>
): Partial<ClientStore> {
  const installationRequest = {
    ...state,
    loading: true,
    success: false,
  }
  const installationSuccess = {
    ...state,
    loading: false,
    success: true,
  }
  const installationError = {
    ...state,
    loading: false,
    success: false,
  }

  const admin = (admin: Admin) => ({
    ...state,
    admin,
  })
  const customer = (customer: Customer) => ({
    ...state,
    customer,
  })
  const relatives = (relatives: Relative[]) => ({
    ...state,
    relatives,
  })
  const call = (call?: Call) => ({
    ...state,
    call,
  })

  switch (action.type) {
    case INSTALLATION_REQUEST:
      return installationRequest
    case INSTALLATION_SUCCESS:
      return installationSuccess
    case INSTALLATION_ERROR:
      return installationError
    case SET_ADMIN:
      return admin(action.payload as Admin)
    case SET_CUSTOMER:
      return customer(action.payload as Customer)
    case SET_RELATIVES:
      return relatives(action.payload as Relative[])
    case SET_CALL:
      return call(action.payload as Call)
    case SAVE_NIR_READER_RESPONSE:
      return {
        ...state,
        nirReaderResponse: action.payload as NirReaderResponse,
      }
    case RESET_CLIENT:
      return initialState
    case DOCUMENTS_SUCCESS:
      return { ...state, documentUrl: action.payload as string }
    case DOCUMENTS_REQUEST:
      return { ...state, documentUrl: null }
    // remove nirReaderResponse
    case CANCEL_SIGNUP_SUCCESS:
    case REQUEST_NIR_READER:
    case LOGGED_OUT:
      return { ...state, nirReaderResponse: undefined }
    default:
      return { ...state }
  }
}

export default reducer
