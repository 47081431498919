import PDFMerger from "pdf-merger-js"

export async function getMergedBlob(blobs: Blob[]): Promise<Blob> {
  // Instanciate PdfMerger
  const merger = new PDFMerger()

  // get Promise of ArrayBuffers from blobs
  const arrayBuffersPromise: Promise<ArrayBuffer>[] = blobs.map(
    async (blob) => await blob.arrayBuffer()
  )

  // get ArrayBuffers from promises
  const arrayBuffers: ArrayBuffer[] = await Promise.all(arrayBuffersPromise)

  // Foreach arrayBuffer, create Buffer and add it to PdfMerger
  arrayBuffers.forEach((arrayBuffer) => {
    const buffer = Buffer.from(arrayBuffer)
    merger.add(buffer)
  })

  // SaveasBuffer PdfMerger to receive a Buffer
  const bufferMerger: Buffer = await merger.saveAsBuffer()
  // Create ArrayBuffer from Buffer
  const arrayBufferMerger: ArrayBuffer = toArrayBuffer(bufferMerger)
  // Create  and return Blob from arrayBuffer
  const blob = new Blob([arrayBufferMerger], {
    type: "application/pdf",
  })
  return blob as Blob
}
const toArrayBuffer = (buf: Buffer): ArrayBuffer => {
  const ab = new ArrayBuffer(buf.length)
  const view = new Uint8Array(ab)
  for (let i = 0; i < buf.length; ++i) {
    view[i] = buf[i]
  }
  return ab as ArrayBuffer
}
