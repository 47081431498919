import config from "react-global-configuration"
import { all, put, takeLatest } from "redux-saga/effects"

import { RELATIVES_REQUEST } from "client/services/constants"
import { request } from "lib/request"
import {
  InsertRelativeRequest,
  RelativeApi,
  ResponseInsertRelative,
} from "types/payload"
import { Action, GWatcher } from "types/redux"

import {
  insertRelativeError,
  insertRelativeSuccess,
} from "./actions"
import { INSERT_RELATIVE_REQUEST, INSERT_RELATIVES_REQUEST } from "./constants"
import { formatCustomerINSPayload } from "lib/api-compliance"

async function insertRelativeApi(
  payload?: InsertRelativeRequest
): Promise<ResponseInsertRelative> {
  const formattedPayload = formatCustomerINSPayload(payload);
  return await request(config.get("patient.post.relative"), {
    method: "POST",
    payload: formattedPayload,
  })
}

function* insertRelativeFlow({ payload }: Action<InsertRelativeRequest>) {
  try {
    const response: ResponseInsertRelative = yield insertRelativeApi(payload)
    yield put(insertRelativeSuccess(response.relative))
  } catch (error) {
    console.error(error, {
      route: config.get("patient.post.relative")
    })
    yield all([put(insertRelativeError(error as string))])
  }
}

function* insertRelativesFlow({ payload }: Action<RelativeApi[]>) {
  try {
    const relativesResponse = payload.map(async (relative: RelativeApi) => {
      return await insertRelativeApi(relative)
    })
    yield put({ type: RELATIVES_REQUEST })
  } catch (error) {
    console.error(error, {
      route: "saga::insertRelativesFlow"
    })
  }
}
function* relativeWatcher(): GWatcher {
  yield takeLatest(INSERT_RELATIVE_REQUEST, insertRelativeFlow)
  yield takeLatest(INSERT_RELATIVES_REQUEST, insertRelativesFlow)
}

export default relativeWatcher
