import { Relative } from "types/entity"
import { Action, Message } from "types/redux"
import { InsertRelativeStore } from "types/store"

import {
  INSERT_RELATIVE_ERROR,
  INSERT_RELATIVE_REQUEST,
  INSERT_RELATIVE_SUCCESS,
  RESET_RELATIVE_STORE,
} from "./constants"
import { languages } from "locales/languages"

export const initialState: Partial<InsertRelativeStore> = {
  success: false,
  loading: false,
}

const reducer = function relativeReducer(
  state = initialState,
  action: Action<Relative | Message>
): Partial<InsertRelativeStore> {
  const newState = { ...state }
  switch (action.type) {
    case RESET_RELATIVE_STORE: {
      return { ...initialState }
    }
    case INSERT_RELATIVE_SUCCESS:
      return {
        ...newState,
        success: true,
        loading: false,
        message: {
          text: languages.RelativeAddedSuccessfully,
          type: "success",
        },
      }

    case INSERT_RELATIVE_ERROR:
      return {
        ...newState,
        success: false,
        loading: false,
        message: action.payload as Message,
      }
    case INSERT_RELATIVE_REQUEST:
      return { ...newState, loading: true, success: false }
    default:
      return newState
  }
}

export default reducer
