export const GET_PHARMACY_CARERS_REQUEST = "GET_PHARMACY_CARERS_REQUEST"
export const GET_PHARMACY_CARERS_SUCCESS = "GET_PHARMACY_CARERS_SUCCESS"
export const GET_PHARMACY_CARERS_ERROR = "GET_PHARMACY_CARERS_ERROR"

export const ADD_PHARMACY_CARERS_REQUEST = "ADD_PHARMACY_CARERS_REQUEST"
export const ADD_PHARMACY_CARERS_SUCCESS = "ADD_PHARMACY_CARERS_SUCCESS"
export const ADD_PHARMACY_CARERS_ERROR = "ADD_PHARMACY_CARERS_ERROR"

export const GET_PATIENT_CARERS_REQUEST = "GET_PATIENT_CARERS_REQUEST"
export const GET_PATIENT_CARERS_SUCCESS = "GET_PATIENT_CARERS_SUCCESS"
export const GET_PATIENT_CARERS_ERROR = "GET_PATIENT_CARERS_ERROR"

export const DELETE_PHARMACY_CARER_REQUEST = "DELETE_PHARMACY_CARER_REQUEST"
export const DELETE_PHARMACY_CARER_SUCCESS = "DELETE_PHARMACY_CARER_SUCCESS"
export const DELETE_PHARMACY_CARER_ERROR = "DELETE_PHARMACY_CARER_ERROR"

export const DELETE_PATIENT_CARER_REQUEST = "DELETE_PATIENT_CARER_REQUEST"
export const DELETE_PATIENT_CARER_SUCCESS = "DELETE_PATIENT_CARER_SUCCESS"
export const DELETE_PATIENT_CARER_ERROR = "DELETE_PATIENT_CARER_ERROR"

export const GET_RELATIONSHIP_PATIENT_CARERS_REQUEST =
  "GET_RELATIONSHIP_PATIENT_CARERS_REQUEST"
export const GET_RELATIONSHIP_PATIENT_CARERS_SUCCESS =
  "GET_RELATIONSHIP_PATIENT_CARERS_SUCCESS"
export const GET_RELATIONSHIP_PATIENT_CARERS_ERROR =
  "GET_RELATIONSHIP_PATIENT_CARERS_ERROR"

export const ADD_RELATIONSHIP_PATIENT_CARERS_REQUEST =
  "ADD_RELATIONSHIP_PATIENT_CARERS_REQUEST"
export const ADD_RELATIONSHIP_PATIENT_CARERS_SUCCESS =
  "ADD_RELATIONSHIP_PATIENT_CARERS_SUCCESS"
export const ADD_RELATIONSHIP_PATIENT_CARERS_ERROR =
  "ADD_RELATIONSHIP_PATIENT_CARERS_ERROR"

export const ADD_PATIENT_CARERS_REQUEST = "ADD_PATIENT_CARERS_REQUEST"
export const ADD_PATIENT_CARERS_SUCCESS = "ADD_PATIENT_CARERS_SUCCESS"
export const ADD_PATIENT_CARERS_ERROR = "ADD_PATIENT_CARERS_ERROR"

export const GET_IDENTITY_FROM_RPPS_REQUEST = "GET_IDENTITY_FROM_RPPS_REQUEST"
export const GET_IDENTITY_FROM_RPPS_SUCCESS = "GET_IDENTITY_FROM_RPPS_SUCCESS"
export const GET_IDENTITY_FROM_RPPS_ERROR = "GET_IDENTITY_FROM_RPPS_ERROR"

export const CANCEL_PROFESSIONAL_TO_INSERT = "CANCEL_PROFESSIONAL_TO_INSERT"

export const CARERS_RESET_STORE = "CARERS_RESET_STORE"

export const CARERS_CLEAR_MESSAGE = "CARERS_CLEAR_MESSAGE"
