export const [
  SET_PHONE_CODE_REQUEST,
  SET_PHONE_CODE_SUCCESS,
  SET_PHONE_CODE_ERROR,
  CANCEL_SIGNUP_REQUEST,
  CANCEL_SIGNUP_SUCCESS,
  CANCEL_SIGNUP_ERROR,
  SEND_AGAIN_ERROR,
  SEND_AGAIN_REQUEST,
  SEND_AGAIN_SUCCESS,
  RESET_VERIFYPHONE_STORE,
] = [
  "SET_PHONE_CODE_REQUEST",
  "SET_PHONE_CODE_SUCCESS",
  "SET_PHONE_CODE_ERROR",
  "CANCEL_SIGNUP_REQUEST",
  "CANCEL_SIGNUP_SUCCESS",
  "CANCEL_SIGNUP_ERROR",
  "SEND_AGAIN_ERROR",
  "SEND_AGAIN_REQUEST",
  "SEND_AGAIN_SUCCESS",
  "RESET_VERIFYPHONE_STORE",
]
