import { Statistics } from "types/payload"
import { Action, ActionWithoutPayload, Message } from "types/redux"
import {
  GET_STATISTICS_ERROR,
  GET_STATISTICS_REQUEST,
  GET_STATISTICS_SUCCESS,
} from "./constants"

export const actions = {
  getStatisticsRequest: (): ActionWithoutPayload => ({
    type: GET_STATISTICS_REQUEST,
  }),
  getStatisticsSuccess: (payload: Statistics): Action<Statistics> => ({
    type: GET_STATISTICS_SUCCESS,
    payload,
  }),
  getStatisticsError: (error: string): Action<Message> => ({
    type: GET_STATISTICS_ERROR,
    payload: {
      type: "error",
      text: error,
    },
  }),
}
