import { eventChannel } from "@redux-saga/core"
import {
  call,
  delay,
  put,
  select,
  take,
  takeLatest,
  takeLeading,
} from "@redux-saga/core/effects"
import config from "react-global-configuration"
import { EventChannel } from "redux-saga"
import { all } from "redux-saga/effects"
import { IMessageEvent, w3cwebsocket as W3CWebSocket } from "websocket"

import { formatReaderSocketErrorByType } from "lib/api-errors"
import { getPatientsFromDataCarteVitale } from "lib/sesam"
import { SocketSendMessage, TerminalInformation, WebSocketMessage } from "types/payload"
import {
  Action,
  ActionWithoutPayload,
  GFlow,
  GWatcher,
  Message,
  WebSocketAction,
} from "types/redux"
import { DataCarteVitale } from "types/sesam"
import { GlobalStore, NirReaderResponse, SocketStore } from "types/store"

import {
  decrementRetryBy,
  errorCardSocketNirReader,
  removeCardSocketNirReader,
  removedCardSocketNirReader,
  requestSocketNirReader,
  resetNumberOfRetry,
  resetSocketNirReader,
  setSocket,
  socketNirReaderResponse,
} from "./actions"
import {
  CHANGE_NIR_READER_TYPE,
  SOCKET_CANCEL_CARD_VITALE_READER,
  SOCKET_INIT_CARD_VITALE_READER,
  SOCKET_LAUNCH_CARD_VITALE_READER,
  SOCKET_SEND_MESSAGE,
  SOCKET_SUBSCRIBE_CLIENT,
  SOCKET_LOG_TERMINAL_INFORMATION,
  SOCKET_SEND_TERMINAL_INFORMATION,
  socketAction,
  socketType,
} from "./constants"
import { getWssHost, ReadyState, staging, WEBSOCKET_OPEN_STATE } from "./utils"
import { request } from "lib/request";
import { languages } from "locales/languages"

function* handleSocketErrorMsg(action: ActionWithoutPayload) {
  const translatedError = formatReaderSocketErrorByType(action.type)
  yield put(errorCardSocketNirReader(translatedError))
  return
}

function* readerSocketManager(
  webSocketAction: WebSocketAction<
    Action<DataCarteVitale> | ActionWithoutPayload | Action<TerminalInformation> 
  >
): GFlow<Action<NirReaderResponse | Message>> {
  const { type, result, payload } = webSocketAction

  if(type === socketAction.ALL_INFOS) {
    const body = {...payload} as TerminalInformation;
    const currentChromeVersion = window.navigator.userAgent;
    body.terminal.webbrowser_version = currentChromeVersion;
    yield put({type: SOCKET_SEND_TERMINAL_INFORMATION, payload: body});
  }

  if (result === "KO") {
    yield handleSocketErrorMsg(webSocketAction.payload as ActionWithoutPayload)
  }
  if (payload.type === socketAction.REMOVE_TIMEOUT) {
    yield put(removedCardSocketNirReader())
  }
  if (result === socketAction.TIMEOUT) {
    yield handleSocketErrorMsg(webSocketAction.payload as ActionWithoutPayload)
  } else if (
    result === "OK" &&
    type === "get" &&
    (payload as Action<DataCarteVitale>).payload
  ) {
    const response: NirReaderResponse = yield getPatientsFromDataCarteVitale(
      (payload as Action<DataCarteVitale>).payload
    )
    yield put(socketNirReaderResponse(response as NirReaderResponse))
  } else {
    if (payload.type === socketAction.REMOVE) {
      yield put(removeCardSocketNirReader())
    } else if (payload.type === socketAction.REMOVED) {
      yield put(removedCardSocketNirReader())
    }
  }
}

function* incomingPayload(
  webSocketAction: WebSocketAction<
    Action<DataCarteVitale> | ActionWithoutPayload
  >
): GFlow<WebSocketAction<Action<DataCarteVitale> | ActionWithoutPayload>> {
  const { result, domain } = webSocketAction
  if (domain === socketType.VITAL_CARD || domain === socketType.INFOS) {
    yield readerSocketManager(webSocketAction)
  }
  if (result !== "OK") {
    yield 0
  }
}

function* launchReaderCard(): GFlow<ActionWithoutPayload> {
  const client: W3CWebSocket | undefined = yield call(initSocket)
  const stopLookingForClient = yield select(
    ({ socket }: GlobalStore) => socket.stop || false
  )

  if (stopLookingForClient) return yield all([put(resetSocketNirReader())])
  if (!client) {
    // Si le websocket n'existe pas,
    // On passe au modèle de lecteur de carte via l'API
    yield put({ type: CHANGE_NIR_READER_TYPE, payload: "api" })
    return
  }
  try {
    if (client.readyState !== ReadyState.OPEN) {
      console.warn("[launchReaderCard] client not ready")
      yield put(errorCardSocketNirReader(languages.cannotStartReader))
    } else {
      console.info("[launchReaderCard] SEND carte_vitale")
      send(client, {
        type: socketType.VITAL_CARD,
        action: socketAction.GET,
        body: {
          timeout: config.get("timeoutNirReaderInS"),
        },
      })
      yield put({ type: SOCKET_LAUNCH_CARD_VITALE_READER })
    }
  } catch (e) {
    console.warn("[launchReaderCard] error", e)
    console.error(e, {route: "wss::launchReaderCard"})
    yield put(errorCardSocketNirReader("[launchReaderCard] error"))
  }
}

function* cancelReaderCard() {
  console.info("---- CANCEL vital card READER ----")
  yield 0
}

function* createSocketChannel(
  client: W3CWebSocket
): GFlow<EventChannel<unknown>> {
  return yield eventChannel((emitMessage) => {
    client.onmessage = (message: IMessageEvent) => {
      const data: WebSocketMessage = JSON.parse(message.data as string)
      if (data.type === socketType.VITAL_CARD || data.type === socketType.INFOS) {
        emitMessage({
          data: {
            domain: data.type,
            type: data.action,
            result: data.result,
            payload: data.body,
          },
          client,
        })
      }
    }

    client.onclose = () => {
      // ici, récupération de l'état fermé du socket courant
      console.error(`[WS subscribe] Le socket courant s'est fermé`, {
        route: "wss::client::onclose"
      })
      const message = {
        type: socketType.VITAL_CARD,
        action: socketAction.SOCKET_CLOSED,
        body: undefined,
      }
      emitMessage({
        data: {
          domain: message.type,
          type: message.action,
          payload: message.body,
        },
        client,
      })
    }

    const unsubscribe = () => {
      client.close()
    }
    return unsubscribe
  })
}

function* sendMessage(payload: SocketSendMessage) {
  const client: W3CWebSocket = yield call(initSocket)
  send(client, payload)
  return
}

function send(client: W3CWebSocket | undefined, payload: SocketSendMessage) {
  if (client && client.readyState === WEBSOCKET_OPEN_STATE)
    client.send(JSON.stringify(payload))
}

function* waitSocketInOpenState(): GFlow<W3CWebSocket | undefined> {
  // delai avant de tenter le rétablissement de la connexion WS
  const numberOfRetry = yield select(
    ({ socket }: { socket: SocketStore }) => socket?.numberOfRetryLeft
  )
  const WSS_SOCKET = getWssHost()
  const client = yield new W3CWebSocket(WSS_SOCKET)
  const isOpened = yield staging(client)

  const stopLookingForClient = yield select(
    ({ socket }: GlobalStore) => socket.stop || false
  )

  if (stopLookingForClient) {
    return undefined
  }

  if (isOpened) {
    yield all([
      put(resetNumberOfRetry()),
      put({ type: SOCKET_SUBSCRIBE_CLIENT, payload: client }),
    ])
    return client
  } else if (numberOfRetry > 1) {
    client.close()
    yield all([put(decrementRetryBy(1)), delay(1000)])
    return yield call(waitSocketInOpenState)
  } else {
    console.error(`P.3/ [WS], can't connect to WebSocket`, {
      route: "wss::waitSocketInOpenState"
    })
    return undefined
  }
}

function* initSocket(): GFlow<W3CWebSocket | undefined> {
  // retourne le websocket courant s'il existe
  // sinon retourne un nouveau ws
  console.info("---------INITIALIZE WEBSOCKET----------")
  let client: W3CWebSocket = yield select(
    ({ socket }: { socket: SocketStore }) => socket?.client
  )

  if (!client || client.readyState !== WEBSOCKET_OPEN_STATE) {
    client = yield waitSocketInOpenState()
    yield put(setSocket(client))
  }
  return client
}

function* subscribeWebSocket({
  payload,
}: {
  type: string
  payload: W3CWebSocket
}): GFlow<{ data: WebSocketAction<unknown> }> {
  console.info("---------SOCKET LINK CLIENT----------")
  const socketChannel: EventChannel<unknown> = yield call(
    createSocketChannel,
    payload
  )
  while (true) {
    try {
      const {
        data,
      }: {
        data: WebSocketAction<Action<DataCarteVitale> | ActionWithoutPayload>
      } = yield take(socketChannel)

      if (data.type === socketAction.SOCKET_CLOSED) {
        return yield put(requestSocketNirReader())
      } else {
        yield call(incomingPayload, data)
      }
    } catch (err) {
      console.error(err, {route: "wss::subscribeWebSocket"})
    }
  }
}

function* patchManageEngineInformation(manageEngine, terminalUuid) {
  const patchTerminalUrl = config.get("terminals.patch");
  // patch manageengine information
  if(manageEngine && Object.keys(manageEngine).length > 0) {
    yield request(`${patchTerminalUrl}/${terminalUuid}/manageengine`, {method: "PATCH", payload: manageEngine});
  }
}

function* patchTeamViewerInformation(teamViewer, terminalUuid) {
  const patchTerminalUrl = config.get("terminals.patch");
  // patch terminal information
  if(teamViewer && Object.keys(teamViewer).length > 0){
    yield request(`${patchTerminalUrl}/${terminalUuid}/teamviewer`, {method: "PATCH", payload: teamViewer});
  }
}

function* bulkPatchTerminalsInformation(payload: TerminalInformation, terminalUuid) {
  const patchTerminalUrl = config.get("terminals.patch");
  const { manageengine: manageEngine, teamviewer: teamViewer, ...body } = payload;

  yield request(`${patchTerminalUrl}/${terminalUuid}`, {method: "PATCH", payload: body });

  yield patchManageEngineInformation(manageEngine, terminalUuid);

  yield patchTeamViewerInformation(teamViewer, terminalUuid);

}

function* createOrUpdateTerminalInformation(payload: TerminalInformation) {
  const getTerminalUrl = config.get("terminals.get");
  const postTerminalUrl = config.get("terminals.post");
  const terminalUuid = sessionStorage.getItem("terminal");
  if(!terminalUuid) {
    const terminalInformation = yield request(getTerminalUrl, { method: "GET" });
    if(terminalInformation?.terminal?.guid) {
      sessionStorage.setItem("terminal", terminalInformation.terminal?.guid);
      yield bulkPatchTerminalsInformation(payload, terminalInformation.terminal?.guid);
    } else {
      const res = yield request(postTerminalUrl, {method: "POST", payload })
      sessionStorage.setItem("terminal", res?.terminal?.guid);
      yield patchManageEngineInformation(payload?.manageengine, res?.terminal?.guid);

      yield patchTeamViewerInformation(payload?.teamviewer, res?.terminal?.guid);
    }
  } else {
    yield bulkPatchTerminalsInformation(payload, terminalUuid);
  }
}

function* sendTerminalInformation({ payload } : any) {
  try {
    const { terminal, manageengine, teamviewer } = payload;
    // FIX to handle ipv6 in ipv4 information
    if(terminal.ipv4_private.length >15) {
      delete terminal.ipv4_private;
    };
    const body = {...terminal, manageengine, teamviewer };

    yield createOrUpdateTerminalInformation(body)
  } catch (error) {
    console.error("error when uploading terminal information : ", error)
  }
}

function* logTerminalInformation() {
  yield sendMessage({ type: socketType.INFOS, action: socketAction.ALL_INFOS, body: {}  })
}

export default function* rootSaga(): GWatcher {
  yield takeLatest(SOCKET_INIT_CARD_VITALE_READER, launchReaderCard)
  // yield takeLatest(SOCKET_RESET_CARD_VITALE_READER, resetReaderCard)
  yield takeLatest(SOCKET_CANCEL_CARD_VITALE_READER, cancelReaderCard)
  yield takeLeading(SOCKET_SEND_MESSAGE, sendMessage)
  yield takeLeading(SOCKET_SUBSCRIBE_CLIENT, subscribeWebSocket)
  yield takeLeading(SOCKET_LOG_TERMINAL_INFORMATION, logTerminalInformation)
  yield takeLeading(SOCKET_SEND_TERMINAL_INFORMATION, sendTerminalInformation)
}
