import { Call, CustomerVerify, ResponsePutCustomerVerify } from "types/payload"
import { Action, Message } from "types/redux"
import { ProfileStore } from "types/store"

import {
  CHANGE_PATIENT_ERROR,
  CHANGE_PATIENT_REQUEST,
  CHANGE_PATIENT_SUCCESS,
  CHANGE_RELATIVE_ERROR,
  CHANGE_RELATIVE_REQUEST,
  CHANGE_RELATIVE_SUCCESS,
  HISTORY_ERROR,
  HISTORY_REQUEST,
  HISTORY_SUCCESS,
  RESET_PROFILE_STORE,
} from "./constants"

const initialState: Partial<ProfileStore> = {
  loading: false,
  success: false,
}
const Reducer = (
  state = initialState,
  action: Action<Call[] | Message | CustomerVerify>
): Partial<ProfileStore> => {
  switch (action.type) {
    case HISTORY_SUCCESS:
      return {
        ...state,
        calls: action.payload as Call[],
        loading: false,
      }
    case CHANGE_PATIENT_SUCCESS:
    case CHANGE_RELATIVE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        message: action.payload as Message,
      }
    case HISTORY_ERROR:
    case CHANGE_PATIENT_ERROR:
    case CHANGE_RELATIVE_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: action.payload as Message,
      }

    case HISTORY_REQUEST:
    case CHANGE_PATIENT_REQUEST:
    case CHANGE_RELATIVE_REQUEST:
      return { ...state, loading: true }

    case RESET_PROFILE_STORE:
      return { ...initialState, message: null }

    case "UPDATE_REQUIRES_ACTION":
      return {
        ...state,
        update_requires_action: {
          ...state.update_requires_action,
          success: false,
          loading: false,
          ...(action.payload as CustomerVerify),
        },
      }
    case "UPDATE_VERIFY_REQUEST":
      return {
        ...state,
        update_requires_action: {
          ...state.update_requires_action,
          success: false,
          loading: true,
        },
      }
    case "UPDATE_VERIFY_MESSAGE":
      return {
        ...state,
        update_requires_action: {
          ...state.update_requires_action,
          message: action.payload as Message,
          success: false,
          loading: false,
        },
      }
    case "UPDATE_VERIFY_CANCEL":
      return {
        ...state,
        update_requires_action: null,
        loading: false,
      }
    case "UPDATE_VERIFY_ERROR":
      return {
        ...state,
        update_requires_action: {
          ...state.update_requires_action,
          message: action.payload as Message,
          success: false,
          loading: false,
        },
      }
    case "UPDATE_VERIFY_SUCCESS":
      return {
        ...state,
        update_requires_action: null,
      }
    default:
      return { ...state }
  }
}

export default Reducer
