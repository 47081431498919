import {
  ASKING_FOR_2FA_TOGGLE,
  ASKING_FOR_2FA_VERIFY,
  LOGIN_ERROR,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  REQUEST_ONGOING,
  RESEND_REQUEST_SUCCESS,
  RESET_LOGIN_STORE,
  TWO_FA_VERIFY_ERROR,
  _2FA_CANCELED,
} from "./constants"

import { Customer } from "types/entity"
import { LoginStore } from "types/store"
import { Action, Message } from "types/redux"
import { twoFaData } from "types/payload"
import { languages } from "locales/languages"

const initialState: Partial<LoginStore> = {
  success: false,
  loading: false,
  twoFaData: undefined,
  activate2Fa: undefined
}

const reducer = function loginReducer(
  state = initialState,
  action: Action<Customer | Message | twoFaData>
): Partial<LoginStore> {
  switch (action.type) {
    case REQUEST_ONGOING : 
    case LOGIN_REQUEST:
      return { ...state, loading: true, message: undefined }
    case LOGIN_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
      }
    case TWO_FA_VERIFY_ERROR:
    case LOGIN_ERROR:
      return {
        ...state,
        success: false,
        loading: false,
        message: action.payload as Message,
      }
    case ASKING_FOR_2FA_VERIFY:
      return { ...state, 
              loading: false, 
              activate2Fa : false,
              twoFaData : action.payload as twoFaData}
    case RESEND_REQUEST_SUCCESS:
      const { transport } = action.payload as twoFaData;
      const message = { text: languages.twoFa_codeHasBeenResentBy(languages[transport] || 'sms'), type: "success" }
      return { ...state,
              loading: false,
              twoFaData : action.payload as twoFaData,
              message: message as Message
             }
    case ASKING_FOR_2FA_TOGGLE:
      return {...state, 
              loading: false, 
              activate2Fa : true,
              twoFaData : action.payload as twoFaData,
              }
    case _2FA_CANCELED:
    case RESET_LOGIN_STORE:
      return { ...initialState }
    default:
      return { ...state }
  }
}

export default reducer
