import {
  CHANGE_PATIENT_ERROR,
  CHANGE_PATIENT_REQUEST,
  CHANGE_PATIENT_SUCCESS,
  CHANGE_RELATIVE_ERROR,
  CHANGE_RELATIVE_REQUEST,
  CHANGE_RELATIVE_SUCCESS,
} from "containers/Profile/services/constants"
import { SUBMIT_SURVEY_REQUEST } from "containers/Survey/services/constants"
import { Answer } from "containers/Survey/types"
import { ResponsePrice } from "types/payload"
import { Action, Message } from "types/redux"
import { NirStore } from "types/store"
import { RESET_NIR_AND_EXEMPTION_STORE } from "./constants"

const initialState: Partial<NirStore> = {
  loading: false,
  success: false,
}

const NirReducer = (
  state = initialState,
  action: Action<Answer[] | Message | ResponsePrice>
): Partial<NirStore> => {
  switch (action.type) {
    case SUBMIT_SURVEY_REQUEST:
      return { ...state, answers: action.payload as Answer[] }
    case CHANGE_PATIENT_REQUEST:
    case CHANGE_RELATIVE_REQUEST:
      return { ...state, loading: true, message: undefined }
    case CHANGE_PATIENT_SUCCESS:
    case CHANGE_RELATIVE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      }
    case CHANGE_RELATIVE_ERROR:
    case CHANGE_PATIENT_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: action.payload as Message,
      }
    case RESET_NIR_AND_EXEMPTION_STORE:
      return { ...initialState }
    default:
      return { ...state }
  }
}

export default NirReducer
