import React, { useEffect } from "react"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { useDispatch, useSelector } from "react-redux"
import { CardsStore } from "types/store"
import actions from "../services/actions"

const stripePromise = (public_stripe_key: string) => {
  return loadStripe(public_stripe_key)
}

const Stripe: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
  const dispatch = useDispatch()
  const public_stripe_key = useSelector(
    ({ stripe }: { stripe: CardsStore }) => stripe.public_stripe_key
  )
  useEffect(() => {
    dispatch(actions.getTokenRequest())
  }, [])

  return public_stripe_key ? (
    <Elements stripe={stripePromise(public_stripe_key)}>{children}</Elements>
  ) : null
}

export default Stripe
