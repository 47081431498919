import { Modal as AntdModal, Col, Row } from "antd"
import { omit } from "lodash"
import { useEffect, useState } from "react"
import config from "react-global-configuration"
import { useDispatch } from "react-redux"

import { Button } from "components/Button"
import FatButton from "components/Button/FatButton/FatButton"
import { Buttons } from "components/Card/Card"
import PreviousButton from "components/Previous/PreviousButton"
import { Title } from "components/Title/Title"
import { logout } from "containers/Login/services/actions"
import { cdn } from "core/cdn"
import { ModalProps } from "types/props"

import * as DOMPurify from "dompurify";

import styles from "./Modal.module.scss"
import { useLanguage } from "locales"

export const Modal: React.FC<ModalProps> = (props): JSX.Element => {
  const { languages } = useLanguage()
  const ErrorIcon = () => (
    <img
      src={cdn("images/devices/tutorial_device/attention.png")}
      alt="warning icon"
    />
  )

  const titleHeader = (
    <Row justify="center" wrap={false} style={{ marginTop: "50px" }}>
      {props.iconError && (
        <Col flex="none">
          <ErrorIcon />
        </Col>
      )}
      <Col flex="auto">
        <Title text={props.title} {...props.titleProps} />
      </Col>
      {props.iconError && (
        <Col flex="none">
          <ErrorIcon />
        </Col>
      )}
    </Row>
  )

  const title = props.closable ? (
    <div>
      <PreviousButton
        text={languages.close}
        onClick={() => {
          if (props.onClose) props.onClose()
          else if (props.onCancel) props.onCancel()
        }}
      />
      {props.title ? titleHeader : null}
    </div>
  ) : props.title ? (
    titleHeader
  ) : null
  return (
    <AntdModal
      width="100%"
      className={styles.Modal}
      centered
      title={title}
      bodyStyle={props.bodyStyle}
      closable={false}
      open={props.visible}
      footer={
        props.footer ? (
          props.footer
        ) : !props.noFooter ? (
          <Buttons>
            {props.onCancel && (
              <Button
                wide="long"
                type="link"
                onClick={props.onCancel}
                {...props.cancelButtonProps}
              >
                {props.cancelText || languages.previous}
              </Button>
            )}
            {props.onAccept && (
              <Button
                wide="long"
                loading={props.loading}
                disabled={props.loading}
                onClick={props.onAccept}
                {...props.acceptButtonProps}
              >
                {props.acceptText || languages.confirm}
              </Button>
            )}
          </Buttons>
        ) : null
      }
    >
      {props.children}
    </AntdModal>
  )
}

export const GenericModal: React.FC<ModalProps> = (props): JSX.Element => {
  const { languages } = useLanguage()
  return (
  <Modal
    {...props}
    footer={
      props.footer ? (
        props.footer
      ) : !props.noFooter ? (
        <Buttons style = {{...props.buttonWrapperStyle}}>
          {props.onCancel && (
            <Button
              wide="long"
              {...props.cancelButtonProps}
              onClick={() => props.onCancel && props.onCancel()}
            >
              {props.cancelText || languages.cancel}
            </Button>
          )}
          {props.onAccept && (
            <Button
              wide="long"
              danger
              type="primary"
              {...props.acceptButtonProps}
              onClick={() => props.onAccept && props.onAccept()}
            >
              {props.acceptText}
            </Button>
          )}
        </Buttons>
      ) : undefined
    }
  >
    <div className="card-contenant font-size-large">
      <div
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(props.contentText, { USE_PROFILES: { html: true }}) || "",
        }}
      ></div>
      {props.children}
    </div>
  </Modal>
)}

export const ConsentModal = (props: {
  visible: boolean
  onAccept: () => void
  onCancel: () => void
}): JSX.Element => {
  const { languages } = useLanguage()
  const [showCGU, setShowCGU] = useState(false)
  const [showPrivacy, setShowPrivacy] = useState(false)
  const onAccept = !showPrivacy && !showCGU ? props.onAccept : undefined
  return (
    <GenericModal
      {...props}
      onAccept={onAccept}
      title={languages.cguAndPrivacyTitle}
      acceptText={languages.IAcceptConsent}
      acceptButtonProps={{ danger: false }}
      cancelText={
        showCGU || showPrivacy ? languages.previous : languages.IRefuseConsent
      }
      onCancel={
        showCGU || showPrivacy
          ? () => {
              setShowCGU(false)
              setShowPrivacy(false)
            }
          : props.onCancel
      }
    >
      <div>
        <div style={{ textAlign: "center" }} hidden={showCGU || showPrivacy}>
          {languages.IhaveReadAndAccept}
          <a onClick={() => setShowCGU(true)}>
          {languages.theCGU}
          </a>{languages.andThe}
          <a
            onClick={() => {
              setShowPrivacy(true)
            }}
          >
            {languages.privacyPolicies}
          </a>
          {languages.ofMedadom}
        </div>
        <div
          hidden={!showCGU}
          className={styles.CGUContent}
        ><iframe src="https://info.medadom.com/cgu" style={{width: "-webkit-fill-available", height: "-webkit-fill-available"}} title="privacy_and_cgu" /> </div>
        <div hidden={!showPrivacy} className={styles.CGUContent}>
          <iframe src="https://info.medadom.com/politique-de-confidentialite" style={{width: "-webkit-fill-available", height: "-webkit-fill-available"}} title="privacy_and_cgu" />
        </div>
      </div>
    </GenericModal>
  )
}

export const GenericCancelModal = (props: any): JSX.Element => {
  const { languages } = useLanguage()
  return (
  <AntdModal
    width="100%"
    className={styles.Modal}
    centered
    title={
      <div className={styles.iconTitle}>
        <img
          src={cdn("/icons/warning_icon.svg")}
          alt="generic_cancel_modal_icon"
        />
      </div>
    }
    closable={false}
    open={props.visible}
    footer={
      <Buttons>
        {props.onCancel && (
          <Button
            style={{ borderColor: "#D64949", color: "#D64949" }}
            wide="long"
            type="link"
            onClick={props.onCancel}
          >
            {languages.cancelConsultation}
          </Button>
        )}
        {props.onAccept && (
          <Button
            wide="long"
            type="primary"
            loading={props.loading}
            disabled={props.loading}
            onClick={props.onAccept}
          >
            {props.onAcceptText || languages.continueConsultation}
          </Button>
        )}
      </Buttons>
    }
  >
    <div className={styles.cancelConsultationContent}>
      {props.contentText || languages.cancel_warning}
    </div>
  </AntdModal>
)}

export const IdleModal = (props: any) => {
  const { languages } = useLanguage()
  const Initialseconds = config.get("disconnectTimer.seconds") || 30
  const InitialMinutes = config.get("disconnectTimer.minutes") || 1

  const dispatch = useDispatch()

  const [remainingSecond, setRemainingSecond] = useState(Initialseconds)
  const [remainingMinute, setRemainingMinute] = useState(InitialMinutes)
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null)
  const [tabVisible, setTabVisible] = useState(true)
  useEffect(() => {
    document.addEventListener("visibilitychange", function () {
      // clear Interval if user leave the tab
      if (document.hidden) {
        timer && clearInterval(timer)
        setTabVisible(false)
      } else {
        setTabVisible(true)
      }
    })
  }, [])

  useEffect(() => {
    clearInterval(timer)
    setRemainingMinute(InitialMinutes)
    setRemainingSecond(Initialseconds)
  }, [props.isIdle])

  useEffect(() => {
    let sec = remainingSecond
    let min = remainingMinute
    if (props.visible) {
      const timer = setInterval(() => {
        sec = sec - 1 >= 0 ? sec - 1 : 59
        min = sec !== 59 ? min : min - 1
        if (!sec && !min) {
          timer && clearInterval(timer)
          props.onAccept()
          dispatch(logout())
        } else if (tabVisible) {
          setRemainingMinute(min)
          setRemainingSecond(sec)
        }
      }, 1000)
      setTimer(timer)
    } else {
      timer && clearInterval(timer)
    }
  }, [props.visible])

  return (
    <GenericModal
      {...props}
      title={languages.inactivity}
      acceptText={languages.stayLogged}
    >
      <div>{`${remainingMinute} m ${remainingSecond} ...`}</div>
    </GenericModal>
  )
}


const RightsCheckboxes = (props: {
  onCancel?: () => void
  onAccept: (value: string) => void
  relative?: boolean
}) => {
  const { languages } = useLanguage()
  const [right, setRight] = useState<string>()
  const MEDICAL_RIGHTS = [
    {
      content: (
        <span className="center">
          <b>{languages.c2s}</b>
          <br />
          (CMU)
        </span>
      ),
      value: "C2S",
    },
    {
      content: (
        <span className="center">
          <b>{languages.ame}</b>
          <br />
          (AME)
        </span>
      ),
      value: "AME",
    },
    {
      content: (
        <span className="center">
          <b>{languages.ald}</b>
          <br />
          (ALD)
        </span>
      ),
      value: "ALD",
    },
  ]

  return (
    <>
      <Buttons style={{ paddingTop: "0" }}>
        {MEDICAL_RIGHTS.map((mRight, key) => (
          <FatButton
            key={key}
            clicked={mRight.value === right}
            onClick={() => setRight(mRight.value)}
          >
            {mRight.content}
          </FatButton>
        ))}
      </Buttons>
      <Buttons style={{ paddingTop: "100px" }}>
        <Button wide="long" type="primary" onClick={props.onCancel}>
          {languages.cancel}
        </Button>
        <Button
          disabled={!right}
          wide="long"
          onClick={() => right && props.onAccept(right)}
        >
          {languages.next}
        </Button>
      </Buttons>
    </>
  )
}

export const RightsModal: React.FC<ModalProps & { relative?: boolean }> = (
  props
): JSX.Element => {
  const { languages } = useLanguage()
  const [step, setStep] = useState<"check" | "attest">("check")
  const rightsSteps = [
    {
      step: "check",
      title: languages.indicateYourRights,
    },
    {
      step: "attest",
      title: languages.fraudIsPunished,
      contentText: languages.ameModalContent,
      acceptText: languages.ameModalAccept,
      cancelText: languages.ameModalCancel,
    },
  ]
  const rightsStepsForRelative = [
    {
      step: "check",
      title: languages.indicateRelativeRights,
    },
    {
      step: "attest",
      title: languages.fraudIsPunished,
      contentText: languages.ameModalContent,
      acceptText: languages.ameModalAccept,
      cancelText: languages.ameModalCancelRelative,
    },
  ]

  const content = props.relative
    ? (rightsStepsForRelative.find((s) => step === s.step) as any)
    : (rightsSteps.find((s) => step === s.step) as any)

  return (
    <GenericModal
      {...omit(props, "relative")}
      title={content.title}
      contentText={content.contentText}
      acceptText={content.acceptText}
      cancelText={content.cancelText}
      acceptButtonProps={{ wide: "default", type: "default", danger: false }}
      cancelButtonProps={{ wide: "default", type: "primary", danger: false }}
      noFooter={step === "check"}
      onCancel={() => {
        setStep("check")
        props.onCancel && props.onCancel()
      }}
    >
      {step === "check" ? (
        <RightsCheckboxes
          onCancel={props.onCancel}
          onAccept={() => setStep("attest")}
          relative={props.relative}
        />
      ) : undefined}
    </GenericModal>
  )
}

export const UploadModal: React.FC<ModalProps> = (props): JSX.Element => {
  const { languages } = useLanguage()
  const { onAccept, onCancel, title } = props

  const TitleBlock = (
    <div className={styles.title__block}>
      <div className={styles.title__icon}>
        <img src={cdn("images/warning_picto.svg")} alt="warning image" />
      </div>
      <h1 className={styles.title__text}>{title? title : languages.UploadRecommendedText}</h1>
    </div>
  )

  return (
    <GenericModal
      {...props}
      bodyStyle={{ padding: 0 }}
      title={TitleBlock}
      acceptText={languages.UDModalTransmit}
      cancelText={languages.UDModalIgnore}
      acceptButtonProps={{ wide: "long", type: "primary", danger: false }}
      cancelButtonProps={{ wide: "long", danger: true }}
      onCancel={onCancel}
      onAccept={onAccept}
    />
  )
}
