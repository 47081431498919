import { omit } from "lodash"
import config from "react-global-configuration"
import { all, call, put, select, takeLatest } from "redux-saga/effects"

import clientActions from "client/services/actions"
import { getNirReaderResponse } from "client/services/selector"
import { insertRelativesRequest } from "containers/InsertRelative/services/actions"
import profileActions from "containers/Profile/services/actions"
import signupActions from "containers/Signup/services/actions"
import { request } from "lib/request"
import { Customer, Relative } from "types/entity"
import { ResponseVerifyPhone } from "types/payload"
import { Action, GFlow, GWatcher, Message } from "types/redux"
import { Patient } from "types/sesam"
import { NirReaderResponse } from "types/store"

import actions from "./actions"
import {
  CANCEL_SIGNUP_REQUEST,
  SEND_AGAIN_REQUEST,
  SET_PHONE_CODE_REQUEST,
} from "./constants"
import { languages } from "locales/languages"

/*
 *  API REQUESTS
 */
async function verifyPhoneApi(payload: {
  code: string
}): Promise<ResponseVerifyPhone> {
  return await request(config.get("patient.verify"), {
    method: "POST",
    payload,
  })
}
async function cancelApi(): Promise<ResponseVerifyPhone> {
  return await request(config.get("patient.cancelSignup"), {
    method: "POST",
    payload: {},
  })
}
async function sendAgainApi(): Promise<ResponseVerifyPhone> {
  return await request(config.get("patient.verifySendAgain"), {
    method: "POST",
    payload: {},
  })
}
/*
 * FLOWS
 */
function* sendAgainFlow() {
  try {
    yield call(sendAgainApi)
    yield put(actions.sendAgainSuccess(languages.verifyCallSendSuccess))
  } catch (e) {
    console.error(e, {
      route: config.get("patient.verifySendAgain")
    })
    yield put(actions.sendAgainError(languages.cannotSendTheSms))
  }
}
function* cancelFlow() {
  try {
    yield call(cancelApi)
    yield put(signupActions.reset())
  } catch (e) {
    console.error(e, {
      route: config.get("patient.cancelSignup")
    })
    yield put(actions.cancelError(languages.cannotCancel))
  }
}

function* verifyPhoneFlow({
  payload,
}: Action<{ code: string }>): GFlow<Action<Message>> {
  try {
    const response: ResponseVerifyPhone = yield call(verifyPhoneApi, payload)
    if (response && response.customer) {
      // Get NirReader patients
      const nirReaderResponse: NirReaderResponse | undefined = yield select(
        getNirReaderResponse
      )
      const { patient, relatives } = nirReaderResponse ?? {
        patient: null,
        relatives: null,
      }
      // Add Nir after a success signup + verified user
      const nir = patient?.nir
      if (nir) yield put(profileActions.changePatientRequest({ nir, signup_by_carte_vitale:true }))
      // Add Relatives in Nir if they exists
      if (relatives) {
        const relativesFormat: Relative[] = relatives.map(
          (r: Partial<Patient>) => {
            return {...omit(r, ["amc", "cmu", "amo"]), signup_by_carte_vitale:true } as unknown as Relative
          }
        )
        yield put(insertRelativesRequest(relativesFormat))
      }
      yield all([
        put(clientActions.setCustomer(response.customer)),
        put(actions.success(languages.SignupSuccess)),
      ])
    } else {
      yield put(
        actions.error(languages.RefraichToUpdateInfo)
      )
    }
  } catch (error) {
    console.error(error, {
      route: config.get("patient.verify")
    })
    yield put(actions.error((error as string) || languages.WrongCode))
  }
}

function* signupWatcher(): GWatcher {
  yield takeLatest(SET_PHONE_CODE_REQUEST, verifyPhoneFlow)
  yield takeLatest(SEND_AGAIN_REQUEST, sendAgainFlow)
  yield takeLatest(CANCEL_SIGNUP_REQUEST, cancelFlow)
}

export default signupWatcher
