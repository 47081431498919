import { Checkbox } from "antd"
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import { useState } from "react"
import { connect } from "react-redux"

import { Button } from "components/Button"
import { Buttons } from "components/Card/Card"
import { GenericModal } from "components/Modal/Modal"
import PreviousButton from "components/Previous/PreviousButton"
import { PATH } from "containers/Survey/models"
import surveyActions from "containers/Survey/services/actions"
import { Reply } from "containers/Survey/types"
import { Dispatcher } from "types/redux"
import { DoctorsStore, SurveyStore } from "types/store"

import styles from "./Consent.module.scss"
import { useLanguage } from "locales"

const Consent = (props) => {
  const { languages } = useLanguage()
  const [consentToDataUse, setConsentToDataUse] = useState(true)
  const [showPrivacy, setShowPrivacy] = useState(false)
  const surveyPushResponse = () => {
    props.unset()
    props.setStepReplies({
      replies: [
        {
          title: "share_personal_data",
          value: consentToDataUse,
        },
      ],
      id: PATH.consent,
    })
    props.onAccept()
  }
  const handleChange = (event: CheckboxChangeEvent) => {
    setConsentToDataUse(event.target.checked)
  }

  const previous = () => {
    props.onCancel()
  }

  return (
    <GenericModal
      visible={props.visible}
      footer={
        showPrivacy ? null : (
          <Buttons style={{ marginTop: 0 }} direction="vertical">
            <Button onClick={surveyPushResponse} type="primary">
              {languages.continue}
            </Button>
          </Buttons>
        )
      }
      title={languages.datas_and_consent_title}
    >
      {showPrivacy ? (
        <div className={styles.CGUContent}>
          <PreviousButton onClick={() => setShowPrivacy(false)} />
          <iframe title="privacy" src="https://info.medadom.com/politique-de-confidentialite" style={{width: '-webkit-fill-available', height: '-webkit-fill-available'}} />
        </div>
      ) : (
        <div>
          <PreviousButton onClick={previous} />
          <ul className={`font-size-large ${styles.Content}`}>
            <li>
              <b>{languages.datas_and_consent_point_1}</b>
            </li>
            <li>
              <b>{languages.datas_and_consent_point_2}</b>
              <div className={styles.Checkboxes}>
                <div className={styles.Checkbox}>
                  <Checkbox
                    id="consent-data"
                    checked={true}
                    disabled={true}
                    type="checkbox"
                  />
                  <label htmlFor="consent-data">
                    {languages.datas_and_consent_point_2_checkbox_1}
                  </label>
                </div>
                <div className={styles.Checkbox}>
                  <Checkbox
                    onChange={handleChange}
                    id=""
                    type="checkbox"
                    checked={consentToDataUse}
                  />
                  <label htmlFor="">
                    {languages.datas_and_consent_point_2_checkbox_2}
                  </label>
                </div>
              </div>
            </li>
            <li>
              <b>{languages.datas_and_consent_point_3}</b>
              <a
                onClick={() => {
                  setShowPrivacy(true)
                }}
              >
                {languages.datas_and_content_point_3_ref}
              </a>
            </li>
          </ul>
        </div>
      )}
    </GenericModal>
  )
}

const p = (dispatch: Dispatcher): Partial<SurveyStore> => {
  return {
    previous: () => dispatch(surveyActions.previous()),
    next: () => dispatch(surveyActions.next()),
    setStepReplies: (payload) =>
      dispatch(surveyActions.setStepReplies(payload)),
    unset: (unReplies?: Reply[]) =>
      dispatch(
        surveyActions.unsetStepReplies({ replies: unReplies, id: PATH.consent })
      ),
  } as Partial<DoctorsStore>
}

const s = ({ survey }: { survey: SurveyStore }) => {
  return {}
}

export default connect(s, p)(Consent)
