export const HISTORY_ERROR = "HISTORY_ERROR"
export const HISTORY_SUCCESS = "HISTORY_SUCCESS"
export const HISTORY_REQUEST = "HISTORY_REQUEST"
export const RESET_PROFILE_STORE = "RESET_PROFILE_STORE"
export const [
  CHANGE_PATIENT_REQUEST,
  CHANGE_PATIENT_SUCCESS,
  CHANGE_PATIENT_ERROR,
  CHANGE_RELATIVE_REQUEST,
  CHANGE_RELATIVE_SUCCESS,
  CHANGE_RELATIVE_ERROR,
  UNSUBSCRIBE_NEWSLETTER,
] = [
  "CHANGE_PATIENT_REQUEST",
  "CHANGE_PATIENT_SUCCESS",
  "CHANGE_PATIENT_ERROR",
  "CHANGE_RELATIVE_REQUEST",
  "CHANGE_RELATIVE_SUCCESS",
  "CHANGE_RELATIVE_ERROR",
  "UNSUBSCRIBE_NEWSLETTER",
]
