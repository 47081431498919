import { cdn } from "core/cdn"
import { languages } from "locales/languages"

import { ACTION,Reply, Step, ReplyAction} from "../Survey/types"
import { MOBILE_IMPORT, SMS_LINK, WEBCAM, WEB_IMPORT } from "./services/constants"

export const ITEMS_PER_CAROUSEL_PAGE = 6
export const SECOND_NEXT_ANSWER = 0.2
export const PATH_DEEP = ":"

export enum PATH {
   webOrMobile = "webOrMobile",
   SmsOrBorn = "SmsOrBorn",
   Qrcode = "Qrcode", 
   GetReady = "GetReady", 
   IamReady = "IamReady", 
   LetsGo = "LetsGo", 
   Preview = "Preview", 
   AddAnotherPage = "AddAnotherPage", 
   ChooseType = "ChooseType", 
   DocName = "DocName", 
   UpLoading = "UpLoading", 
   Success= "Success"
}

export enum DOCTYPE {
  Crm = "Compte-rendu médical", 
  Ord = "Ordonnance", 
  MT = "Médicaments / traitement", 
  PA = "Pièce administrative", 
  PC = "Photo corporelle (gorge, peau , oeil, ...)", 
  Autre = "Autre", 
  Certificat = "Certificat", 
  AB = "Analyses biologiques", 
  Cma = "Courrier médical d'adressage", 
  Radio = "Radio / imagerie", 
}

export const doc_types : ReplyAction[] = [
  {title: DOCTYPE.Crm,
  value: "report",
  imgUrl: cdn("images/pico_compte-rendu.svg")}, 

  {title: DOCTYPE.Ord,
  value: "prescription",
  imgUrl: cdn("images/pico_ordonnance.svg")}, 

  {title: DOCTYPE.MT,
  value: "treatment",
  imgUrl: cdn("images/pico_medicament.svg")}, 

  {title: DOCTYPE.PA,
  value: "administrative",
  imgUrl: cdn("images/pico_piece_administrative.svg")}, 

  {title: DOCTYPE.PC,
  value: "picture",
  imgUrl: cdn("images/pico_photo_corporelle.svg")} , 
  {title: DOCTYPE.Certificat,
  value: "certificate",
  imgUrl: cdn("images/pico_certificat.svg")} , 

  {title: DOCTYPE.AB,
  value: "analyses",
  imgUrl: cdn("images/pico_analyse_bio.svg")}, 

  {title: DOCTYPE.Cma,
  value: "letter",
  imgUrl: cdn("images/pico_courrier_adressage.svg")}, 

  {title: DOCTYPE.Radio,
    value: "imaging",
    imgUrl: cdn("images/picto_radiologie.svg")}, 

  {title: DOCTYPE.Autre,
    value: "other",
    imgUrl: cdn("images/pico_autre.svg")}, 
]


const keppGoing: Reply[] = 
[{
    title: languages.uploadDocumentGetReadyButton,
    action: ACTION.redirect,
    value: true,
    ui: {
      view: "button",
      type: "primary"
    },
  }]

const Ready: Reply[] = 
[{
    title: languages.uploadDocumentIamReadyButton,
    action: ACTION.redirect,
    value: true,
    ui: {
      view: "button",
      type : "primary"
    },
  }]

const cancel: Reply[] = 
[  {
    title: "Annuler",
    value: true,
    ui: {
      view: "button",
      style: "primary"
    },
  }]

const validate: Reply[] = 
[  {
    title: languages.uploadDocumentChooseTypeButton,
    action: ACTION.redirect,
    value: true,
    ui: {
      view: "button",
    },
  }]

const sendMeSmsLink : Reply = 
    {
      title: languages.UDSendMeSMS,
      value: SMS_LINK,
      ui: {
        view: "button",
      },
    }

const cannotScanQrCode : Reply = 
{
      title: languages.UDCannotScan,
      value: SMS_LINK,
      ui: {
        view: "button",
      },
}

const booleanReplies: Reply[] = [
  {
    title: languages.yes,
    value: true,
    ui: {
      view: "button",
    },
  },
  {
    title: languages.no,
    value: false,
    action: ACTION.redirect,
    ui: {
      view: "button",
    },
  },
]

const webormobile: Reply[] = [
  {
    title: languages.uDImportFromKiosk,
    value: WEB_IMPORT,
    ui: {
      view: "button",
    },
  },
  {
    title: languages.uDImportFromMobile,
    value: MOBILE_IMPORT,
    action: ACTION.redirect,
    ui: {
      view: "button",
    },
  },
]

const smsOrBornReplies: Reply[] = [
  {
    title: languages.UDGetLinkBySms,
    value: SMS_LINK,
    ui: {
      view: "button",
    },
  },
  {
    title: languages.UDUseBorn,
    value: WEBCAM,
    // action: ACTION.redirect,
    ui: {
      view: "button",
    },
  },
]

const previewReplies: Reply[] = [
  {
    title: languages.uploadDocumentPreviewTakeAgain,
    value: false,
    ui: {
      view: "button",
    },
  },
  {
    title: languages.uploadDocumentPreviewValidate,
    value: true,
    action: ACTION.redirect,
    ui: {
      view: "button",
      type: "primary",
    },
  }
]

const webOrMobile : Step =  
  {
  title: languages.uDImportFRom,
  imgUrl: "",
  id: PATH.webOrMobile,
  replies: webormobile,
  }

const SmsOrBorn : Step =  
  {
  title: languages.UDAskSms,
  imgUrl: "",
  id: PATH.SmsOrBorn,
  replies: smsOrBornReplies,
}

export const QrcodeStep : Step =  
  {
  title: languages.uDocScanQrCode,
  imgUrl: "",
  id: PATH.Qrcode,
  replies: [],
  defaultReply: cannotScanQrCode,
  }

export const oldQrcodeStep : Step =  
  {
  title: languages.uDocScanQrCode,
  imgUrl: "",
  id: PATH.Qrcode,
  replies: [],
  defaultReply: sendMeSmsLink,
  }

const GetReady : Step =  
  {
  title: languages.uploadDocumentGetReady,
  imgUrl: cdn("images/envoi_document_illustration.svg"),
  id: PATH.GetReady,
  replies: keppGoing,
  }

const IamReady : Step =  
  {
  title: "",
  imgUrl: cdn("images/illustration_base.svg"),
  id: PATH.IamReady,
  replies: Ready,
  }

const LetsGo : Step =  
  {
  title: "",
  id: PATH.LetsGo,
  servicesToDisplay : "shooter",
  replies: cancel
  }

const Preview : Step =  
  {
  title: languages.uploadDocumentPreview,
  imgUrl: "",
  id: PATH.Preview,
  replies: previewReplies,
  }

const AddAnotherPage : Step =  
  {
  title: languages.uploadDocumentAddAnotherPage,
  imgUrl: cdn("images/document_send_illustration.svg"),
  id: PATH.AddAnotherPage,
  replies: booleanReplies,
  }

const ChooseType : Step = 
  {
  title: languages.uploadDocumentChooseType,
  imgUrl: "",
  id: PATH.ChooseType,
  servicesToDisplay: "docTypes",
  replies: doc_types,
  }

const DocName : Step =
  {
  title: languages.uploadDocumentDocName,
  imgUrl: "",
  id: PATH.DocName,
  replies: validate,
  servicesToDisplay: "Form",
  }

  const UpLoading : Step =
  {
    title: languages.uploadDocumentloading,
    imgUrl: cdn("images/envoi_doc_med_illustration.svg"),
    subtitle: languages.uploadDocumentWait,
  id: PATH.UpLoading,
  replies: [],
  }

const Success : Step = 
  {
  title: languages.uploadDocumentSuccess,
  imgUrl: cdn("images/okay_illustration.svg"),
  id: PATH.Success,
  replies: keppGoing,
  }

export const UPLOAD_STEPS = [
  QrcodeStep,
  webOrMobile,
  GetReady, 
  IamReady, 
  LetsGo, 
  Preview, 
  AddAnotherPage, 
  ChooseType, 
  DocName,
  UpLoading, 
  Success,
  SmsOrBorn
]