import { useEffect, useMemo, useState } from "react"
import { connect, useSelector } from "react-redux"

import { Button } from "components/Button"
import FatButton from "components/Button/FatButton/FatButton"
import { Buttons, Card } from "components/Card/Card"
import { Carousel } from "components/Carousel/Index"
import Loader from "components/Loaders/Loader/Loader"
import { GenericModal } from "components/Modal/Modal"
import PreviousButton from "components/Previous/PreviousButton"
import { PATH } from "containers/Survey/models"
import surveyActions from "containers/Survey/services/actions"
import { RESET_SURVEY_REPLY } from "containers/Survey/services/constants"
import {
  getCurrentPatient,
  getCurrentSelectedPatientId,
} from "containers/Survey/services/selector"
import { Value } from "containers/Survey/types"
import { cdn } from "core/cdn"
import {
  Carer,
  CARER,
  RELATIONSHIP,
  RELATIONSHIP_LABEL,
} from "types/entity"
import { Dispatcher } from "types/redux"
import { ClientStore, InsertCarerStore, NirReaderResponse } from "types/store"

import styles from "./insertCarer.module.scss"
import { SelectProfessionalCarer } from "./InsertProfessionalCarer"
import { InsertRelativeCarer, SelectRelativeCarer } from "./insertRelativeCarer"
import {
  ADD_PATIENT_CARERS_REQUEST,
  ADD_RELATIONSHIP_PATIENT_CARERS_REQUEST,
  CARERS_RESET_STORE,
  DELETE_PATIENT_CARER_REQUEST,
  DELETE_PHARMACY_CARER_REQUEST,
  GET_PATIENT_CARERS_REQUEST,
  GET_PHARMACY_CARERS_REQUEST,
  GET_RELATIONSHIP_PATIENT_CARERS_REQUEST,
} from "./services/constants"
import { useLanguage } from "locales"
import { languages } from "locales/languages"

export interface InsertCarerProps {
  visible?: boolean
  onChosenCarer?: (carer: Carer) => void
  onCancel?: () => void
  onFinish?: (carer: Carer) => void
  getCarerRequest?: (forRelativeId?: Value) => void
}
const SelectCarerType = (props) => {
  const { languages } = useLanguage()
  const currentPatientId = useSelector(getCurrentSelectedPatientId)

  return (
    <Card
      title={
        currentPatientId
          ? languages.whoIsCaringYouForTheTLC_relative
          : languages.whoIsCaringYouForTheTLC
      }
      style={{ height: "70vh", justifyContent: "center" }}
    >
      <Buttons>
        <div style={{ transform: "scale(1.2)", display: "flex", gap: "50px" }}>
          {currentPatientId ? (
            <FatButtonMainPatient
              onClick={props.onFinish}
            ></FatButtonMainPatient>
          ) : null}
          <FatButton onClick={() => props.onClick(CARER.RELATIVE)}>
            <div className={styles.PatientInformation}>
              <img src={cdn("/images/proche_picto.svg")} alt="" />
              <b className={styles.ButtonText}>{languages.aRelative}</b>
              <i></i>
            </div>
          </FatButton>
          <FatButton onClick={() => props.onClick(CARER.PROFESSIONAL)}>
            <div className={styles.PatientInformation}>
              <img src={cdn("/images/ps_picto.svg")} alt="" />
              <b className={styles.ButtonText}>{languages.proOrReferent}</b>
              <i></i>
            </div>
          </FatButton>
        </div>
      </Buttons>
    </Card>
  )
}

const getMainPatientOrMainCarer = ({ patient, carers }) => {
  const getParsedName = (patient) =>
    `${patient.firstname} ${patient.lastname}`.toLowerCase()

  const mainPatientAsCarer = carers.find(
    (carer) => getParsedName(carer) === getParsedName(patient)
  )
  return mainPatientAsCarer ?? patient
}

export const FatButtonMainPatient = connect(
  ({ carer }) => carer,
  (dispatch: Dispatcher): Partial<InsertCarerStore> => ({
    getPatientCarers: () => dispatch({ type: GET_PATIENT_CARERS_REQUEST }),
    getRelationshipPatientCarer: (relativeId?: string) =>
      dispatch({
        type: GET_RELATIONSHIP_PATIENT_CARERS_REQUEST,
        payload: { relativeId },
      }),
    patient_insert: (carer: Carer) =>
      dispatch({ type: ADD_PATIENT_CARERS_REQUEST, payload: carer }),
  })
)(
  (
    props: Partial<InsertCarerStore & InsertCarerProps> & {
      onClick: (c: Carer) => void
    }
  ) => {
    const currentPatientId = useSelector(getCurrentSelectedPatientId)
    const [selectedCarer, setSelectedCarer] = useState(null)
    const [insertMainPatient, setInsertMainPatient] = useState(false)
    const patients: NirReaderResponse = useSelector(
      ({ client }: { client: ClientStore }) => ({
        patient: client.customer,
        relatives: client.relatives,
      })
    )

    const mainPatientOrMainCarer: Carer = useMemo(
      () =>
        getMainPatientOrMainCarer({
          patient: patients.patient,
          carers: props.patient_carers || [],
        }),
      [props.patient_carers]
    )

    useEffect(() => {
      props.getPatientCarers()
      props.getRelationshipPatientCarer(currentPatientId as string)
    }, [])

    useEffect(() => {
      if (selectedCarer) {
        if (selectedCarer.is_carer) {
          props.onClick(selectedCarer)
          setSelectedCarer(null)
        } else {
          setInsertMainPatient(true)
        }
      }
    }, [selectedCarer])
    if (insertMainPatient) {
      return (
        <InsertRelativeCarer
          onFinish={(c) => {
            props.onClick(c)
            setSelectedCarer(null)
          }}
          partialCarer={selectedCarer}
          onCancel={() => setSelectedCarer(null)}
        />
      )
    }
    return (
      <FatButton
        type="primary"
        disabled={props.loading}
        onClick={() => {
          setSelectedCarer(mainPatientOrMainCarer)
        }}
      >
        {props.loading ? (
          <Loader />
        ) : (
          <div className={styles.PatientInformation}>
            <img src={cdn("/images/people_picto.svg")} alt="" />
            <b className={styles.ButtonText}>
              {mainPatientOrMainCarer.firstname}{" "}
              {mainPatientOrMainCarer.lastname}
            </b>

            <i>
              {
                props.relationships_current_patient?.find(
                  (relationshipCarer) =>
                    relationshipCarer.carer.guid === mainPatientOrMainCarer.guid
                )?.label
              }
            </i>
          </div>
        )}
      </FatButton>
    )
  }
)

export const SelectCarer = connect(
  ({ carer }) => carer,
  (dispatch: Dispatcher): Partial<InsertCarerStore> => ({
    getPharmacyCarers: () => dispatch({ type: GET_PHARMACY_CARERS_REQUEST }),
    getPatientCarers: () => dispatch({ type: GET_PATIENT_CARERS_REQUEST }),
    getRelationshipPatientCarer: (relativeId?: string) =>
      dispatch({
        type: GET_RELATIONSHIP_PATIENT_CARERS_REQUEST,
        payload: { relativeId },
      }),
    patient_insert: (carer: Carer) =>
      dispatch({ type: ADD_PATIENT_CARERS_REQUEST, payload: carer }),
  })
)(
  (
    props: Partial<InsertCarerProps & InsertCarerStore> & {
      type: CARER
    }
  ): JSX.Element => {
    const currentPatientId = useSelector(getCurrentSelectedPatientId)
    useEffect(() => {
      props.getRelationshipPatientCarer(currentPatientId as string)
      if (props.type === CARER.PROFESSIONAL) props.getPharmacyCarers()
      else if (props.type === CARER.RELATIVE) props.getPatientCarers()
    }, [])

    if (props.type === CARER.PROFESSIONAL)
      return (
        <SelectProfessionalCarer
          onFinish={(carer) => {
            props.onFinish(carer)
          }}
          onCancel={props.onCancel}
        />
      )
    else
      return (
        <SelectRelativeCarer
          onFinish={props.onFinish}
          onCancel={props.onCancel}
        />
      )
  }
)

// Porte d'entrée de la séléction d'accompagnant
export const InsertCarerModal = connect(
  ({ carer }) => carer,
  (dispatch: Dispatcher) => ({
    setSurveyStepReplies: (payload) =>
      dispatch(surveyActions.setStepReplies(payload)),
    resetSurveyReply: (payload) =>
      dispatch({ type: RESET_SURVEY_REPLY, payload }),
    resetStore: () => dispatch({ type: CARERS_RESET_STORE }),
  })
)((props: Partial<InsertCarerProps & InsertCarerStore>) => {
  // dans ce composant, 4 étapes :
  // - on choisi le type (proche ou professionnel) <CarerTypeChoice>
  // - on choisi l'accompagnant <SelectCarer> (on peut le créer dedans)
  // - on choisi la relation avec le patient <SelectRelationShip>
  // - L'accompagnant consent à garder privée la TLC <ConsentCerer>
  const { languages } = useLanguage()
  const [carerType, setCarerType] = useState<CARER>(null)
  const currentPatientId = useSelector(getCurrentSelectedPatientId)
  const [chosenCarer, setChosenCarer] = useState(null)
  const [shouldSelectRelationship, setShouldSelectRelationship] =
    useState(false)
  const [consentCarer, setConsentCarer] = useState(false)

  useEffect(() => {
    // Pour éviter tout problème de conflit dans l'app,
    // on réinitialise la question carer du survey
    // au montage du composant
    props.resetStore()
    props.resetSurveyReply(PATH.selected_carer)
  }, [])

  const selectCarer = (carer: Carer, type?: CARER) => {
    // L'accompagnant est séléctionné
    setChosenCarer(carer)
    const hasRelationshipWithCurrentPatient =
      props.relationships_current_patient?.find(
        (relationshipCarer) => relationshipCarer.carer.guid === carer.guid
      )

    // Si carerType === CARER.PROFESSIONAL
    // Pas besoin de choisir la relation avec le proche (c'est AUTRE)
    if (!hasRelationshipWithCurrentPatient && carerType === CARER.RELATIVE)
      setShouldSelectRelationship(true)
    else if (!hasRelationshipWithCurrentPatient && type === CARER.RELATIVE)
      setShouldSelectRelationship(true)
    else setConsentCarer(true)
  }

  const finish = (carer: Carer) => {
    // On défini la question Accompagnant du survey avec celui choisi
    props.setSurveyStepReplies({
      replies: [
        {
          title: PATH.selected_carer,
          value: carer.guid,
        },
      ],
      id: PATH.selected_carer,
    })
    props.onFinish(carer)
  }

  const cancel = () => {
    props.resetSurveyReply(PATH.selected_carer)
    props.onCancel()
  }

  return (
    <GenericModal visible={props.visible} noFooter={true}>
      <div
        style={{
          minHeight: "85vh",
          margin: "auto",
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
        }}
      >
        {consentCarer ? (
          <ConsentCarer
            onCancel={() => {
              setConsentCarer(false)
              setChosenCarer(null)
            }}
            onFinish={() => finish(chosenCarer)}
          />
        ) : (
          <div>
            <PreviousButton
              onClick={() => {
                if (chosenCarer) setChosenCarer(null)
                else if (carerType) setCarerType(null)
                else cancel()
              }}
            />
            {chosenCarer && shouldSelectRelationship ? (
              <SelectRelationshipCarer
                chosenCarer={chosenCarer}
                currentPatientId={currentPatientId as string}
                onFinish={(carer) => selectCarer(carer)}
              />
            ) : carerType ? (
              <SelectCarer
                type={carerType}
                onFinish={selectCarer}
                onCancel={() => {
                  setCarerType(null)
                }}
              />
            ) : (
              <SelectCarerType
                onClick={(choice) => setCarerType(choice)}
                onFinish={(mainCarer) => {
                  setCarerType(CARER.RELATIVE)
                  // Dans SelectCarer, carerType ne sera pas encore défini.
                  // on doit donc rajouter cette variable à la fonction
                  selectCarer(mainCarer, CARER.RELATIVE)
                }}
              />
            )}
          </div>
        )}
      </div>
    </GenericModal>
  )
})

export const ConsentCarer = (props) => {
  const { languages } = useLanguage()
  return (
    <>
      <Card title={languages.consentOfAssistance}>
        <PreviousButton onClick={props.onCancel} />
        <div
          className="flex-row flex-center"
          style={{ gap: "30px", height: "30vh" }}
        >
          <img
            src={cdn("/icons/picto_secret.svg")}
            alt="Image de confidentialité"
          />
          <div className={styles.InformConfidential}>
            {languages.asCarerIAccept}
            <b>{languages.toKeepConfidential}</b> {languages.duringTlc}
          </div>
        </div>
        <Buttons>
          <Button type="primary" onClick={props.onFinish}>
            {languages.validateAndContinue}
          </Button>
        </Buttons>
      </Card>
    </>
  )
}
export const CarerSelector = connect(
  ({ carer }) => carer,
  (dispatch: Dispatcher): Partial<InsertCarerStore> => ({
    deletePatientCarer: (payload: Partial<Carer>) =>
      dispatch({
        type: DELETE_PATIENT_CARER_REQUEST,
        payload,
      }),
    deletePharmaCarer: (payload: Partial<Carer>) =>
      dispatch({ type: DELETE_PHARMACY_CARER_REQUEST, payload }),
  })
)(
  (
    props: {
      title?: string
      carers: Carer[]
      canDelete?: boolean
      isAdminList?: boolean
      onCancel: () => void
      onSelect: (p: Partial<Carer>) => void
      notInTheList: () => void
      notInTheListText?: string
    } & Partial<InsertCarerStore>
  ): JSX.Element => {
    const { languages } = useLanguage()
    const [selectedCarer, setSelectedCarer] = useState<Partial<Carer>>()

    if (props.loading) return <Loader />
    return (
      <Card title={props.title} style={{ width: "80vw" }}>
        <PreviousButton onClick={props.onCancel} />
        {props.carers?.length ? (
          <Carousel
            itemsPerPage={6}
            items={[
              ...props.carers.map((carer: Partial<Carer>, key: number) => (
                <div style={{ position: "relative" }} key={key}>
                  {props.canDelete && (carer.is_carer || props.isAdminList) ? (
                    <Trash
                      carer={carer}
                      confirm={true}
                      onClick={() => {
                        props.isAdminList
                          ? props.deletePharmaCarer(carer)
                          : props.deletePatientCarer(carer)
                      }}
                    />
                  ) : null}
                  <FatButton
                    clicked={selectedCarer === carer}
                    onClick={() => {
                      setSelectedCarer(carer)
                      props.onSelect(carer)
                    }}
                  >
                    <div className={styles.PatientInformation}>
                      {props.isAdminList ? (
                        carer.rpps ? (
                          <img src={cdn("/images/ps_picto.svg")} alt="" />
                        ) : (
                          <img src={cdn("/images/people_picto.svg")} alt="" />
                        )
                      ) : (
                        <img src={cdn("/images/proche_picto.svg")} alt="" />
                      )}
                      <b
                        className={styles.ButtonText}
                      >{`${carer?.firstname} ${carer?.lastname}`}</b>
                      {props.isAdminList ? (
                        <i>
                          {carer.rpps ? languages.HealthProfessional : languages.Referer}
                        </i>
                      ) : (
                        <i>
                          {
                            props.relationships_current_patient?.find(
                              (relationshipCarer) =>
                                relationshipCarer.carer.guid === carer.guid
                            )?.label
                          }
                        </i>
                      )}
                    </div>
                  </FatButton>
                </div>
              )),
              <FatButton
                key={props.carers.length + 1}
                type="ghost"
                onClick={() => props.notInTheList()}
              >
                <div className={styles.Other}>
                  <img src={cdn("/images/plus_picto.svg")} alt="" />
                  <b> {languages.otherCarer} </b>
                </div>
              </FatButton>,
            ]}
          />
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <FatButton type="ghost" disabled>
              <div className={styles.Other}>
                <img src={cdn("/images/unknown_people.svg")} alt="" />
                <b> {languages.noCarer} </b>
              </div>
            </FatButton>
            <FatButton type="ghost" onClick={() => props.notInTheList()}>
              <div className={styles.Other}>
                <img src={cdn("/images/plus_picto.svg")} alt="" />
                <b> {languages.otherCarer} </b>
              </div>
            </FatButton>
          </div>
        )}
      </Card>
    )
  }
)

export const Trash = (props: {
  title?: string
  carer: Partial<Carer>
  confirm?: boolean
  onClick: () => void
}) => {
  const { languages } = useLanguage()
  const [modalVisible, setModalVisible] = useState(false)

  return (
    <>
      <div
        className={styles.TrashAction}
        onClick={() =>
          props.confirm ? setModalVisible(true) : props.onClick()
        }
      >
        <img
          src={cdn("/icons/picto_trash.svg")}
          alt="Image supprimer accompagnant"
        />
      </div>
      <GenericModal
        visible={modalVisible}
        title={props.title || languages.confirmRemoveCarer}
        footer={
          <Buttons>
            <Button wide="long" onClick={() => setModalVisible(false)}>
              {languages.cancel}
            </Button>
            <Button wide="long" danger type="primary" onClick={props.onClick}>
              {languages.delete}
            </Button>
          </Buttons>
        }
      >
        <div className="flex-column flex-center">
          <FatButton disabled>
            <div className={styles.PatientInformation}>
              <img src={cdn("/images/proche_picto.svg")} alt="" />
              <b>
                {props.carer.firstname} {props.carer.lastname}
              </b>
              <i></i>
            </div>
          </FatButton>
        </div>
      </GenericModal>
    </>
  )
}

export const SelectRelationshipCarer = connect(
  ({ carer }) => carer,
  (dispatch: Dispatcher): Partial<InsertCarerStore> => ({
    setRelationship: (payload: { carer; relationship; relativeId }) =>
      dispatch({ type: ADD_RELATIONSHIP_PATIENT_CARERS_REQUEST, payload }),
  })
)(
  (
    props: Partial<InsertCarerProps & InsertCarerStore> & {
      chosenCarer: Carer
      currentPatientId: string
    }
  ) => {
    const currentPatient = useSelector(getCurrentPatient)
    const currentPatientId = useSelector(getCurrentSelectedPatientId)
    const relationships: RELATIONSHIP[] = Object.values(RELATIONSHIP)
    const [chosenRelationship, setChosenRelationship] =
      useState<RELATIONSHIP>(null)
    const setRelationship = (code: RELATIONSHIP) => {
      setChosenRelationship(code)
      props.setRelationship({
        carer: props.chosenCarer,
        relativeId: props.currentPatientId ?? null,
        relationship: code,
      })
    }
    useEffect(() => {
      if (props.success && chosenRelationship) props.onFinish(props.chosenCarer)
    }, [props.success])
    return (
      <Card
        title={`${languages.whoIs}${props.chosenCarer.firstname} ${
          props.chosenCarer.lastname
        } ${languages.for}${!currentPatientId ? languages.you : currentPatient.firstname} ? `}
      >
        <Carousel
          itemsPerPage={8}
          items={[
            ...relationships.map((relationship, key) => (
              <FatButton
                key={key}
                onClick={() => setRelationship(relationship)}
                size="small"
              >
                <span>{RELATIONSHIP_LABEL[relationship]}</span>
              </FatButton>
            )),
          ]}
        />
      </Card>
    )
  }
)
