
import { useForm } from "antd/lib/form/Form"
import { omit } from "lodash"
import React, { useEffect, useState } from "react"
import { connect, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"

import { getRelative } from "client/services/selector"
import { Card } from "components/Card/Card"
import { Form } from "components/Form/Form"
import PreviousButton from "components/Previous/PreviousButton"
import { NOT_AUTHORIZED_PAGE } from "core/constants"
import { Gender, Relative } from "types/entity"
import { Dispatcher } from "types/redux"
import { ClientStore, ProfileStore } from "types/store"

import { FullNameDisplayer, Modifier } from "./PatientInformation"
import actions from "./services/actions"
import { IDVerificationStatus, InputNames } from "types/props"
import { API_DATE_FORMAT, concatGenderAndName } from "lib/utils"
import searchCityFullNameWithInseeCode from "api/SearchBirthLocationName"
import { Dayjs } from "dayjs"
import { useLanguage } from "locales"


const ModifyRelative: React.FC<ProfileStore> = (props) => {
  const { languages } = useLanguage()
  const { id } = useParams<{ id?: string }>()
  const navigate = useNavigate()
  const [formRef] = useForm()

  useEffect(() => {
    if (!id) navigate(NOT_AUTHORIZED_PAGE)
  }, [id])

  useEffect(() => {
    if (props.success) props.resetProfile()
  }, [props.success])

  const relative = useSelector(getRelative(parseInt(id as string)))

  const [verified_fields] = useState(relative.id_verification_fields)
  const [isVerified] = useState(relative.id_verification_status===IDVerificationStatus.VERIFIED)

  useEffect(() => {
    // should be updated after the patient edit a value
    const newValues = {...omit(relative, "updated_at", "created_at")}
    formRef.setFieldsValue(newValues)
  }, [relative])

  useEffect(() => {
    const birthDate = (relative.birthdate as Dayjs).format(
      API_DATE_FORMAT
    )
    // should be updated after the patient edit a value
    if (/^\d+$/.test(relative.birth_location as string)){
      if(relative.birth_location === "99999"){
        formRef.setFieldValue(InputNames.BIRTH_LOCATION , languages.unknown)
        return
      }
      searchCityFullNameWithInseeCode(relative.birth_location as string, birthDate).then(
        res => {
          const birthLocationDisplayedValue = `${res.locations[0].name} (${relative.birth_location})` 
          formRef.setFieldValue(InputNames.BIRTH_LOCATION , birthLocationDisplayedValue)
          return
        }
      ).catch((error) => console.error(error, { path: "locations/public/search" }))
    }
    formRef.setFieldsValue({ ...relative})
  }, [relative, props.loading])


  return (
    <Card title={languages.modifyRelative} message={props.message}>
      <PreviousButton />
      <div>
        <Form
          form={formRef}
          initialValues={{...omit(relative, "updated_at", "created_at"), birthdate: (relative.birthdate).format(API_DATE_FORMAT)}}
        >
          <Modifier
            action={props.changeRelative}
            label={`${languages.modifyThe(InputNames.GENDER)} ${languages.of} ${
              relative?.firstname
            } ${relative?.lastname}`}
            valuesToIntegrate={{ id: parseInt(id as string) }}
            loading={props.loading}
            name={InputNames.GENDER}
          />
          <FullNameDisplayer
            inputInfo={languages.birth_names}
            value={concatGenderAndName(
              relative[InputNames.GENDER],
              relative[InputNames.FIRST_BIRTH_FIRSTNAME],
              relative[InputNames.BIRTH_LASTNAME]
              )}
            loading={props.loading} 
            lock={isVerified && verified_fields.first_birth_firstname && verified_fields.birth_lastname}
            >
              {
                [InputNames.FIRST_BIRTH_FIRSTNAME, InputNames.BIRTH_LASTNAME].map((inputName, key) => (
                  <Modifier
                    key={key}
                    action={props.changeRelative}
                    label={`${languages.modifyThe(inputName)} ${languages.of} ${
                      relative?.firstname
                    } ${relative?.lastname}`}
                    valuesToIntegrate={{ id: parseInt(id as string) }}
                    loading={props.loading}
                    name={inputName}
                    lock={isVerified && verified_fields[inputName]}
                  />
                ))
              }
        </FullNameDisplayer>
        <FullNameDisplayer
            inputInfo={languages.pseudo_names}
            value={concatGenderAndName(
              relative[InputNames.GENDER],
              relative[InputNames.FIRSTNAME],
              relative[InputNames.LASTNAME]
            )}
            loading={props.loading} 
            >
              {
                [InputNames.FIRSTNAME, InputNames.LASTNAME].map((inputName, key) => (
                  <Modifier
                    key={key}
                    action={props.changeRelative}
                    label={`${languages.modifyThe(inputName)} ${languages.of} ${
                      relative?.firstname
                    } ${relative?.lastname}`}
                    valuesToIntegrate={{ id: parseInt(id as string) }}
                    loading={props.loading}
                    name={inputName}
                    lock={isVerified && verified_fields[inputName]}
                  />
                ))
              }
        </FullNameDisplayer>
        <Modifier
            action={props.changeRelative}
            label={`${languages.modifyThe(InputNames.BIRTHDATE)} ${languages.of} ${
              relative?.firstname
            } ${relative?.lastname}`}
            valuesToIntegrate={{ id: parseInt(id as string) }}
            loading={props.loading}
            name={InputNames.BIRTHDATE}
            lock={isVerified && verified_fields[InputNames.BIRTHDATE]}
          />
          <Modifier
            action={props.changeRelative}
            label={`${languages.modifyThe(InputNames.BIRTH_LOCATION)} ${languages.of} ${
              relative?.firstname
            } ${relative?.lastname}`}
            valuesToIntegrate={{ id: parseInt(id as string) }}
            loading={props.loading}
            name={InputNames.BIRTH_LOCATION}
            lock={isVerified && verified_fields[InputNames.BIRTH_LOCATION]}
          />
        </Form>
      </div>
    </Card>
  )
}

const mapStateToProps = ({
  profile,
  client,
}: {
  profile: ProfileStore
  client: ClientStore
}): ProfileStore => {
  return {
    ...profile,
    relatives: client.relatives as Relative[],
  }
}
const mapDispatchToProps = (dispatch: Dispatcher): Partial<ProfileStore> => ({
  changeRelative: (r: Partial<Relative>) =>
    dispatch(actions.changeRelativeRequest(r)),
  resetProfile: () => dispatch(actions.reset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ModifyRelative)
