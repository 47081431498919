import { ActionWithoutPayload } from "types/redux"
import { RESET_NIR_AND_EXEMPTION_STORE } from "./constants"

const actions = {
  reset: (): ActionWithoutPayload => ({
    type: RESET_NIR_AND_EXEMPTION_STORE,
  }),
}

export default actions
