import { ReaderState } from "types/sesam"
import { NirReaderResponse, NirReaderStore } from "types/store"
import { Customer, Relative } from "types/entity"
import { Action, Message } from "types/redux"
import {
  NIR_READER_RESPONSE,
  RESET_NIR_READER,
  SET_NIR_READER_LOADING,
  SET_NIR_READER_MESSAGE,
  SET_NIR_READER_STATE,
} from "./constants"

const initialState: Partial<NirReaderStore> = {
  success: false,
  loading: false,
  readerState: ReaderState.INITIALIZE,
}

const reducer = function nirReducer(
  state = initialState,
  action: Action<NirReaderResponse | Message | ReaderState | boolean>
): Partial<NirReaderStore> {
  switch (action.type) {
    case NIR_READER_RESPONSE:
      return {
        ...state,
        message: undefined,
        response: action.payload as NirReaderResponse,
      }
    case SET_NIR_READER_STATE:
      return {
        ...state,
        message: undefined,
        readerState: action.payload as ReaderState,
      }
    case SET_NIR_READER_LOADING: {
      return {
        ...state,
        loading: action.payload as boolean,
      }
    }
    case SET_NIR_READER_MESSAGE: {
      return {
        ...state,
        message: action.payload as Message,
      }
    }
    case RESET_NIR_READER:
      return { ...initialState }
    default:
      return { ...state }
  }
}

export default reducer
