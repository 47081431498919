import React, { useState } from "react"

import { Buttons } from "components/Card/Card"
import { Modal } from "components/Modal/Modal"
import PreviousButton from "components/Previous/PreviousButton"
import { Important } from "components/Title/Title"
import { cdn } from "core/cdn"
import { getTime } from "lib/utils"
import { CallState } from "types/payload"
import { useLanguage } from "locales"

export const Accepted: React.FC<{
  doctorName: string
  date: Date
  loading: boolean
  callState: CallState
  cancelCall: () => void
}> = (props): JSX.Element => {
  const { languages } = useLanguage()
  const humanTime = getTime(props.date)
  const [modalVisible, setModalVisibility] = useState(false)
  return (
    <>
      <PreviousButton
        text={languages.cancel}
        onClick={() => setModalVisibility(true)}
      />
      <Buttons direction="vertical" style={{ marginTop: "0" }}>
        <div className="center flex">
          {props.callState === CallState.ACCEPTED && (
            <img src={cdn("/icons/waiting.svg")} alt="" />
          )}
          <Important
            shape={true}
            text={
              props.callState === CallState.ACCEPTED_AND_SOON
                ? languages.waitingDoctor
                : humanTime
            }
          />
        </div>
        {props.callState === CallState.ACCEPTED && (
          <div className="bold">
            <b>{languages.youWillBeInformedBySMS} </b>
          </div>
        )}
      </Buttons>
      <Modal
        loading={props.loading}
        title={languages.confirmCancelCall}
        visible={modalVisible}
        onAccept={() => props.cancelCall()}
        onCancel={() => setModalVisibility(false)}
      />
    </>
  )
}
