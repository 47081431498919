import { useForm } from "antd/lib/form/Form"
import { Dayjs } from "dayjs"
import React, { useState } from "react"

import { Button } from "components/Button"
import FatButton from "components/Button/FatButton/FatButton"
import { Buttons } from "components/Card/Card"
import { Carousel } from "components/Carousel/Index"
import { Modal } from "components/Modal/Modal"
import { cdn } from "core/cdn"
import { DATE_FORMAT } from "lib/utils"
import { Patient } from "types/sesam"
import { NirReaderResponse } from "types/store"

import styles from "./SelectedPatient.module.scss"
import { useLanguage } from "locales"

const SelectPatient = (props: {
  patients: NirReaderResponse
  onSelect: (p: Partial<Patient>) => void
}): JSX.Element => {
  const { languages } = useLanguage()
  const [formRef] = useForm()
  const [selectedPatient, setSelectedPatient] = useState<Partial<Patient>>()

  const patients = [props.patients.patient, ...props.patients.relatives]
  return (
    <Modal
      visible={true}
      footer={
        <Buttons>
          <Button
            wide="long"
            disabled={!selectedPatient}
            type="primary"
            onClick={() => props.onSelect(selectedPatient)}
          >
            {languages.chooseThisRelative}
          </Button>
        </Buttons>
      }
    >
      <div style={{ width: "1400px", margin: "auto" }}>
        {patients.length ? (
          <Carousel
            itemsPerPage={6}
            items={[
              ...patients.map((relative: Partial<Patient>, key: number) => (
                <FatButton
                  key={key}
                  clicked={selectedPatient === relative}
                  onClick={() => {
                    if (selectedPatient === relative)
                      setSelectedPatient(undefined)
                    else setSelectedPatient(relative)
                  }}
                >
                  <div className={styles.PatientInformation}>
                    {key === 0 ? (
                      <span
                        className={`${styles.SelectedBadge} ${
                          selectedPatient === relative ? styles.Checked : ""
                        }`}
                      >
                        {languages.owner}
                      </span>
                    ) : null}
                    <img src={cdn("/images/proche_picto.svg")} alt="" />
                    <b>{`${relative?.firstname} ${relative?.lastname}`}</b>
                    <div>
                      {`${languages.bornThe} ${(relative.birthdate as Dayjs).format(
                        DATE_FORMAT
                      )}`}
                    </div>
                  </div>
                </FatButton>
              )),
              <FatButton type="ghost" onClick={() => props.onSelect({})}>
                <div className={styles.Other}>
                  <img src={cdn("/images/plus_picto.svg")} alt="" />
                  <b> {languages.OtherRelative}</b>
                </div>
              </FatButton>,
            ]}
          />
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <FatButton type="ghost" onClick={() => props.onSelect({})}>
              <div className={styles.Other}>
                <img src={cdn("/images/plus_picto.svg")} alt="" />
                <b> {languages.OtherRelative}</b>
              </div>
            </FatButton>
          </div>
        )}
      </div>
    </Modal>
  )
}
export default SelectPatient
