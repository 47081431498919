import config from "react-global-configuration";

const FOREIGN_LOCATION = ['foreign_territory'];

const FRENCH_LOCATION = ['city_district', 'municipality', 'oversea_community_municipality'];

export default function searchCityFullNameWithInseeCode (inseeCode: string, birthdate: string){
    const location_type = inseeCode.slice(0, 2) === "99" ? FOREIGN_LOCATION : FRENCH_LOCATION;
    return fetch(`/api${config.get("locations")}`, {
      method: "POST",
      headers: {'Content-Type': 'application/json' },
      body: JSON.stringify({
        date: birthdate,
        capitalize_article: true,
        insee_code: inseeCode,
        location_types: location_type,
    })
    }).then((res: any) => {
      return res.json();
  })
  }