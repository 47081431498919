import { Button } from "components/Button"
import { Buttons } from "components/Card/Card"
import Spacer from "components/Spacer"
import { Information, Title } from "components/Title/Title"
import { cdn } from "core/cdn"
import { ModalProps } from "types/props"

import { GenericModal } from "./Modal"
import { useLanguage } from "locales"

export const NirBelongsToChildModal = (props: ModalProps): JSX.Element => {
  const { languages } = useLanguage()
  return (
    <GenericModal
      {...props}
      footer={
        <Buttons direction="vertical">
          <Button onClick={props.onAccept} wide="long" type="primary">
            {languages.fix}
          </Button>
          <Button onClick={props.onCancel} type="link" size="small" danger>
            {languages.keepThisNumber}
          </Button>
        </Buttons>
      }
    >
      <Spacer
        style={{ textAlign: "center", maxWidth: "70vw" }}
        size={30}
        align="center"
        direction="vertical"
      >
        <img
          height={114}
          src={cdn("icons/picto_vital_card.svg")}
          alt="Carte vitale"
        />
        <div style={{ padding: "0 100px" }}>
          <Title text={languages.warning} />
          <Information
            size="large"
            text={languages.addChildRelativeWithYourOwnVitalCard}
          />
        </div>
      </Spacer>
    </GenericModal>
  )
}
