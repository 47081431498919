import { Customer, Prospect, Relative } from "types/entity"
import { Action, Message } from "types/redux"
import { SignupStore } from "types/store"
import {
  RESET_SIGNUP_STORE,
  SIGNUP_ERROR,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
} from "./constants"

const initialState: Partial<SignupStore> = {
  loading: false,
  success: false,
}

const reducer = function signupReducer(
  state = initialState,
  action: Action<Prospect | Message | Relative[] | Customer>
): Partial<SignupStore> {
  switch (action.type) {
    case SIGNUP_REQUEST:
      return {
        ...state,
        loading: true,
        message: undefined,
      }

    case SIGNUP_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        message: action.payload as Message,
      }
    case SIGNUP_ERROR:
      return {
        ...state,
        success: false,
        loading: false,
        message: action.payload as Message,
      }
    case RESET_SIGNUP_STORE:
      return { ...initialState }
    default:
      return { ...state }
  }
}

export default reducer
