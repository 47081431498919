import { ButtonProps, FormInstance, FormItemProps, FormProps } from "antd"
import { Rule } from "antd/lib/form"
import { Subject } from "rxjs"

import { OurFormInstance } from "components/Form/useForm"
import { PATH } from "containers/Survey/models"
import { Value } from "containers/Survey/types"

import { Carer, Customer, Prospect, Relative } from "./entity"

export interface ItemProps extends FormItemProps {
  name?: string
  required?: boolean
  labelHidden?: boolean
  hidden?: boolean
  rules?: Rule[]
  addFilledCondition?: (isFilled: boolean) => void
}

export interface GetInputProps {
  extra?: string
  name: string
  visible: boolean
  value?: string
  disabled?: boolean,
  required?: boolean
  readOnly?: boolean
  prospect?: Partial<Relative | Customer | Prospect>
  formRef?: FormInstance
  changeKeyboardValue?: Subject<string>
  rules?: Rule[]
  addFilledCondition?: (isFilled: boolean) => void
  conditionChecked?: boolean
  changeTitle?: (newTitle: string | Element) => void
}
export interface WarningDefaultForm {
  title?: string
  acceptText?: string
  cancelText?: string
  contentText?: string
}
export interface InputFormProps {
  name: string
  type?: string
  label?: string | JSX.Element
  keepLabelFormat?: boolean
  required?: boolean
  autoSubmit?: boolean
  extra?: string
  rules?: Rule[]
  readOnly?: boolean
  default?: {
    value?: string
    label: string
    warning?: WarningDefaultForm
  }
  condition?: {
    label?: string,
    filled: InputFormProps,
    type?: string
  }
}

export const enum InputNames {
  BIRTH_COUNTRY = "birth_country",
  BIRTH_LASTNAME = "birth_lastname",
  BIRTH_LOCATION = "birth_location",
  BIRTHDATE = "birthdate",
  INSEE_CODE = "insee_code",
  FIRST_BIRTH_FIRSTNAME = "first_birth_firstname",
  FIRSTNAME = "firstname",
  LASTNAME = "lastname",
  GENDER = "gender",
  Email = "email",
  PHONE = "phone",
  NEW_PASSWORD = "new-password"
}

export const enum IDVerificationStatus {
  CREATED = "created",
  VERIFIED = "verified",
  PROCESSING = "processing",
  CANCELED = "canceled",
  REQUIRES_INPUT = "requires_input",
  REDACTED = "redacted",
  FAILED = "failed",
  MISMATCHED = "mismatched",
  RETRY = "retry",
  LEGACY = "legacy",
  REFUSED = "refused",
}
export interface ModalProps {
  iconError?: boolean
  visible: boolean
  titleProps?: { size?: "small" | "medium" | "large", style?: any, doNotAnimateTitle?: boolean }
  title?: string | JSX.Element
  onAccept?: () => void
  onCancel?: () => void
  onClose?: () => void
  acceptText?: string
  cancelText?: string
  loading?: boolean
  closable?: boolean
  noFooter?: boolean
  children?: JSX.Element
  footer?: JSX.Element
  contentText?: string
  acceptButtonProps?: ButtonProps & { wide?: "long" | "default" | "short" }
  cancelButtonProps?: ButtonProps & { wide?: "long" | "default" | "short" }
  buttonWrapperStyle?: any
  style?: any
  bodyStyle?: any
}

type ObjectValue = {
  [key: string]: Value
}
export interface FormPageProps extends FormProps {
  readOnly?: boolean
  prospect?: Partial<Relative | Customer | Prospect | Carer>
  inputs: InputFormProps[]
  hidePrevious?: boolean
  onFinish: (
    values: Partial<Prospect | Relative | Customer | ObjectValue | any>
  ) => void
  onCancel: () => void
  loading?: boolean
  acceptText?: string
  cancelText?: string
  formRef?: OurFormInstance
}

export interface QuestionLanguages {
  id: PATH,
  patient?: {
    title?: string
    defaultReply?: string
    subtitle?: string
  }
  relative?: {
    male: {
      title?: string
      defaultReply?: string
      subtitle?: string
    },
    female: {
      title?: string
      defaultReply?: string
      subtitle?: string
    }
  }
}

export const enum TutoState {
  HOME,
  FULL,
  CHAPTER,
}
export interface VideoProps {
  poster?: string
  video?: string
  onClick?: () => void
  title?: string
  play?: boolean
  className?: string
  width?: string
  controls?: boolean
}
export type CustomerType = 'unknown' | 'patient' | 'relative_male' | 'relative_female' | 'relative'

export interface Configuration {
  nirReader?: "api" | "websocket"
  doctorChoice?: boolean
}
