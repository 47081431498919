import { concat, flatten, uniq, cloneDeep } from "lodash"
import { addMinutes, getRoundHour, getTime } from "../../lib/utils"
import { DURATION_SLOT_MIN, ITEMS_PER_CAROUSEL_PAGE, PATH_DEEP } from "./models"
import { ACTION, Answer, Question, Reply, UiPosition } from "./types"
import { pick } from "lodash"
import { cdn } from "core/cdn"
import config from "react-global-configuration"
/*
 *
 */
export const get_complete_path = (pathId: string): string[] => {
  const partial_path = pathId.split(":")

  let pathToIncrement = ""
  return partial_path.map((step: string, key: number) => {
    pathToIncrement += key ? `:${step}` : `${step}`
    return pathToIncrement
  })
}

export const replace_question = (
  questions: Question[],
  question: Question
): Question[] => {
  return questions.map((_q) =>
    _q.id === (question as Question).id ? question : _q
  ) as Question[]
}
export const is_deep_path = (path: string): boolean => path.includes(PATH_DEEP)

export const get_index_of_position = (
  position: UiPosition,
  itemsLength: number
): number => {
  switch (position) {
    case "right":
      return itemsLength
    case "middle":
      return itemsLength / 2
    case "left":
    default:
      return 0
  }
}

export function split<T>(array: T[], nbElement: number): T[][] {
  const chunk = []
  while (array.length > 0) chunk.push(array.splice(0, nbElement))
  return chunk
}

export const splitCarouselPages = (
  items: Reply[],
  nbElement: number
): Reply[][] => {
  const chunk = []
  const array = cloneDeep<Reply[]>(items)
  while (array.length > 0) chunk.push(array.splice(0, nbElement))
  return chunk
}

export const recursive_get = (question: Question, path: string[]): Question => {
  if (path.indexOf(question.id) === -1) return question
  else {
    path.splice(path.indexOf(question.id), 1)
    if (!path.length) return question
    else if (question.subquestion)
      return recursive_get(question.subquestion, path)
  }
  return question
}

/*
 * from replies, give the new part of the _path
 */
export const get_sub_path = (replies: Reply[], current: Question): string[] =>
  replies
    .map(({ action }: Reply) =>
      action === ACTION.subquestion && current.subquestion
        ? current.subquestion?.id
        : undefined
    )
    .filter((path: string | undefined) => path) as string[]

/*
 * update path question regarding the lasts replies
 */
export const update_path = (
  lastReplies: Reply[],
  actualPath: string[],
  current: Question
): string[] => {
  const subPath: string[] = get_sub_path(lastReplies, current)

  const split_subPath = uniq(
    flatten(subPath.map((sub) => sub.split(PATH_DEEP)))
  )

  const path: string[] | string[][] =
    actualPath.map((step) => {
      const parentOfSub = split_subPath
        .filter((v, key) => key < split_subPath.length - 1)
        .join(PATH_DEEP)

      if (step === parentOfSub) {
        return [step, ...subPath]
      }
      return [step]
    }) || actualPath
  return flatten(path)
}

export const DocTypeToPicture = {
  report: cdn("images/pico_compte-rendu.svg"),
  prescription: cdn("images/pico_ordonnance.svg"),
  treatment: cdn("images/pico_medicament.svg"),
  administrative: cdn("images/pico_piece_administrative.svg"),
  picture: cdn("images/pico_photo_corporelle.svg"),
  other: cdn("images/pico_autre.svg"),
  certificate: cdn("images/pico_certificat.svg"),
  analyses: cdn("images/pico_analyse_bio.svg"),
  letter: cdn("images/pico_courrier_adressage.svg"),
  imaging: cdn("images/picto_radiologie.svg"),
}

export const DocTypeToName = {
  report: "compte_rendu",
  prescription: "ordonnance",
  treatment: "médicaments",
  administrative: "administratif",
  picture: "photo",
  other: "",
  certificate: "certificat",
  analyses: "analyses",
  letter: "lettre",
  imaging: "radio",
}

export const getUsefulData = (doc: any) => {
  const TIME_TO_PRESELECT_DOC = config.get("timeout.select_doc_time");
  const value = pick(doc, ["id", "created_at", "type", "title"])
  const then = new Date(value.created_at)
  const now = new Date()
  const month = then.getMonth() + 1
  const day = then.getDate()
  const year = then.getFullYear()
  const msBetweenDates = Math.abs(then.getTime()-now.getTime())
  const hoursBetweenDates = msBetweenDates / (60*60*1000);

  const preselect =  (hoursBetweenDates < TIME_TO_PRESELECT_DOC)
  const reply = {
    imgUrl: DocTypeToPicture[value.type],
    title: `${value.title} ${day}/${month}/${year}`,
    value: value.id,
    preselect:  preselect
  }
  return reply
}

export const isThisDocNewlyUpdated = (doc: any, created_at: Date) => {
  const documentAge = (new Date(doc.created_at)).getTime();
  const linkAge = (new Date(created_at)).getTime();
  const createdAfterLinkRequest = (linkAge < documentAge)
  return createdAfterLinkRequest ? doc : null
}

export const update_answers = (answers: Answer[], fresh: Answer): Answer[] => {
  const a = answers
  const toSet: Answer | undefined = a.find(
    ({ questionId }) => questionId === fresh.questionId
  )

  if (toSet !== undefined) {
    fresh.value = uniq(concat(toSet.value, fresh.value))
    a.splice(answers.indexOf(toSet), 1)
  }
  // Push fresh values
  a.push(fresh)
  return a
}

export function insertAt<T>(array: T[], index: number, ...elements: T[]): void {
  array.splice(index, 0, ...elements)
}

export const getSlotsReplies = (
  beforeSlots: number,
  afterSlots: number
): Reply[] => {
  const date: Date = getRoundHour()
  const passedDate = addMinutes(
    date,
    ITEMS_PER_CAROUSEL_PAGE * beforeSlots * -DURATION_SLOT_MIN
  )
  // CountSlots is the number of slots page
  // regarding the nb of items in a carousel page
  const countSlots: number =
    ITEMS_PER_CAROUSEL_PAGE * (afterSlots + beforeSlots)
  const replies: Reply[] = []
  for (let i = 0; i < countSlots; i++) {
    const minutesToAdd = i * DURATION_SLOT_MIN
    replies.push({
      title: getTime(addMinutes(passedDate, minutesToAdd)),
      value: addMinutes(passedDate, minutesToAdd).getTime(),
    })
  }
  return replies
}
