import { Button } from "components/Button"
import { Buttons } from "components/Card/Card"
import Spacer from "components/Spacer"
import { Information, Title } from "components/Title/Title"
import { cdn } from "core/cdn"
import { ModalProps } from "types/props"

import { GenericModal } from "./Modal"
import { useLanguage } from "locales"

export const RegisterAccountBelongsToChildModal = (
  props: ModalProps
): JSX.Element => {
  const { languages } = useLanguage()
  return (
    <GenericModal
      {...props}
      footer={
        <Buttons direction="vertical">
          <Button onClick={props.onAccept} wide="long" type="primary">
            {languages.IGotIt}
          </Button>
        </Buttons>
      }
    >
      <Spacer
        style={{ textAlign: "center" }}
        size={5}
        align="center"
        direction="vertical"
      >
        <img
          height={114}
          src={cdn("icons/picto_relative.svg")}
          alt="Carte vitale"
        />
        <Title text={languages.majorityRequired} />
        <div className="modal-content-left">
          <Information
            size="large"
            text={<span>{languages.majorityRequiredInfoFirstChunk}<span style={{fontWeight: 800}}>{languages.majorityRequiredInfoSecondChunk}</span>{languages.majorityRequiredInfoLastChunk}</span>}
          />
          <Information
            style={{ fontWeight: "bold" }}
            size="large"
            text={languages.majorityRequiredAddRelative}
          />
        </div>
      </Spacer>
    </GenericModal>
  )
}
