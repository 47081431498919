import { Action, Message } from "types/redux"
import { VerifyPhoneStore } from "types/store"
import {
  CANCEL_SIGNUP_ERROR,
  CANCEL_SIGNUP_REQUEST,
  CANCEL_SIGNUP_SUCCESS,
  RESET_VERIFYPHONE_STORE,
  SEND_AGAIN_ERROR,
  SEND_AGAIN_REQUEST,
  SEND_AGAIN_SUCCESS,
  SET_PHONE_CODE_ERROR,
  SET_PHONE_CODE_REQUEST,
  SET_PHONE_CODE_SUCCESS,
} from "./constants"

const initialState: Partial<VerifyPhoneStore> = {
  loading: false,
  success: false,
}

const reducer = function verifyPhoneReducer(
  state = initialState,
  action: Action<string | Message>
): Partial<VerifyPhoneStore> {
  switch (action.type) {
    case SET_PHONE_CODE_REQUEST:
    case SEND_AGAIN_REQUEST:
    case CANCEL_SIGNUP_REQUEST:
      return { ...state, loading: true, message: undefined }
    case SET_PHONE_CODE_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        message: action.payload as Message,
      }
    case SEND_AGAIN_SUCCESS:
    case SEND_AGAIN_ERROR:
    case CANCEL_SIGNUP_SUCCESS:
    case CANCEL_SIGNUP_ERROR:
    case SET_PHONE_CODE_ERROR:
      return {
        ...state,
        success: false,
        loading: false,
        message: action.payload as Message,
      }
    case RESET_VERIFYPHONE_STORE:
      return initialState
    default:
      return { ...state }
  }
}

export default reducer
