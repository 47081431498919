import { Action, ActionWithoutPayload, Message } from "types/redux"
import { CardInformation } from "types/stripe"

import {
  CARDS_ERROR,
  CARDS_REQUEST,
  CARDS_SUCCESS,
  DELETE_CARD_ERROR,
  DELETE_CARD_REQUEST,
  DELETE_CARD_SUCCESS,
  GET_TOKEN_ERROR,
  GET_TOKEN_REQUEST,
  GET_TOKEN_SUCCESS,
  HAS_STRIPE_ERROR,
  RESET_CARD_STORE,
  SETUPINTENT_ATTACH_ERROR,
  SETUPINTENT_ATTACH_REQUEST,
  SETUPINTENT_ATTACH_SUCCESS,
  SETUPINTENT_CREATE_ERROR,
  SETUPINTENT_CREATE_REQUEST,
  SETUPINTENT_CREATE_SUCCESS,
} from "./constants"

export const actions = {
  cardsRequest: (): ActionWithoutPayload => ({
    type: CARDS_REQUEST,
  }),
  cardsSuccess: (value: CardInformation[]): Action<CardInformation[]> => ({
    type: CARDS_SUCCESS,
    payload: value,
  }),
  cardsError: (error: string): Action<Message> => ({
    type: CARDS_ERROR,
    payload: { type: "error", text: error },
  }),

  attachRequest: (payload: {
    setupintent_id: string
  }): Action<{ setupintent_id: string }> => ({
    type: SETUPINTENT_ATTACH_REQUEST,
    payload,
  }),
  attachSuccess: (value: string): Action<Message> => ({
    type: SETUPINTENT_ATTACH_SUCCESS,
    payload: { type: "success", text: value },
  }),
  attachError: (value: string): Action<Message> => ({
    type: SETUPINTENT_ATTACH_ERROR,
    payload: { type: "error", text: value },
  }),

  deleteCardRequest: (
    card: CardInformation
  ): Action<{ payment_method_id: string }> => ({
    type: DELETE_CARD_REQUEST,
    payload: { payment_method_id: card.id },
  }),
  deleteCardError: (error: string): Action<Message> => ({
    type: DELETE_CARD_ERROR,
    payload: { type: "error", text: error },
  }),
  deleteCardSuccess: (value: string): Action<Message> => ({
    type: DELETE_CARD_SUCCESS,
    payload: { type: "success", text: value },
  }),

  createRequest: (): Action<undefined> => ({
    type: SETUPINTENT_CREATE_REQUEST,
    payload: undefined,
  }),
  createSuccess: (payload: string): Action<string> => ({
    type: SETUPINTENT_CREATE_SUCCESS,
    payload,
  }),
  createError: (value: string): Action<Message> => {
    return {
      type: SETUPINTENT_CREATE_ERROR,
      payload: { type: "error", text: value || "oups" },
    }
  },
  resetCard: (): ActionWithoutPayload => ({ type: RESET_CARD_STORE }),

  hasStripeError: (hasError: boolean): Action<boolean> => ({
    type: HAS_STRIPE_ERROR,
    payload: hasError,
  }),

  getTokenRequest: (): ActionWithoutPayload => ({ type: GET_TOKEN_REQUEST }),
  getTokenSucess: (public_stripe_key: string): Action<string> => ({
    type: GET_TOKEN_SUCCESS,
    payload: public_stripe_key,
  }),
  getTokenError: (payload?: string): Action<Message> => ({
    type: GET_TOKEN_ERROR,
    payload: { type: "error", text: payload ?? "Une erreur est survenue" },
  }),
}

export default actions
