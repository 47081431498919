import { FormInstance, Radio, RadioGroupProps } from "antd"
import React, { useEffect, useRef, useState } from "react"

import FatButton from "components/Button/FatButton/FatButton"
import Spacer from "components/Spacer"
import { Value } from "containers/Survey/types"
import { Input } from "components/Input"
interface RadioboxValue {
  value: Value
  imgUrl?: string
  text: string
  onclick?: () => void
}

interface RadioboxProps extends RadioGroupProps {
  values: RadioboxValue[]
  formRef?: FormInstance
}

const Radiobox: React.FC<RadioboxProps> = ({
  values,
  formRef,
  ...radioGroupProps
}) => {
  const [selectedValue, setSelectedValue] = useState<Value | undefined>()

  useEffect(() => {
    setSelectedValue(radioGroupProps.value)
  }, [radioGroupProps])

  if (radioGroupProps.disabled) {
    radioGroupProps.value = `${radioGroupProps.value===0 ? "HOMME" : radioGroupProps.value===1 ? "FEMME" : "Non renseigné"}`
    return (
       <Input {...(radioGroupProps as unknown as any)} style={{maxWidth:190, border:0}}/>
    )
  }
  return (
    <div>
      <Radio.Group
        size="large"
        style={{ display: "none" }}
        {...(radioGroupProps as RadioGroupProps)}
        value={selectedValue}
      >
        {values.map((radioboxValue, key) => {
          return (
            <Radio.Button
              key={key}
              value={radioboxValue.value}
              checked={selectedValue === radioboxValue.value}
            >
              {radioboxValue.text}
            </Radio.Button>
          )
        })}
      </Radio.Group>
      <Spacer size={60}>
        {values.map((radioboxValue, key) => {
          return (
            <FatButton
              clicked={selectedValue === radioboxValue.value}
              onClick={() => {
                radioboxValue.onclick && radioboxValue.onclick();
                // obligé de passer la référene du formulaire
                formRef &&
                  formRef.setFieldsValue({
                    [radioGroupProps.name as string]: radioboxValue.value,
                  })
                formRef && formRef.validateFields()

                setSelectedValue(radioboxValue.value)
              }}
              key={key}
              img={radioboxValue.imgUrl}
            >
              {radioboxValue.text}
            </FatButton>
          )
        })}
      </Spacer>
    </div>
  )
}
export default Radiobox
