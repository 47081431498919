import {
  ADMIN_LOGIN_ERROR,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGIN_REQUEST,
  ADMIN_LOGOUT,
} from "./constants"
import { AdminStore } from "types/store"
import { Admin } from "types/entity"
import { Action, Message } from "types/redux"

const initialState: Partial<AdminStore> = {
  success: false,
  loading: false,
}

const reducer = function adminReducer(
  state = initialState,
  action: Action<Admin | Message>
): Partial<AdminStore> {
  switch (action.type) {
    case ADMIN_LOGOUT:
      return { ...state, loading: false, success: false }
    case ADMIN_LOGIN_REQUEST:
      return { ...state, loading: true, message: undefined }
    case ADMIN_LOGIN_SUCCESS:
      return {
        ...state,
        message: { text: "Vous êtes bien connecté", type: "success" },
        success: true,
        loading: false,
      }
    case ADMIN_LOGIN_ERROR:
      return {
        ...state,
        message: action.payload as Message,
        success: false,
        loading: false,
      }
    default:
      return { ...state, message: undefined }
  }
}

export default reducer
