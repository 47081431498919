import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux"
import { Col, Form, Input, Row } from "antd";
import Button from "components/Button/Button/Index"
import {request} from "lib/request"
import actions from "containers/Profile/services/actions"
import {Modal, GenericModal} from "components/Modal/Modal";
import config from "react-global-configuration";
import Styles from './newsletter.module.scss';
import { ClientStore } from "types/store";
import { cdn } from "core/cdn";
import { useLanguage } from "locales";

const Newsletter = () => {
    const { languages } = useLanguage()
    const newsletterPatientId = config.get("newsletterSubPatientId");
    const [subscribed, setSubscribed] = useState(undefined);
    const [reoptinUrl, setReoptinUrl] = useState("https://info.medadom.com/reabonnez-vous-a-notre-newsletter?email=");
    const [showSubscribeModal, setShowSubscribeModal] = useState(false);
    const [ShowAlertModal, setShowAlertModal] = useState(false);
    const dispatch = useDispatch();

    const clientEmail = useSelector(({client} : { client : ClientStore }) => client?.customer?.email);

    useEffect(() => {
        request('/crm/subscriptions/status', {method: "GET"})
        .then((res : any) => {
            if(res && res.status === "ok") {
                const sub = res.subscriptions.find((subscription) => subscription.id = newsletterPatientId);
                setReoptinUrl(sub.reoptin_url);
                setSubscribed(sub.subscribed);
            }
        })
    }, [])
    return (
        <div>
            <GenericModal visible={showSubscribeModal} footer={undefined} bodyStyle={{height: 600}}>
                <div>
                    <div style={{textAlign: "right"}} onClick={() => setShowSubscribeModal(false)}>
                        <img src={cdn("/images/close_pop-up.svg")} alt="close_icon" style={{paddingBottom: "40px"}}/>
                    </div>
                    <iframe src={`${reoptinUrl}${clientEmail}`} width="1200px" height="600px"/>
              </div>
            </GenericModal>
            <Modal visible={ShowAlertModal}
                onCancel={() => {
                    setShowAlertModal(false);
                }}
                cancelText="Annuler"
                cancelButtonProps={{type: "primary"}}
                acceptText="Me désabonner"
                acceptButtonProps={{style:{color: "#d64949", borderColor: "#d64949"}}}
                onAccept={() => {
                    dispatch(actions.unsubscribeNewsletter());
                    setSubscribed(false);
                    setShowAlertModal(false);
                }}>
                <div className={Styles.textContainer}>
                    <p className={Styles.text}>Votre désabonnement à "Newsletters Patients" sera <b>immédiat.</b><br /> Si vous souhaitez vous réabonner plus tard, <br /> le traitement de votre demande peut prendre jusqu'à <b>48h (jours ouvrés).</b></p>
                </div>
            </Modal>
            <Form>
            <Row gutter={[16, 16]}>
          <Col span="19">
          <Form.Item>
                    <Input placeholder={languages.subscribePlaceholder} readOnly style={{fontSize: "28px"}} />
                </Form.Item>
          </Col>
          <Col span="4">
            <div className={Styles.buttonContainer}>
                <Button
                        disabled={subscribed === undefined || !subscribed}
                        onClick={() => {
                            setShowAlertModal(true);
                        }}>
                        {languages.no}
                    </Button>
                    <Button style={{marginLeft: "25px"}}
                        onClick={() => {
                            setShowSubscribeModal(true);
                            setSubscribed(true);
                        }}
                        disabled={subscribed === undefined || subscribed}
                    >
                            {languages.yes}
                    </Button>
            </div>
          </Col>
        </Row>
            </Form>
        </div>
    );
};

export default Newsletter;