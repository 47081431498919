import config from "react-global-configuration"
import { all, put, select, takeLatest } from "redux-saga/effects"

import clientActions from "client/services/actions"
import { request } from "lib/request"
import { Response2faId, ResponseLogin, twoFaData } from "types/payload"
import { Action, GFlow, GWatcher } from "types/redux"

import { callHasChanged } from "containers/Call/services/actions"
import {
  AskingFor2FaToggle,
  AskingFor2FaVerify,
  cancel2FaSuccess,
  loginError,
  loginSuccess,
  requestOngoing,
  resendSuccess,
  twoFaVerifyError
} from "./actions"
import {
  CANCEL_2FA,
  LOGIN_REQUEST,
  LOGOUT,
  RESEND_2FA,
  TOGGLE_2FA,
  VERIFY_2FA,
  _2FA_RESEND_ERROR,
  _2FA_TOGGLE_ERROR,
  _2FA_VERIFY_ERROR
} from "./constants"

import { getCurrentCallApi } from "containers/Call/services/saga"
import { CALL_PAGE, HOME_PAGE, PROFILE_PAGE } from "core/constants"
import { push } from "redux-first-history"
import { languages } from "locales/languages"
/*
 *  API REQUESTS
 */
async function loginApi(payload?: {
  phone: string
  password: string
}): Promise<ResponseLogin> {
  return await request(config.get("patient.login"), {
    method: "POST",
    payload,
  })
}

async function login2faApi(payload?: {
  phone: string
  password: string
}): Promise<ResponseLogin | Response2faId> {
  return await request(config.get("patient.login2fa"), {
    method: "POST",
    payload,
  })
}

async function login2faToggleApi(): Promise<Response2faId> {
  return await request(config.get("patient.login2faToggle"), {
    method: "POST",
  })
}

async function login2faVerifyApi(payload?: {
  token: string
  mfa_verify_id: string
  toggle: boolean
}): Promise<ResponseLogin> {
  const url = payload.toggle ? 'login2faToggleVerify' : 'login2faVerify'
  return await request(config.get(`patient.${url}`), {
    method: "POST",
    payload,
  })
}

async function login2faResendApi(payload: twoFaData): Promise<any> {
  const { mfa_verify_id, toggle } = payload
  const baseUrl = config.get(`patient.${toggle ? 'login2faToggleResend' : 'login2faResend'}`)
  const RequestPayload = {
    "mfa_verify_id": mfa_verify_id
  }
  return await request(`${baseUrl}${payload.transport}`, {
    method: "POST",
    payload: RequestPayload,
  })
}

async function login2faCancelApi(payload?: {
  mfa_verify_id: string
}): Promise<any> {
  return await request(config.get(`patient.login2faCancel`), {
    method: "POST",
    payload,
  })
}

async function logoutApi() {
  return await request(config.get("patient.logout"), {
    method: "POST",
  })
}

/*
 * FLOWS
 */
function* loginFlow({
  payload,
}: Action<{ phone: string; password: string }>): GFlow<ResponseLogin> {
  try {
    const response: ResponseLogin = yield loginApi(payload)
    if (response.customer) {
      const callInProgress = yield getCurrentCallApi();
      yield all([
        put(loginSuccess(response.customer)),
        put(clientActions.setCustomer(response.customer)),
      ])
      if (callInProgress) {
        yield put(callHasChanged(callInProgress.call))
        yield put(push(CALL_PAGE))
      } else {
        yield put(push(PROFILE_PAGE))
      }
    }

  } catch (error) {
    console.error(error, { route: config.get("patient.login") })
    yield all([put(loginError(error as string))])
  }
}

function* login2faFlow({
  payload,
}: Action<{ phone: string; password: string }>): GFlow<ResponseLogin | Response2faId> {
  try {
    const response: ResponseLogin | Response2faId = yield login2faApi(payload)
    if (response.customer) {
      const customer = response.customer
      if ("mfa_verify_id" in customer) {
        customer.phone = payload.phone
        yield (put(AskingFor2FaVerify(customer as twoFaData)))
      }
      else if ("enabled2fa" in customer && customer["enabled2fa"] === false) {
        const collectedData = {
          phone: payload.phone,
          has_valid_email: customer.email ? true : false,
        }
        yield (put(AskingFor2FaToggle(collectedData as twoFaData)))
      }
    }
  } catch (error) {
    console.error(error, { route: config.get("patient.login2fa") })
    yield all([put(loginError(error as string))])
  }
}

function* login2faVerifyFlow({
  payload,
}: Action<{
  token: string
  mfa_verify_id: string
  toggle: boolean
}>): GFlow<ResponseLogin> {

  yield put(requestOngoing())

  try {

    const response: ResponseLogin = yield login2faVerifyApi(payload)
    if (response.customer) {
      const callInProgress = yield getCurrentCallApi();

      yield all([
        put(loginSuccess(response.customer)),
        put(clientActions.setCustomer(response.customer)),
      ])

      if (callInProgress) {
        yield put(callHasChanged(callInProgress.call))
        yield put(push(CALL_PAGE))
      } else {
        yield put(push(PROFILE_PAGE))
      }
    }
  } catch (error) {
    const url = payload.toggle ? 'login2faToggleVerify' : 'login2faVerify'
    console.error(error, { route: config.get(`patient.${url}`) })
    yield all([put(twoFaVerifyError(languages.twoFa_invalidCode as string))])
  }
}

function* login2faToggleFlow({
  payload,
}: Action<twoFaData>): GFlow<Response2faId> {
  try {
    yield put(requestOngoing())
    const response: any = yield login2faToggleApi()
    if (response.status === "ok") {
      const newTwoFaData = {
        ...payload,
        mfa_verify_id: response.mfa_verify_id,
        toggle: true,
      }
      yield (put(AskingFor2FaVerify(newTwoFaData as twoFaData)))
    }
  } catch (error) {
    console.error(error, { route: config.get(`patient.login2faToggle`) })
    yield all([put(twoFaVerifyError(languages.twoFa_activationError as string))])
  }
}

function* login2faResendFlow({
  payload,
}: Action<twoFaData>): GFlow<ResponseLogin> {
  try {
    yield put(requestOngoing())
    const response: any = yield login2faResendApi(payload)
    if (response.status === "ok") {
      const newTwoFaData = {
        ...payload,
        mfa_verify_id: response.mfa_verify_id
      }
      yield (put(resendSuccess(newTwoFaData)))
    }

  } catch (error) {
    const url = payload.toggle ? 'login2faToggleResend' : 'login2faResend'
    console.error(error, { route: config.get(`patient.${url}`) })
    yield all([put(twoFaVerifyError(error as string))])
  }
}

function* login2faCancelFlow({ payload }: Action<{ mfa_verify_id: string }>): GFlow<void> {
  yield login2faCancelApi(payload)
  yield put(cancel2FaSuccess())
  yield put(push(HOME_PAGE))
}

function* logoutFlow(): GFlow<void> {
  yield logoutApi()
  yield put(clientActions.loggedOut)
}

function* loginWatcher(): GWatcher {
  yield takeLatest(LOGIN_REQUEST, login2faFlow)
  yield takeLatest(TOGGLE_2FA, login2faToggleFlow)
  yield takeLatest(RESEND_2FA, login2faResendFlow)
  yield takeLatest(VERIFY_2FA, login2faVerifyFlow)
  yield takeLatest(CANCEL_2FA, login2faCancelFlow)
  yield takeLatest(LOGOUT, logoutFlow)
}

export default loginWatcher
