import React, { useMemo, useState } from "react";
import { IStrings, getCurrentLanguage, languages } from "./languages";

export type LanguageContextProps={
  languages: IStrings;
  changeLanguage: (lang: string)=> void;
  currentLang: string;
}

export const LanguageContext = React.createContext<LanguageContextProps | null>(null);

export default function LanguageProvider ({ children }){
  const [currentLang] = useState(getCurrentLanguage())

  const changeLanguage = (lang: string) => {
    localStorage.setItem('lang', lang);
    window.location.reload()
  };

  const memoizedValues = useMemo(()=>({
    languages,
    changeLanguage,
    currentLang
  }), [languages, changeLanguage, currentLang])

  return (
    <LanguageContext.Provider value={memoizedValues}>
      {children}
    </LanguageContext.Provider>
  );
};