import { Input, InputProps } from "antd"
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons"
import { useState } from "react"
import { isPasswordValid } from "lib/form"
import React from "react"
import { useLanguage } from "locales"

const Details = ({ password }: { password: string }) => {
  const { languages } = useLanguage()
  return (
    <isPasswordValid.Container>
      <ul>
        <isPasswordValid.Contenant
          text={languages.tenCharacters}
          valid={isPasswordValid.isMoreThanTenCharLength(password)}
        />
      </ul>
      <ul>
        <isPasswordValid.Contenant
          text={languages.oneLowerCaseLetter}
          valid={isPasswordValid.containOneMinusChar(password)}
        />
      </ul>
      <ul>
        <isPasswordValid.Contenant
          text={languages.oneUpperCaseLetter}
          valid={isPasswordValid.containOneCapsChar(password)}
        />
      </ul>
      <ul>
        <isPasswordValid.Contenant
          text={languages.oneNumber}
          valid={isPasswordValid.containOneNumeric(password)}
        />
      </ul>
    </isPasswordValid.Container>
  )
}
const NewPassword = (
  props: InputProps & { visible?: boolean }
): JSX.Element => {
  const password = (props.value as string) || ""
  const [visible, setVisilibity] = useState(props.visible ?? true)
  if (visible) {
    return (
      <>
        <Input
          {...props}
          suffix={
            <EyeOutlined
              onClick={() => {
                setVisilibity(false)
              }}
            />
          }
        />
        {!isPasswordValid.isValid(password) && <Details password={password} />}
      </>
    )
  }

  return (
    <>
      <Input.Password
        {...props}
        iconRender={(visible) =>
          visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
        }
      />
      {!isPasswordValid.isValid(password) && <Details password={password} />}
    </>
  )
}

export default NewPassword
