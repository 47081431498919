import dayjs from "dayjs"
import * as DOMPurify from "dompurify"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { Button } from "components/Button"
import { Buttons } from "components/Card/Card"
import { GenericModal } from "components/Modal/Modal"
import PreviousButton from "components/Previous/PreviousButton"
import Spacer from "components/Spacer"
import { SecurityToInsertReferent } from "containers/InsertCarer/InsertProfessionalCarer"
import { cdn } from "core/cdn"
import { ADMIN_CARERS_PAGE, STATISTICS_PAGE, SURVEY_PAGE, TUTORIALS_PAGE } from "core/constants"
import { ClientStore } from "types/store"

import styles from "./Home.module.scss"
import useUpdator from "./hooks/useUpdator"
import { useLanguage } from "locales"

const Home: React.FC<unknown> = ({}) => {
  const { languages } = useLanguage()
  // Update REACT_APP
  const { version, needToUpdate } = useUpdator()
  const [displayedVersion, setDisplayedVersion] = useState<string | null>(null)
  if (needToUpdate) window.location.reload()
  ///////////////////////////////////////////
  const [modalPharmaAccountVisible, setModalPharmaAccountVisible] =
    useState(false)
  const [securedNavigationFor, setSecuredNavigationFor] = useState(null)

  const [showAccessibility, setShowAccessibility] = useState(false);
  const navigate = useNavigate()

  const { admin } = useSelector(({ client }: { client: ClientStore }) => ({
    admin: client.admin,
  }))

  useEffect(() => {
    if (version) {
      const datify = dayjs(version)
      if (datify.isValid()) setDisplayedVersion(datify.format("DD.MM.YYYY"))
      else setDisplayedVersion(version)
    }
  }, [version])

  return (
    <>
      {admin && (
        <div
          className={styles.PharmaAccount}
          onClick={() => {
            setModalPharmaAccountVisible(true)
          }}
        >
          <img
            src={cdn("/icons/profil_pharma.svg")}
            height={30}
            alt={languages.yourPharmaAccount}
          />
          <span className={styles.ConnectedAs}>{admin?.name}</span>
        </div>
      )}
      <div className={styles.LayoutHome} onClick={() => navigate(SURVEY_PAGE)}>
        <div className={styles.TaglineInfo}>
          <img src={cdn("images/medadom_blue.png")} height={60} alt="" />
          <img
            src={cdn("images/medadom_logo_blue.svg")}
            height={60}
            alt="medadom logo"
            style={{ marginLeft: "15px" }}
          />
        </div>
        <span className={styles.TouchScreen}>{languages.touch_screen}</span>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "200px",
            height: "300px",
          }}
        >
          <span className={styles.click_here_logo}></span>
        </div>
        <img
          className={styles.HomepageImage}
          src={cdn("/images/newhomepage.png")}
          alt="Docteur en pharmacie"
        />
      </div>
      <div className={styles.AccessibilityLink}><u><a onClick={() => setShowAccessibility(true)}>{languages.accessibility_declaration}</a></u></div>
      {displayedVersion && (
        <div className={styles.Versioning}>V. {displayedVersion}</div>
      )}
      <GenericModal
        title={
          securedNavigationFor ? null : languages.modal_pharmaAccount_title
        }
        noFooter
        closable
        onClose={() => setModalPharmaAccountVisible(false)}
        visible={modalPharmaAccountVisible}
      >
        {securedNavigationFor ? (
          <SecurityToInsertReferent
            onFinish={() => {
              navigate(securedNavigationFor)
            }}
            onCancel={() => setSecuredNavigationFor(null)}
          />
        ) : (
          <Spacer direction="vertical">
            <Buttons>
              <Button onClick={() => navigate(TUTORIALS_PAGE)}>
                {languages.myTutorialVideos}
              </Button>
              <Button
                onClick={() => {
                  setSecuredNavigationFor(ADMIN_CARERS_PAGE)
                }}
              >
                {languages.companion}
              </Button>
            </Buttons>
            <div
              className={styles.Contact}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  languages.modal_pharmaAccount_information,
                  { USE_PROFILES: { html: true } }
                ),
              }}
            ></div>
          </Spacer>
        )}
      </GenericModal>
      <GenericModal visible={showAccessibility} footer={undefined} bodyStyle={{height: 600}}>
        <div>
          <div style={{textAlign: "right"}} onClick={() => setShowAccessibility(false)}>
          <PreviousButton
            text={languages.previous}
            onClick={() => setShowAccessibility(false)}
          />          </div>
          <div style={{marginTop: "50px"}}>
          <iframe src={`https://info.medadom.com/declaration-accessibilite-kiosk`} width="1700px" height="600px"/>
          </div>
        </div>
      </GenericModal>
    </>
  )
}

export default Home
