import { connect } from "react-redux"

import { Card } from "components/Card/Card"
import { Button } from "components/Button"
import { logout } from "containers/Login/services/actions"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Call } from "types/payload"
import { ClientStore, DocumentsStore } from "types/store"

import { DocumentType } from "client/documents/types"
import { actions } from "client/documents/actions"
import { Buttons } from "components/Card/Card"
import { hasDocuments, ModalDocument } from "client/documents/utils"
import { Information } from "components/Title/Title"
import PreviousButton from "components/Previous/PreviousButton"
import { Dispatcher, Message } from "types/redux"
import { Modal } from "components/Modal/Modal"
import { cdn } from "core/cdn"
import { useLanguage } from "locales"

interface FinaleProps {
  logout: () => void
  getDocuments: (callId: string, documentsType?: DocumentType[]) => void
  documentUrl?: string
  call?: Call
  message?: Message
}

const Finale: React.FC<FinaleProps> = (props): JSX.Element => {
  const { languages } = useLanguage()
  const [chosenCall, setChosenCall] = useState<Call>()
  const [disconnectModalVisible, setDisconnectModalVisible] =
    useState<boolean>(false)

  const logout = () => {
    props.logout()
  }

  const RenderFinal =
    props.call && hasDocuments(props.call) ? (
      <Card
        title={languages.finalePageTitle}
        message={props.message}
        imgUrl={cdn("/images/tlc_terminee_illustration.svg")}
      >
        <Information text={<>{languages.documentAvailableInfo}{languages.inTab}<b>&nbsp;{languages.documents}</b></>} />
        <Buttons>
          <Button onClick={() => setChosenCall(props.call)} type="primary">
            {languages.printDocument}
          </Button>
        </Buttons>
      </Card>
    ) : (
      <Card title={languages.noDocument}>
        <Buttons>
          <Button disabled={true} type="primary">
            {languages.printDocument}
          </Button>
          <Button onClick={logout} type="link">
            {languages.logout}
          </Button>
        </Buttons>
      </Card>
    )

  return (
    <>
      <PreviousButton text={languages.logout} reset={true} />
      {RenderFinal}
      <ModalDocument
        call={chosenCall}
        onCancel={logout}
        onAccept={() => {
          setChosenCall(undefined)
          setDisconnectModalVisible(true)
        }}
        acceptButtonProps={{ danger: true }}
        acceptText={languages.logout}
      />
      <Modal
        visible={disconnectModalVisible}
        title={languages.beSureToBeDisconnected}
        onCancel={() => {
          setDisconnectModalVisible(false)
          setChosenCall(props.call)
        }}
        cancelButtonProps={{ type: "primary" }}
        cancelText={languages.rePrint}
        acceptButtonProps={{ danger: true, type: "primary" }}
        acceptText={languages.finishAndDisconnect}
        onAccept={() => {
          logout()
        }}
      >
        <Information text={languages.beSureDocumentsArePrinted} />
      </Modal>
    </>
  )
}

const mapStateToProps = ({
  client,
  documents,
}: {
  client: Partial<ClientStore>
  documents: Partial<DocumentsStore>
}): Partial<FinaleProps> => {
  return {
    documentUrl: client.documentUrl,
    call: client.call,
    message: documents.message,
  }
}
const mapDispatchToProps = (dispatch: Dispatcher): FinaleProps => {
  return {
    logout: () => dispatch(logout()),
    getDocuments: (callId: string, documentsType?: DocumentType[]) =>
      dispatch(actions.request(callId, documentsType)),
  }
}

const FinalePage = Finale
export default connect(mapStateToProps, mapDispatchToProps)(FinalePage)
