// INSTALLATION
export const INSTALLATION_REQUEST = "INSTALLATION_REQUEST"
export const INSTALLATION_SUCCESS = "INSTALLATION_SUCCESS"
export const INSTALLATION_ERROR = "INSTALLATION_ERROR"

export const SET_ADMIN = "SET_ADMIN"
export const SET_CUSTOMER = "SET_CUSTOMER"

export const SET_RELATIVES = "SET_RELATIVES"
export const RELATIVES_REQUEST = "RELATIVES_REQUEST"
export const RELATIVES_SUCCESS = "RELATIVES_SUCCESS"
export const RELATIVES_ERROR = "RELATIVES_ERROR"

export const UPDATE_PROSPECT = "UPDATE_PROSPECT"

export const LOGGED_OUT = "LOGGEDOUT"
export const LOGGED_IN = "LOGGEDIN"

export const RESET_CLIENT = "RESET_CLIENT"
export const SET_CLIENT = "SET_CLIENT"

export const SET_CALL = "SET_CALL"
export const RESET_STORE = "RESET_STORE"
export const SAVE_NIR_READER_RESPONSE = "SAVE_NIR_READER_RESPONSE"
