import { Action, ActionWithoutPayload, Message } from "types/redux"
import { ReaderState } from "types/sesam"
import { NirReaderResponse } from "types/store"
import {
  CANCEL_NIR_READER,
  NIR_READER_RESPONSE,
  REQUEST_NIR_READER,
  RESET_NIR_READER,
  SET_NIR_READER_LOADING,
  SET_NIR_READER_MESSAGE,
  SET_NIR_READER_STATE,
} from "./constants"

export const nirReaderResponse = (
  payload: NirReaderResponse
): Action<NirReaderResponse> => {
  return { type: NIR_READER_RESPONSE, payload }
}

export const requestNirReader = (): ActionWithoutPayload => ({
  type: REQUEST_NIR_READER,
})

export const resetNirReader = (): ActionWithoutPayload => ({
  type: RESET_NIR_READER,
})

export const cancelNirReader = (): ActionWithoutPayload => ({
  type: CANCEL_NIR_READER,
})
export const setNirReaderLoading = (payload: boolean): Action<boolean> => ({
  type: SET_NIR_READER_LOADING,
  payload,
})
export const setNirReaderMessage = (payload: Message): Action<Message> => ({
  type: SET_NIR_READER_MESSAGE,
  payload,
})

export const setNirReaderState = (
  payload: ReaderState
): Action<ReaderState> => ({
  type: SET_NIR_READER_STATE,
  payload,
})
