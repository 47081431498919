import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import config from "react-global-configuration"
import IdleTimer from "react-idle-timer"

import { Card } from "components/Card/Card"
import { IdleModal } from "components/Modal/Modal"
import { cdn } from "core/cdn"
import withNetworkDetector from "hocs/withNetworkDetector"
import { dateDiff } from "lib/utils"
import { Call, CallState, Eta } from "types/payload"
import { CallStore } from "types/store"

import { Accepted } from "./Accepted"
import styles from "./Call.module.scss"
import Canceled from "./Canceled"
import Expired from "./Expired"
import { FifoWaiting } from "./FifoWaiting"
import { Hangup } from "./Hangup"
import { Waiting } from "./WaitingRoom"
import { useLanguage } from "locales"

const _IdleTimer = IdleTimer as any

const Render = (props: {
  call: Call
  loading: boolean
  createCall: () => void
  cancelCall: () => void
  logout: () => void
}) => {
  const { languages } = useLanguage()
  switch (props.call.state) {
    case CallState.EXPIRED:
      return <Expired createCall={props.createCall} logout={props.logout} />
    case CallState.ACCEPTED:
    case CallState.ACCEPTED_AND_SOON:
      const slot = new Date(props.call.start_date)
      const doctorName = `Dr ${props.call.doctor?.lastname}`
      return (
        <Accepted
          loading={props.loading}
          doctorName={doctorName}
          date={slot}
          callState={props.call.state}
          cancelCall={props.cancelCall}
        />
      )
    case CallState.FIFO_WAITING:
      return (
        <FifoWaiting
          loading={props.loading}
          eta={props.call.eta as Eta}
          cancelCall={props.cancelCall}
        />
      )
    case CallState.INITIALIZED:
    case CallState.WAITING_ROOM:
      const now: Date = new Date()
      const expiredDate: Date = new Date(props.call.expires_at)
      const diff = dateDiff(now, expiredDate)
      return (
        <Waiting
          loading={props.loading}
          remainingMinutes={diff.min || 0}
          cancelCall={props.cancelCall}
        />
      )
    case CallState.HUNGUP:
      return <Hangup />
    case CallState.CANCELLED_BY_DOCTOR:
    case CallState.CANCELLED_BY_PATIENT:
      return (
        <Canceled
          createCall={props.createCall}
          logout={props.logout}
          cancelState={props.call.state}
        />
      )

    default:
      return <>{languages.errorCallState}</>
  }
}

const img = (state: CallState) => {
  switch (state) {
    case CallState.INITIALIZED:
    case CallState.WAITING_ROOM:
      return cdn("images/temps_reponse_illustration.svg")
    case CallState.ACCEPTED:
    case CallState.FIFO_WAITING:
      return cdn("images/attente_illustration.svg")
    case CallState.ACCEPTED_AND_SOON:
      return cdn("images/attente_bientot_illustration.svg")
    case CallState.EXPIRED:
    case CallState.CANCELLED_BY_DOCTOR:
      return cdn("images/pas_de_medecin_illustration.svg")
    case CallState.CANCELLED_BY_PATIENT:
      return cdn("images/patient_annulation_illustration.svg")
    case CallState.HUNGUP:
      return cdn("images/envoi_document_illustration.svg")
    default:
      return ""
  }
}


const LobbyCall: React.FC<
  CallStore & {
    createCall: () => void
  }
> = (props) => {
  const { languages } = useLanguage()
  const [callState, setCallState] = useState<CallState>(props.call.state)
  const [isIdle, setIdle] = useState(false)
  const timeoutMinutes = config.get("timeoutInactivity.minutes") || 15

  const [pause, setPause] = useState(false)
  useEffect(() => {
    document.addEventListener("visibilitychange", function () {
      // clear Interval if user leave the tab
      if (document.hidden) {
        setPause(true)
      } else {
        setPause(false)
      }
    })
  }, [])

  useEffect(() => {
    if (
      props.call.state === CallState.ACCEPTED &&
      dayjs(props.call.start_date).diff(dayjs(), "minute") <= 5
    )
      setCallState(CallState.ACCEPTED_AND_SOON)
    else if (
      [CallState.INITIALIZED, CallState.WAITING_ROOM].includes(
        props.call.state
      ) &&
      props.call.eta
    )
      setCallState(CallState.FIFO_WAITING)
    else setCallState(props.call.state)
  }, [props.call])

  const subtitle = (state: CallState, call: Call) => {
    const doctorName = `Dr ${call.doctor?.lastname}`
  
    switch (state) {
      case CallState.CANCELLED_BY_DOCTOR:
        return languages.noBill
      case CallState.ACCEPTED_AND_SOON:
        return `${doctorName} ${languages.subtitleCallAcceptedAndSoon}`
      case CallState.ACCEPTED:
        return `${doctorName} ${languages.consultationScheduledAt}`
      case CallState.INITIALIZED:
      case CallState.WAITING_ROOM:
        return languages.weAreLookingForDoctors
  
      default:
        return undefined
    }
  }
  const title = (state: CallState) => {
    switch (state) {
      case CallState.EXPIRED:
        return languages.doctorBusy
      case CallState.HUNGUP:
        return null
      case CallState.CANCELLED_BY_DOCTOR:
        return languages.doctorCancelCall
      case CallState.CANCELLED_BY_PATIENT:
        return languages.youHaveCancelledCall
      case CallState.INITIALIZED:
      case CallState.WAITING_ROOM:
      default:
        return ""
    }
  }
  
  return (
    <div className={styles.LobbyCall} data-testid="LobbyCall">
      <Card
        title={title(callState)}
        titleSize={props.call.state === CallState.HUNGUP ? "small" : undefined}
        subtitle={subtitle(callState, props.call)}
        message={props.message}
        imgUrl={img(callState)}
        imgSize={
          [
            CallState.CANCELLED_BY_PATIENT,
            CallState.CANCELLED_BY_DOCTOR,
            CallState.EXPIRED,
          ].includes(callState)
            ? undefined
            : "large"
        }
      >
        <Render {...props} call={{ ...props.call, state: callState }} />
      </Card>

      {callState === CallState.EXPIRED ||
      callState === CallState.CANCELLED_BY_DOCTOR ||
      callState === CallState.CANCELLED_BY_PATIENT ? (
        <_IdleTimer
          timeout={1000 * 60 * timeoutMinutes}
          onIdle={() => {
            setIdle(true)
          }}
          pause={pause}
        />
      ) : null}
      <IdleModal
        visible={!pause && isIdle}
        onAccept={() => {
          setIdle(false)
        }}
        isIdle={isIdle}
      />
    </div>
  )
}

export default withNetworkDetector(LobbyCall) as any
