import { Space, SpaceProps } from "antd"
import React from "react"
import styles from "./Spacer.module.scss"

const Spacer: React.FC<SpaceProps> = ({ children, ...rest }) => {
  return (
    <Space {...rest} className={styles.Spacer}>
      {children}
    </Space>
  )
}

export default Spacer
