import { Action } from "types/redux"
import { CONNECT_ROOM } from "./constants"

export const connectRoom = (payload: {
  token: string
  roomName: string
}): Action<{ token: string; roomName: string }> => {
  return {
    type: CONNECT_ROOM,
    payload,
  }
}
