import { connect } from "react-redux"
import { Button } from "components/Button"
import { Buttons } from "components/Card/Card"
import { CallState } from "types/payload"
import { PaymentStore } from "types/store"
import React from "react"
import { useLanguage } from "locales"

const Canceled: React.FC<{
  loading?: boolean
  cancelState?: CallState.CANCELLED_BY_PATIENT | CallState.CANCELLED_BY_DOCTOR
  createCall: () => void
  logout: () => void
}> = ({ loading, createCall, logout }): JSX.Element => {
  const { languages } = useLanguage()
  return (
    <Buttons>
      <Button loading={loading} disabled={loading} onClick={createCall}>
        {languages.askAgain}
      </Button>
      <Button type="link" onClick={() => logout()}>
        {languages.logout}
      </Button>
    </Buttons>
  )
}

const mapStateToProps = ({ payment }: { payment: PaymentStore }) => {
  return { loading: payment.loading }
}

export default connect(mapStateToProps, null)(Canceled)
