import { cdn } from "core/cdn"
import { useState } from "react"
import styles from "./LanguageButton.module.scss"
import FatButton from "components/Button/FatButton/FatButton"
import { GenericModal } from "components/Modal/Modal"
import { useLanguage } from "locales"

const LanguagesSelector = {
  fr:{
    imgUrl: cdn("/images/flag_fr.png"),
    text: "Français",
    value: "fr",
    title: "FR"
  },
  en: {
    imgUrl: cdn("/images/flag_en.png"),
    text: "English",
    value: "en",
    title: "EN"
  }
}

const FlagButton=()=> {
  const { languages, changeLanguage, currentLang} = useLanguage()
  const [open, setOpen] = useState(false)
  const handleLanguageChange = (lang: string) => {
    changeLanguage(lang)
    setOpen(false)
  };

  return (
    <>
    <div className={styles.LanguageSelectorFrame} role="button" onClick={()=>setOpen(true)}>
    <img width="38px" src={LanguagesSelector[currentLang].imgUrl} alt="flag" />
    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "50px", gap:-5}}>
      <div style={{margin: "auto", fontSize: '18px', color: "#211452", textAlign: "center", fontWeight: "600", height: "40%"}}>{LanguagesSelector[currentLang].title}</div>
      <div><img width="12px" src={cdn("/images/Chevron.png")} alt="" style={{margin: 0, padding: 0}} /></div>
      </div>
    </div>
    {open &&
    <GenericModal
            onClose={()=>setOpen(false)}
            closable
            visible={open}
            noFooter={true}
            style={{ width: "70vw"}}
            onCancel={()=>setOpen(false)}
            title={
            <div className={styles.title__block}>
             <h1 className={styles.title__text}>{languages.chooseALanguage}</h1>
            </div>
            }
          >
        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", width: "850px", margin: "auto", height: "500px"}}>
        {Object.values(LanguagesSelector).map(lang =>
          <FatButton
              clicked={lang.value === currentLang}
              onClick={() => {
                handleLanguageChange(lang.value)
              }}
              key={lang.value}
              img={lang.imgUrl}
            >
              {lang.text}
          </FatButton>
        )}
        </div>
      </GenericModal>}
    </>
  
  )
}

export default FlagButton;