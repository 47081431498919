import carer from "containers/InsertCarer/services/reducer"
import { Reducer } from "react"
import { combineReducers } from "redux"

import configuration from "client/config/reducer"
import documents from "client/documents/reducer"
import { RESET_STORE } from "client/services/constants"
import client from "client/services/reducer"
import socket from "client/socket/reducer"
import nirReader from "components/NirReader/services/reducer"
import admin from "containers/Admin/services/reducer"
import call from "containers/Call/services/reducer"
import doctors from "containers/Doctors/services/reducer"
import insertRelative from "containers/InsertRelative/services/reducer"
import login from "containers/Login/services/reducer"
import nir from "containers/NirAndExemption/services/reducer"
import payment from "containers/Payment/services/reducer"
import profile from "containers/Profile/services/reducer"
import resetPassword from "containers/ResetPassword/services/reducer"
import signup from "containers/Signup/services/reducer"
import statistics from "containers/Statistics/services/reducer"
import stripe from "containers/Stripe/services/reducer"
import survey from "containers/Survey/services/reducer"
import upload from "containers/Upload/services/reducer"
import verifyPhone from "containers/VerifyPhone/services/reducer"
import { Action, ActionWithoutPayload } from "types/redux"
import { GlobalStore } from "types/store"

const createRootReducers = (
  routeReducer: any
): Reducer<GlobalStore, Action<unknown> | ActionWithoutPayload> =>
  combineReducers<GlobalStore>({
    nirReader,
    payment,
    survey,
    insertRelative,
    client,
    admin,
    login,
    profile,
    socket,
    signup,
    call,
    statistics,
    stripe,
    verifyPhone,
    resetPassword,
    nir,
    documents,
    doctors,
    configuration,
    router: routeReducer,
    upload,
    carer,
  })

const RootReducer =
  (routeReducer: any) =>
  (
    state: GlobalStore | undefined,
    action: Action<unknown> | ActionWithoutPayload
  ): GlobalStore => {
    let initialState = null
    let createdState = state as GlobalStore
    if (action.type === RESET_STORE) {
      const { admin } = state?.client || { admin: undefined }
      const { router, socket, configuration, survey } = state || {
        router: undefined,
        socket: undefined,
      }
      initialState = {
        client: { admin, success: true, loading: false },
        socket,
        router,
        configuration,
        survey,
      }
      createdState = initialState as GlobalStore
    }

    return createRootReducers(routeReducer)(createdState, action)
  }

export default RootReducer
