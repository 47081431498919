import {
  INIT_DOCUMENT_LOAD,
  SUBMIT_DOCUMENT_SUCCESS,
  SUBMIT_DOCUMENT_REQUEST,
  RESET_UPLOAD_STORE,
  RESET_UPLOAD_STATUS,
  SEND_ME_UPLOAD_LINK_REQUEST,
  QR_CODE_LINK_SUCCESS, 
  SEND_ME_UPLOAD_LINK_VIA_SMS_REQUEST, 
  UPLOAD_LINK_VIA_SMS_REQUEST_SUCCESS,
  FETCH_NEW_DOCUMENT_REQUEST, 
  NEW_DOCS_TRUE,
  GO_BACK_TO_SURVEY
} from "./constants"

import { UploadedDocumentType } from "../../Survey/types"
import { Action, ActionWithoutPayload} from "types/redux"
import {UploadLink} from "../../../types/store"
interface QrCodeAction {
  type: string
  link: UploadLink
}

const actions = {
  goBackToSurvey : (): ActionWithoutPayload => ({ type: GO_BACK_TO_SURVEY }),
  reset: (): ActionWithoutPayload => ({ type: RESET_UPLOAD_STORE }),
  generateQrCode: (): ActionWithoutPayload => ({ type: SEND_ME_UPLOAD_LINK_REQUEST }),
  QrcodeLinkSuccess: (link): Action<QrCodeAction> => ({ type: QR_CODE_LINK_SUCCESS, payload: link }),
  SendLinkViaSMS: (link): Action<string>  => ({ type: SEND_ME_UPLOAD_LINK_VIA_SMS_REQUEST, payload: link   }),
  linkViaSmsSuccess: (): ActionWithoutPayload => ({ type: UPLOAD_LINK_VIA_SMS_REQUEST_SUCCESS }),

  resetStatus: (): ActionWithoutPayload => ({ type: RESET_UPLOAD_STATUS }),
  init: (): ActionWithoutPayload => ({ type: INIT_DOCUMENT_LOAD }),
  submitDocument: (Doc: UploadedDocumentType): Action<UploadedDocumentType> => ({
    type: SUBMIT_DOCUMENT_REQUEST,
    payload: {...Doc},
  }),
  fetchNewDocuments: (link_age):Action<number> =>({
    type: FETCH_NEW_DOCUMENT_REQUEST, 
    payload: link_age,
  }),
  newDocsAreAvailable: (num): Action<number> =>({
    type: NEW_DOCS_TRUE,
    payload: num,
  }),
  success: (): ActionWithoutPayload => ({ type: SUBMIT_DOCUMENT_SUCCESS }),
}

export default actions