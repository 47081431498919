import Compliance from "lib/api-compliance"
import { Admin, Customer, Prospect, Relative } from "types/entity"
import { Call, CustomerApi, RelativeApi } from "types/payload"
import { Action, Message } from "types/redux"
import { NirReaderResponse } from "types/store"
import {
  INSTALLATION_REQUEST,
  INSTALLATION_SUCCESS,
  INSTALLATION_ERROR,
  SET_ADMIN,
  SET_CUSTOMER,
  SET_RELATIVES,
  SET_CALL,
  UPDATE_PROSPECT,
  RESET_CLIENT,
  LOGGED_IN,
  LOGGED_OUT,
  SAVE_NIR_READER_RESPONSE,
} from "./constants"

const actions = {
  installation: { type: INSTALLATION_REQUEST },

  success: { type: INSTALLATION_SUCCESS },

  error: (error: string): Action<Message> => ({
    type: INSTALLATION_ERROR,
    payload: { type: "error", text: error || "error " },
  }),

  // birhdate from API = yyyy-mm-dd
  setRelatives: (relatives: RelativeApi[]): Action<Relative[]> => ({
    type: SET_RELATIVES,
    payload: relatives.map((relative) => ({
      ...(new Compliance<RelativeApi, Relative>(relative).please() as Relative),
    })),
  }),

  setCustomer: (customer?: CustomerApi): Action<Customer | undefined> => {
    return {
      type: SET_CUSTOMER,
      payload: customer
        ? new Compliance<CustomerApi, Customer>(customer).please()
        : undefined,
    }
  },

  setAdmin: (admin?: Admin): Action<Admin | undefined> => ({
    type: SET_ADMIN,
    payload: admin,
  }),

  setCall: (call?: Call): Action<Call | undefined> => ({
    type: SET_CALL,
    payload: call,
  }),

  putProspect: (prospect: Partial<Prospect>): Action<Partial<Prospect>> => ({
    type: UPDATE_PROSPECT,
    payload: prospect,
  }),

  loggedIn: (patient: CustomerApi): Action<Customer> => ({
    type: LOGGED_IN,
    payload: new Compliance<CustomerApi, Customer>(
      patient
    ).please() as Customer,
  }),

  saveNirReaderResponse: (
    nirReaderResponse: NirReaderResponse
  ): Action<NirReaderResponse> => ({
    type: SAVE_NIR_READER_RESPONSE,
    payload: nirReaderResponse,
  }),
  loggedOut: { type: LOGGED_OUT },

  reset: {
    type: RESET_CLIENT,
  },
}

export default actions
