import React, { useEffect, useRef } from "react"
import * as THREE from "three"
import pattern_IMG from "./assets/pattern.png"
import pattern_back_IMG from "./assets/pattern-b.png"
import face_IMG from "./assets/face.png"
import face2_IMG from "./assets/face2.png"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import styles from "./AnimatedLogo.module.scss"

const AnimatedLogo: React.FC<{ width: number; height: number }> = (
  props
): JSX.Element => {
  const scene = new THREE.Scene()
  const mount = useRef<HTMLDivElement>(null)

  const camera = new THREE.PerspectiveCamera(
    50,
    props.width / props.height,
    1,
    1000
  )
  const renderer = new THREE.WebGLRenderer({ alpha: true })
  renderer.setClearColor(0x000000, 0)

  useEffect(() => {
    if (mount.current) mount.current.appendChild(renderer.domElement)
  }, [])

  useEffect(() => {
    renderer.setSize(props.width, props.height)
    renderer.setPixelRatio()

    const sphere_back = new THREE.Mesh(
      new THREE.SphereGeometry(10),
      new THREE.MeshBasicMaterial({
        wireframe: false,
        color: 0xffffff,
        side: THREE.BackSide,
        transparent: true,
        map: new THREE.TextureLoader().load(pattern_IMG),
      })
    )
    const sphere_front = new THREE.Mesh(
      new THREE.SphereGeometry(10),
      new THREE.MeshBasicMaterial({
        color: 0xffffff,
        wireframe: false,
        transparent: true,
        side: THREE.FrontSide,
        map: new THREE.TextureLoader().load(pattern_back_IMG),
      })
    )
    scene.add(sphere_back, sphere_front)

    const doctor_face = new THREE.Mesh(
      new THREE.SphereGeometry(3, 32, 32),
      new THREE.MeshBasicMaterial({
        map: new THREE.TextureLoader().load(face2_IMG),
      })
    )
    const patient_face = new THREE.Mesh(
      new THREE.SphereGeometry(3, 32, 32),
      new THREE.MeshBasicMaterial({
        map: new THREE.TextureLoader().load(face_IMG),
      })
    )
    scene.add(doctor_face, patient_face)

    sphere_front.material.opacity = sphere_back.material.opacity = 1
    doctor_face.position.x = 0
    doctor_face.position.z = -5
    patient_face.position.x = 0
    patient_face.position.z = 5
    patient_face.position.y = doctor_face.position.y = 11
    camera.position.z = 40
    const controls = new OrbitControls(camera, renderer.domElement)
    controls.update()

    const animate = function () {
      requestAnimationFrame(animate)
      sphere_back.rotation.y -= 0.01
      sphere_front.rotation.y -= 0.01
      const quaternion = new THREE.Quaternion()
      quaternion.setFromAxisAngle(new THREE.Vector3(0, 1, 0).normalize(), -0.01)
      doctor_face.position.applyQuaternion(quaternion)
      patient_face.position.applyQuaternion(quaternion)
      controls.update()

      renderer.render(scene, camera)
    }

    renderer.render(scene, camera)
    animate()
  })

  return <div className={styles.AnimatedLogo} ref={mount} />
}

export default AnimatedLogo
