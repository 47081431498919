import {
  UPLOAD_STEPS,
} from "../models"
import { UploadDocStore } from "types/store"
import {
  INIT_DOCUMENT_LOAD,
  RESET_UPLOAD_STORE,
  ADD_DOCUMENTS_TO_STORE,
  SUBMIT_DOCUMENT_ONGOING,
  SUBMIT_DOCUMENT_SUCCESS,
  SUBMIT_DOCUMENT_FAILURE,
  STATUS_RESOLVED,
  STATUS_REJECTED,
  STATUS_PENDING,
  RESET_UPLOAD_STATUS,
  QR_CODE_LINK_SUCCESS,
  UPLOAD_LINK_VIA_SMS_REQUEST_SUCCESS,
  NEW_DOCS_TRUE,
  GO_BACK_TO_SURVEY,
} from "./constants"


const MANAGER: Partial<UploadDocStore> = {
  steps: UPLOAD_STEPS,
  loading: false,
  documents: [],
  status: undefined,
  link: undefined,
  MobileUpload:  {STATUS:undefined ,
  NumberOfDocs: 0},
  surveyLost : false,
}



const reset = (manager: Partial<UploadDocStore>): Partial<UploadDocStore> => {
  return init({ ...manager})
}


const init = (manager = MANAGER): Partial<UploadDocStore> => {
  localStorage.removeItem("NDC")
  localStorage.removeItem("NDR")
  return {
    ...manager,
  } as UploadDocStore
}

const Reducer = (state = init(MANAGER), action: any) 
:Partial<UploadDocStore> => {

  const documentReceived = (state :Partial<UploadDocStore>) => {
    return {
      ...state,
      documents: action.payload
    } as UploadDocStore
  }

  const SubmitDocumentRequested = (state :Partial<UploadDocStore>) => {
    return  {
      ...state,
      loading: true,
      status: STATUS_PENDING
    } as UploadDocStore
  }

  const SubmitDocumentSuccess = (state :Partial<UploadDocStore>) => {
    return  {
      ...state,
      loading: false,
      status: STATUS_RESOLVED
    } as UploadDocStore
  }

  const SubmitDocumentFail = (state :Partial<UploadDocStore>) => {
    return  {
      ...state,
      loading: false,
      status: STATUS_REJECTED
    } as UploadDocStore
  }

  const resetStatus = (state :Partial<UploadDocStore>) => {
    return  {
      ...state,
      loading: false,
      status: undefined,
      MobileUpload:  {STATUS:undefined ,
        NumberOfDocs: 0},
    } as UploadDocStore
  }

  const newDocUploadedFromMobile = (state :Partial<UploadDocStore>)=>{
    const mobileUpload = {STATUS:"finished" ,
    NumberOfDocs: action.payload}

    return  {
      ...state,
      MobileUpload: mobileUpload
    } as UploadDocStore
  }
  const serveQrcodeLink = (state :Partial<UploadDocStore>) => {
        return  {
          ...state,
          loading: false,
          link: action.payload
        } as UploadDocStore
  }

  const linkSendViaSMS = (state :Partial<UploadDocStore>) => {
    const NewLink = {...state.link, SendBySms :true}
    return  {
      ...state,
      loading: false,
      link: NewLink
    } as UploadDocStore
}

  switch (action.type) {
    case QR_CODE_LINK_SUCCESS :
      return serveQrcodeLink({...state })
    case UPLOAD_LINK_VIA_SMS_REQUEST_SUCCESS:
      return linkSendViaSMS({...state})
    case NEW_DOCS_TRUE : 
      return newDocUploadedFromMobile({...state});
    case ADD_DOCUMENTS_TO_STORE:
        return documentReceived({ ...state })
    case SUBMIT_DOCUMENT_ONGOING:
       return SubmitDocumentRequested({ ...state }) ;
    case SUBMIT_DOCUMENT_SUCCESS:
        return SubmitDocumentSuccess({ ...state }) ;
    case SUBMIT_DOCUMENT_FAILURE :
        return SubmitDocumentFail({ ...state }) ;
    case INIT_DOCUMENT_LOAD:
      return init({ ...state } as Partial<UploadDocStore>)
    case RESET_UPLOAD_STORE:
        return reset({ ...state } as Partial<UploadDocStore>)
    case RESET_UPLOAD_STATUS:
      return resetStatus({ ...state })
    case GO_BACK_TO_SURVEY: 
      return  {
        ...state,
        surveyLost: true
      } as UploadDocStore
    default:
      return state
  }
}
  
export default Reducer