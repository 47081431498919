import { cdn } from "core/cdn"
import { languages } from "locales/languages"
import { VideoProps } from "types/props"

const _01 = "/images/tuto/01.jpg"
const _02 = "/images/tuto/02.jpg"
const _03 = "/images/tuto/03.jpg"
const _04 = "/images/tuto/04.jpg"
const _05 = "/images/tuto/05.jpg"
const _06 = "/images/tuto/06.jpg"
const _07 = "/images/tuto/07.jpg"
const _08 = "/images/tuto/08.jpg"
const _09 = "/images/tuto/09.jpg"
const _10 = "/images/tuto/10.jpg"
const _11 = "/images/tuto/11.jpg"
const _12 = "/images/tuto/12.jpg"
const _13 = "/images/tuto/13.jpg"
const _14 = "/images/tuto/14.jpg"
const _15 = "/images/tuto/15.jpg"
const _16 = "/images/tuto/16.jpg"
const _17 = "/images/tuto/17.jpg"
const _Full = "/images/tuto/full_tuto_img.jpg"

const _video_01 = "/videos/tuto/01.mp4"
const _video_02 = "/videos/tuto/02.mp4"
const _video_03 = "/videos/tuto/03.mp4"
const _video_04 = "/videos/tuto/04.mp4"
const _video_05 = "/videos/tuto/05.mp4"
const _video_06 = "/videos/tuto/06.mp4"
const _video_07 = "/videos/tuto/07.mp4"
const _video_08 = "/videos/tuto/08.mp4"
const _video_09 = "/videos/tuto/09.mp4"
const _video_10 = "/videos/tuto/10.mp4"
const _video_11 = "/videos/tuto/11.mp4"
const _video_12 = "/videos/tuto/12.mp4"
const _video_13 = "/videos/tuto/13.mp4"
const _video_14 = "/videos/tuto/14.mp4"
const _video_15 = "/videos/tuto/15.mp4"
const _video_16 = "/videos/tuto/16.mp4"
const _video_17 = "/videos/tuto/17.mp4"
const _video_Full = "/videos/tuto/full.mp4"

const tutorialAssets = (): {
  fullVideo: VideoProps
  chapterVideos: VideoProps[]
} => ({
  fullVideo: {
    video: cdn(_video_Full),
    poster: cdn(_Full),
    title: "01 Introduction",
  },
  chapterVideos: [
    {
      video: cdn(_video_01),
      poster: cdn(_01),
      title: "01 Introduction",
    },
    {
      video: cdn(_video_02),
      poster: cdn(_02),
      title: "02 Inscription patient",
    },
    {
      video: cdn(_video_03),
      poster: cdn(_03),
      title: "03 Inscription sans carte",
    },
    {
      video: cdn(_video_04),
      poster: cdn(_04),
      title: "04 Contre-indications",
    },
    {
      video: cdn(_video_05),
      poster: cdn(_05),
      title: "05 Documents médicaux",
    },
    {
      video: cdn(_video_06),
      poster: cdn(_06),
      title: "06 Questions fréquentes/usage de la borne",
    },
    {
      video: cdn(_video_07),
      poster: cdn(_07),
      title: "07 Objets connectés",
    },
    {
      video: cdn(_video_08),
      poster: cdn(_08),
      title: "08 Objets non connectés",
    },
    {
      video: cdn(_video_09),
      poster: cdn(_09),
      title: "09 Questions fréquentes/outils connectés",
    },
    {
      video: cdn(_video_10),
      poster: cdn(_10),
      title: "10  Partie supérieure",
    },
    {
      video: cdn(_video_11),
      poster: cdn(_11),
      title: "11 Partie inférieure",
    },
    {
      video: cdn(_video_12),
      poster: cdn(_12),
      title: "12 Autres symptômes",
    },
    {
      video: cdn(_video_13),
      poster: cdn(_13),
      title: "13 Les contre-indications",
    },
    {
      video: cdn(_video_14),
      poster: cdn(_14),
      title: "14 Questions fréquentes/Contre indications",
    },
    {
      video: cdn(_video_15),
      poster: cdn(_15),
      title: "15 Nettoyage",
    },
    {
      video: cdn(_video_16),
      poster: cdn(_16),
      title: "16 Gestes barrières",
    },
    {
      video: cdn(_video_17),
      poster: cdn(_17),
      title: "17 Conclusion",
    },
  ],
})

export const items = [
  {
    key: '1',
    label: languages.StartWithTheTerminal,
    videoList: [{
      title: languages.WorkWithMedadom,
      description: `${languages.Duration} : 5mn 44s`,
      url: cdn("/videos/tutos/comment_travailler_avec_medadom.mp4"),
      cover: cdn("/videos/tutos/comment_travailler_avec_medadom.png")
    },
    {
      title: languages.TerminalOperation,
      description: `${languages.Duration} : 3mn 27s`,
      url: cdn("/videos/tutos/fonctionnement_du_terminal.mp4"),
      cover: cdn("/videos/tutos/fonctionnement_du_terminal.png")
    },
    {
      title: languages.ClientParcours,
      description: `${languages.Duration} : 5mn 37s`,
      url: cdn("/videos/tutos/parcours_patient.mp4"),
      cover: cdn("/videos/tutos/parcours_patient.png")
    }
  ],
  },
  {
    key: '2',
    label: languages.Inscription,
    videoList: [{
      title: languages.ClientInscription,
      description: `${languages.Duration} : 2mn 16s`,
      url: cdn("/videos/tutos/inscription_du_patient.mp4"),
      cover: cdn("/videos/tutos/inscription_du_patient.png")
    }],
  },
  {
    key: '3',
    label: languages.ToolsUseCase,
    videoList: [{
      title: languages.TheToolsUseCase,
      description: `${languages.Duration} : 3mn 42s`,
      url: cdn("/videos/tutos/l_utilisation_des_outils.mp4"),
      cover: cdn("/videos/tutos/l_utilisation_des_outils.png")
    }],
  },
  {
    key: '4',
    label: languages.UseCases,
    videoList: [{
      title: languages.TheUseCases,
      description: `${languages.Duration} : 1mn 46s`,
      url: cdn("/videos/tutos/les_cas_d_usage.mp4"),
      cover: cdn("/videos/tutos/les_cas_d_usage.png")
    }],
  },
  {
    key: '5',
    label: languages.ContraIndications,
    videoList: [{
      title: languages.TheContraIndications,
      description: `${languages.Duration} : 1mn 21s`,
      url: cdn("/videos/tutos/les_contre_indications.mp4"),
      cover: cdn("/videos/tutos/les_contre_indications.png")
    }],
  },
  {
    key: '6',
    label: languages.HygieneProcedures,
    videoList: [{
      title: languages.TheHygieneProcedure,
      description: `${languages.Duration} : 1mn 24s`,
      url: cdn("/videos/tutos/La_procedure_dhygiene.mp4"),
      cover: cdn("/videos/tutos/La_procedure_dhygiene.png")
    }],
  }
];

export default tutorialAssets
