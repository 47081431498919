import config from "react-global-configuration"
import classNames from "classnames"
import { first } from "lodash"
import { useNavigate } from "react-router-dom"
import { connect } from "react-redux"
import { Dispatcher } from "types/redux"
import { GlobalStore } from "types/store"
import Loader from "components/Loaders/Loader/Loader"
import { SURVEY_PAGE } from "core/constants"
import { Button } from "components/Button"
import { UiCarousel } from "components/Carousel/Index"
import PreviousButton from "components/Previous/PreviousButton"
import { Steps } from "components/Steps"
import { Buttons, Card } from "components/Card/Card"
import { ZoomInOutlined } from "@ant-design/icons"
import {
  DocType,
  Reply,
  Step,
  UploadDocProps,
  UploadedDocumentType,
  Value,
} from "../Survey/types"
import { DocTypeToName } from "../Survey/utils"
import actions from "./services/actions"
import { useCallback, useEffect, useRef, useState } from "react"
import { cdn } from "core/cdn"
import styles from "./Upload.module.scss"
import Webcam from "react-webcam"
import { PATH, UPLOAD_STEPS } from "./models"
import { Keyboard } from "hocs/withKeyboard"
import { Input } from "components/Input"
import {
  SMS_LINK,
  STATUS_PENDING,
  STATUS_REJECTED,
  STATUS_RESOLVED,
  WEBCAM,
} from "./services/constants"
import QRCode from "react-qr-code"
import { getMediasVideoChat } from "containers/Call/utils"
import { openNotification } from "components/Message/Message"
import { GenericModal } from "components/Modal/Modal"
import { ModalProps } from "types/props"
import { useLanguage } from "locales"
import { languages } from "locales/languages"

export interface uploadedDocObject {
  medicalType?: Value
  name?: string
  relativeId?: string
  file?: DocType[]
}

export const WebOrMObileModal: React.FC<ModalProps> = (props): JSX.Element => {
  const { languages } = useLanguage()
  const { onAccept, onCancel } = props

  const TitleBlock = (
    <div className={styles.title__block}>
      <div className={styles.title__icon}>
        <img src={cdn("images/warning_picto.svg")} alt="warning image" />
      </div>
      <h1 className={styles.title__text}>{languages.UDModalText}</h1>
    </div>
  )

  return (
    <GenericModal
      footer={
        <Buttons direction="vertical" style={{ paddingTop: 0, marginTop: 0 }}>
          <Button onClick={onAccept} wide="long" type="primary">
            {languages.UDModalAcceptQRcode}
          </Button>
          <Button onClick={onCancel} type="link" size="small" danger>
            {languages.UDModalIgnoreQRcode}
          </Button>
        </Buttons>
      }
      {...props}
      bodyStyle={{ padding: 0 }}
      title={TitleBlock}
    />
  )
}


const QrCodePage = (props: any): JSX.Element => {
  const { loading, status, current, link, handleCannotScan } = props
  const arrayStep = UPLOAD_STEPS.map((step) => step.id)
  const currentStep: string = first(current.id.split(":"))
  const navigate = useNavigate()

  const PENDING = loading && status === STATUS_PENDING

  return PENDING ? (
    <Loader />
  ) : (
    <>
      <Steps array={arrayStep} current={currentStep} />
      <PreviousButton onClick={()=>navigate(SURVEY_PAGE)} />
      <Card>
        <h1 className={styles.TitleCustom}>{languages.uDocScanQrCode}<span style={{textDecoration: "underline"}}>{languages.yourPhonesCamera}</span>{languages.thenFollowTheSteop}</h1>
        <div
          style={{
            height: "auto",
            margin: "0 auto",
            maxWidth: 400,
            width: "100%",
          }}
        >
          {link && <QRCode
            size={256}
            style={{ height: "auto", width: "100%", margin: "auto" }}
            value={link.url}
            viewBox={`0 0 256 256`}
          />}
        </div>
        <Buttons style={{ paddingTop: 30 }}>
          <div
            onClick={(reply) => handleCannotScan(reply)}
            className={styles.webOrMobileButton}
          >
            {current.defaultReply.title}
          </div>
        </Buttons>
      </Card>
    </>
  )
}

const MobileOrBornPage = (props: any): JSX.Element => {
  const { current, goToStep, handleMobileOrBornResponse } = props
  const arrayStep = UPLOAD_STEPS.map((step) => step.id)
  const currentStep: string = first(current.id.split(":"))

  const [showUploadRecommendedModal, setShowUploadRecommendedModal] =
    useState(false)

  const handleUploadModalAccept = () => {
    setShowUploadRecommendedModal(false)
    goToStep(PATH.Qrcode)
  }

  const handleUploadModalCancel = () => {
    setShowUploadRecommendedModal(false)
    handleMobileOrBornResponse(current.replies[1] as Reply)
  }

  return (
    <>
      <Steps array={arrayStep} current={currentStep} />
      <PreviousButton
        onClick={() => goToStep(PATH.Qrcode)}
      />
      <Card
        title={current.title}
        subtitle={current.subtitle}
        imgUrl={current.imgUrl}
      >
        <div
          className={styles.UploadFatButton}
          onClick={() => handleMobileOrBornResponse(current.replies[0] as Reply)}
        >
          <div className={styles.UploadFatButton__picto}>
            <img src={cdn("images/notifications_phone.svg")} alt="" />
          </div>
          <span style={{fontSize: "28px", fontWeight: 600}}>
            {current.replies[0].title}{" "}
          </span>
        </div>
        <Buttons style={{ paddingTop: 10, display: "flex" }}>
          <div
            onClick={() => setShowUploadRecommendedModal(true)}
            className={styles.webOrMobileButton}
          >
            {current.replies[1].title}{" "}
          </div>
        </Buttons>

        <WebOrMObileModal
          onAccept={handleUploadModalAccept}
          onCancel={handleUploadModalCancel}
          visible={showUploadRecommendedModal}
        />
      </Card>
    </>
  )
}

const PreviewModal = (props: any): JSX.Element => {
  const { caption, src, open, handleClose } = props

  return (
    <>
      {open && (
        <div id="myModal" className={styles.previewModal}>
          <span className={styles.previewClose} onClick={handleClose}>
            {" "}
            &times;
          </span>
          <img className={styles.modalContent} src={src} />
          <div id={styles.caption}>{caption}</div>
        </div>
      )}
    </>
  )
}

const GetReadyPage = (props: any): JSX.Element => {
  const { current, onSelect, goToStep } = props
  const arrayStep = UPLOAD_STEPS.map((step) => step.id)
  const currentStep: string = first(current.id.split(":"))
  return (
    <>
      <Steps array={arrayStep} current={currentStep} />
      <PreviousButton onClick={() => goToStep(PATH.SmsOrBorn)} />
      <Card title={current.title} subtitle={""} imgUrl={""}>
        <div className={styles.GetReadyPageContainer}>
          <div className={styles.getReadyImgSide}>
            <img
              src={cdn("images/pas_bonne_capture.svg")}
              alt="mauvaise capture exemple"
            />
          </div>
          <div className={styles.getReadyImgMiddle}>
            <img
              src={cdn("images/cadre_capture.svg")}
              alt="Bonne capture exemple"
            />
          </div>
          <div className={styles.getReadyImgSide}>
            <img
              src={cdn("images/bonne_capture.svg")}
              alt="Bonne capture exemple"
            />
          </div>
        </div>

        <Buttons style={{ paddingTop: 30 }}>
          <Button onClick={onSelect} type="primary">
            {current.replies[0].title}
          </Button>
        </Buttons>
      </Card>
    </>
  )
}

const IamReadyPage = (props: any): JSX.Element => {
  const { current, previousStep, onSelect } = props
  const arrayStep = UPLOAD_STEPS.map((step) => step.id)
  const currentStep: string = first(current.id.split(":"))

  return (
    <>
      <Steps array={arrayStep} current={currentStep} />
      <PreviousButton onClick={() => previousStep(current)} />
      <Card title={current.title} subtitle={""} imgUrl={""}>
        <div className={styles.IamReady}>
          <img
            className={styles.IamReadyImage}
            src={current.imgUrl}
            alt="Personne positionné devant la borne"
          />
        </div>

        <Buttons style={{ paddingTop: 30 }}>
          <Button onClick={onSelect} type="primary">
            {current.replies[0].title}
          </Button>
        </Buttons>
      </Card>
    </>
  )
}

export const Timer = (props): JSX.Element => {
  const { timer } = props
  return (
    <>
      {timer === 0 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <span className={styles.ScreenShotOnGoing}>
            Prise de photos en cours...
          </span>
          <div className={styles.UploadLoader}></div>
        </div>
      ) : (
        <div className={styles.Timer}>
          <img
            src={cdn("images/devices/tutorial_device/waiting.svg")}
            alt="horloge"
          />
          <span className="">{timer}</span>
        </div>
      )}
    </>
  )
}

const ShooterPage = (props: any): JSX.Element => {
  const TIMER_PER_STEP_IN_SEC = config.get("timeout.upload_doc_timer")
  const { current, previousStep, goToStep, count, getImage } = props
  const arrayStep = UPLOAD_STEPS.map((step) => step.id)
  const currentStep: string = first(current.id.split(":"))
  const [timer, setTimer] = useState<number>(TIMER_PER_STEP_IN_SEC)
  const [videoDeviceID, setVideoDeviceID] = useState("")
  const [webcamError, setWebcamError] = useState(false)
  const [onStream, setOnStream] = useState(false)
  const videoRef = useRef(null)

  const capture = useCallback(() => {
    return videoRef.current.getScreenshot()
  }, [videoRef])

  const shootHandler = () => {
    const instant = setInterval(func, 666)
    let myList = []
    function func() {
      if (myList.length === 3) {
        getImage(myList)
        clearInterval(instant)
      } else {
        const image = capture()
        myList.push(image)
      }
    }
  }

  const videoConstraints = {
    aspectRatio: 0.6666666667,
  }

  const onUserMedia = (stream) => {
    if (stream.active) {
      setOnStream(true)
      console.log("Stream is active and ready to shoot!")
    }
  }

  const handleUserMediaError = (error) => {
    if (error) {
      setWebcamError(true)
      console.error(error, {
        route: "Upload::handleUserMediaError",
      })
    }
  }

  navigator.mediaDevices.ondevicechange = (event) => {
    console.log("Device change", event)
    sendMediaDeviceNotification()
  }

  const sendMediaDeviceNotification = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(() => {
        setOnStream(true)
        setWebcamError(false)
      })
      .catch(function (e) {
        console.warn("Votre camera n'est pas activée", e)
        setOnStream(false)
      })
  }

  const getVideoDevice = async () => {
    const devices = await getMediasVideoChat()
    const videoDevices = devices.filter(
      (device) => device.kind === "videoinput"
    )
    return videoDevices
  }

  useEffect(() => {
    const getVideoDeviceID = async () => {
      const videoDevices = await getVideoDevice()
      if (videoDevices) {
        setVideoDeviceID(videoDevices[0].deviceId)
      }
    }
    getVideoDeviceID()
  }, [])

  useEffect(() => {
    const checkVideoDevice = async () => {
      const videoDevices = await getVideoDevice()
      if (!videoDevices) {
        console.warn("Webcam device not available")
        sendMediaDeviceNotification()
      }
      setVideoDeviceID(videoDevices[0].deviceId)
      console.warn("Webcam device found", videoDevices)
    }
    if (webcamError || !onStream) {
      checkVideoDevice()
    }
  }, [webcamError, onStream])

  useEffect(() => {
    if (!webcamError && onStream) {
      const interval = setInterval(() => {
        if (timer === 0) {
          shootHandler()
          return clearInterval(interval)
        }
        setTimer((timer) => timer - 1)
      }, 1000)
      return () => clearInterval(interval)
    }
  }, [timer, shootHandler, webcamError, onStream])

  return (
    <>
      <Steps array={arrayStep} current={currentStep} />
      <PreviousButton onClick={() => previousStep(current)} />
      {webcamError ? (
        <WebcamUnavailablePage />
      ) : (
        <div className={styles.shooterPage}>
          <div className={styles.container}>
            <div className={styles.sideImage}>
              <Timer {...{ timer }} />
              <img
                src={cdn("images/illustration_base.svg")}
                alt="illustration de la position de capture"
              />
            </div>

            <div id={styles.ShooterContainer}>
              <Webcam
                audio={false}
                ref={videoRef}
                screenshotFormat="image/png"
                width={480}
                height={720}
                screenshotQuality={1}
                videoConstraints={{
                  aspectRatio: 0.6666666667,
                  deviceId: videoDeviceID,
                }}
                onUserMedia={onUserMedia}
                onUserMediaError={handleUserMediaError}
              />

              <div id={styles.frame}>
                <img
                  id={styles.calc}
                  src={cdn("images/masque_doc.png")}
                  alt="Cadre capture image"
                />
              </div>
            </div>
          </div>

          <Buttons
            style={{
              paddingTop: 20,
              display: "flex",
              justifyContent: "center",
              gap: "40rem",
              position: "relative",
            }}
          >
            {count ? (
              <Button
                onClick={() => {
                  goToStep("AddAnotherPage")
                }}
                type="primary"
              >
                {current.replies[0].title}
              </Button>
            ) : null}
            {/* <Timer {...{timer }}/> */}
          </Buttons>
        </div>
      )}
    </>
  )
}

const WebcamUnavailablePage = (props: any): JSX.Element => {
  const navigate = useNavigate()

  return (
    <>
      <Card
        title={"Votre caméra est inaccessible ..."}
        subtitle={"Attente de l'autorisation d'accès à la caméra !"}
        imgUrl={cdn("images/camera_picto.svg")}
      >
        <Buttons style={{ paddingTop: 20 }}>
          <Button
            onClick={() => {
              navigate(SURVEY_PAGE)
            }}
            type="primary"
          >
            Annuler
          </Button>
        </Buttons>
      </Card>
    </>
  )
}

const PreviewPage = (props: any): JSX.Element => {
  const { current, previousStep, takeAgain, validatePreview } = props
  const arrayStep = UPLOAD_STEPS.map((step) => step.id)
  const currentStep: string = first(current.id.split(":"))
  const [openModal, setOpenModal] = useState(false)

  const ImageList = current.imgUrl
  const [selectedIndex, setSelectedIndex] = useState(0)

  const setFirstPosition = (index) => {
    if (index > 2) {
      index = 0
    }
    if (index < 0) {
      index = 2
    }
    document.documentElement.style.setProperty("--positionView", index + 1)
    setSelectedIndex(index)
  }

  const showWide = () => {
    setOpenModal(true)
  }

  const handleClose = () => {
    setOpenModal(false)
  }

  return (
    <>
      <PreviewModal
        src={ImageList[selectedIndex]}
        caption={`Image ${selectedIndex + 1}`}
        open={openModal}
        handleClose={handleClose}
      />
      <Steps array={arrayStep} current={currentStep} />
      <PreviousButton onClick={() => previousStep(current)} />
      <Card title={current.title}>
        <div className={styles.PreviewCardContent}>
          <a
            className={styles.btnArrowLeft}
            onClick={() => setFirstPosition(selectedIndex - 1)}
          >
            <img
              className={styles.btnArrowLeftImage}
              src={cdn("images/btn-arrow-left.svg")}
              alt="left arrow"
            />
          </a>
          <div className={styles.carousel}>
            {ImageList.map((img, index) => {
              return (
                <a
                  key={index}
                  className={classNames(
                    styles.item,
                    index === selectedIndex ? styles.ImageSelected : null
                  )}
                  onClick={(e) => setFirstPosition(index)}
                  onDoubleClick={showWide}
                >
                  <img
                    className={styles.PreviewPageImage}
                    src={img}
                    alt="Image prise"
                  />
                  {index === selectedIndex && (
                    <ZoomInOutlined
                      style={{
                        position: "absolute",
                        top: "15px",
                        right: "15px",
                        fontSize: "30px",
                        color: "white",
                      }}
                      onClick={showWide}
                    />
                  )}
                </a>
              )
            })}
          </div>
          <a
            className={styles.btnArrowLeft}
            onClick={() => setFirstPosition(selectedIndex + 1)}
          >
            <img
              className={styles.btnArrowRightImage}
              src={cdn("images/btn-arrow-right.svg")}
              alt="right arrow"
            />
          </a>
        </div>

        <Buttons style={{ paddingTop: 30 }}>
          <Button onClick={takeAgain}>{current.replies[0].title}</Button>
          <Button
            onClick={() => validatePreview(ImageList[selectedIndex])}
            type="primary"
          >
            {current.replies[1].title}
          </Button>
        </Buttons>
      </Card>
    </>
  )
}

const AddAnotherDocPage = (props: any): JSX.Element => {
  const { current, previousStep, AnotherDocYes, AnotherDocNo } = props
  const arrayStep = UPLOAD_STEPS.map((step) => step.id)
  const currentStep: string = first(current.id.split(":"))
  return (
    <>
      <Steps array={arrayStep} current={currentStep} />
      <PreviousButton onClick={() => previousStep(current)} />
      <Card
        title={current.title}
        subtitle={current.subtitle}
        imgUrl={current.imgUrl}
      >
        {current.replies.length ? (
          <UiCarousel
            items={current.replies}
            selected={[]}
            onSelect={AnotherDocYes}
            unSelect={AnotherDocNo}
            ui={current.ui}
          />
        ) : null}
      </Card>
    </>
  )
}

const PickATypePage = (props: any): JSX.Element => {
  const { current, previousStep, getDocType } = props
  const arrayStep = UPLOAD_STEPS.map((step) => step.id)
  const currentStep: string = first(current.id.split(":"))
  return (
    <>
      <Steps array={arrayStep} current={currentStep} />
      <PreviousButton onClick={() => previousStep(current)} />
      <Card
        title={current.title}
        subtitle={current.subtitle}
        imgUrl={current.imgUrl}
      >
        {current.replies.length ? (
          <UiCarousel
            items={current.replies}
            selected={[]}
            onSelect={getDocType}
            unSelect={getDocType}
            ui={current.ui}
          />
        ) : null}
      </Card>
    </>
  )
}

const NameYourDocPage = (props: any): JSX.Element => {
  const { current, previousStep, defaultName, handleSubmit } = props
  const arrayStep = UPLOAD_STEPS.map((step) => step.id)
  const currentStep: string = first(current.id.split(":"))
  const [name, setName] = useState<string>(defaultName)

  const onSubmit = (e) => {
    e.preventDefault()
    handleSubmit(name)
  }

  return (
    <>
      <Steps array={arrayStep} current={currentStep} />
      <PreviousButton onClick={() => previousStep(current)} />
      <Card
        title={current.title}
        subtitle={current.subtitle}
        imgUrl={current.imgUrl}
      >
        <form className="UploadForm" onSubmit={onSubmit}>
          <Keyboard
            options={{
              type: "docname",
              inputName: "request",
            }}
            value={name}
            onChange={setName}
          >
            <Input
              placeholder={"Donnez un nom à votre document"}
              style={{ marginBottom: "50px" }}
              value={name}
              name={name}
              onChange={(a: React.ChangeEvent<HTMLInputElement>) => {
                const request = a.target?.value
                setName(request)
              }}
            />
          </Keyboard>
          <Buttons>
            <Button type="primary">Ajouter le document</Button>
          </Buttons>
        </form>
      </Card>
    </>
  )
}


const UpLoading = (props: any): JSX.Element => {
  const { current, status, nextStep } = props
  const arrayStep = UPLOAD_STEPS.map((step) => step.id)
  const currentStep: string = first(current.id.split(":"))

  useEffect(() => {
    if (status === STATUS_REJECTED || status === STATUS_RESOLVED) {
      setTimeout(() => {
        nextStep(current)
      }, 1000)
    }
  }, [status, current, nextStep])

  return (
    <>
      <Steps array={arrayStep} current={currentStep} />
      <div className={styles.LoadingPage}>
        <div className={styles.LoadingPageDiv1}>
          <img
            className={styles.LoadingPageImg}
            src={current.imgUrl}
            alt="loading"
          />
        </div>
        <div className={styles.LoadingPageDiv2}>
          <h1 className={styles.Title}> {current.title} </h1>
          <h2 className={styles.Subtitle}> {current.subtitle} </h2>
        </div>
      </div>
    </>
  )
}

const YourDocHasBeenLoadedSuccessfuly = (props: any): JSX.Element => {
  const { current, status } = props
  const arrayStep = UPLOAD_STEPS.map((step) => step.id)
  const currentStep: string = first(current.id.split(":"))
  const navigate = useNavigate()

  if (status === STATUS_RESOLVED) {
    return (
      <>
        <Steps array={arrayStep} current={currentStep} />
        <PreviousButton
          onClick={() => {
            navigate(SURVEY_PAGE)
          }}
        />
        <Card
          title={current.title}
          subtitle={current.subtitle}
          imgUrl={current.imgUrl}
        >
          <Buttons>
            <Button
              wide="long"
              type="primary"
              onClick={() => {
                navigate(SURVEY_PAGE)
              }}
            >
              Continuer
            </Button>
          </Buttons>
        </Card>
      </>
    )
  }
  if (status === STATUS_REJECTED) {
    return (
      <>
        <Steps array={arrayStep} current={currentStep} />
        <PreviousButton
          onClick={() => {
            navigate(SURVEY_PAGE)
          }}
        />
        <Card
          title={"Oups..."}
          subtitle={"Une erreur est survenue !"}
          imgUrl={cdn("images/upload_failed.svg")}
        >
          <Buttons>
            <Button
              wide="long"
              type="primary"
              onClick={() => {
                navigate(SURVEY_PAGE)
              }}
            >
              Reprendre
            </Button>
          </Buttons>
        </Card>
      </>
    )
  }
}

const Upload: React.FC<UploadDocProps> = (props): JSX.Element => {
  props.reset()
  const { loading, status, link, MobileUpload, surveyLost } = props
  const [current, setStep] = useState<Step | null>()
  const [currentImage, setCurrentImage] = useState<DocType>(null)
  const [imagesList, SetImagesList] = useState<DocType[]>([])
  const [currentDocument, setCurrentDocument] =
    useState<UploadedDocumentType>(null)
  const [defaultName, setDefaultName] = useState<string>("")
  const [count, setCount] = useState<number>(0)
  const [countShoot, setCountShoot] = useState<number>(0)
  const navigate = useNavigate()

  useEffect(()=>{
    if(!link){
      props.generateQrCode()
    }
    else(
      setStep(UPLOAD_STEPS[0])
    )
  }, [link])

  useEffect(() => {
    if (surveyLost) return navigate(SURVEY_PAGE)
  }, [surveyLost])

  useEffect(() => {
    if (current && current.id === PATH.Qrcode) {
      if (MobileUpload.STATUS === undefined) {
        const interval = setInterval(() => {
          props.fetchNewDocuments(link.created_at)
        }, 5000)
        return () => clearInterval(interval)
      } else {
        props.resetStatus()
        navigate(SURVEY_PAGE)
      }
    }
  }, [current, MobileUpload, link])

  useEffect(() => {
    if (current && current.id === PATH.webOrMobile) {
      props.resetStatus()
    }
  }, [current])

  const goToStep = (stepId) => {
    const indexOfStep = UPLOAD_STEPS.findIndex((step) => step.id === stepId)
    setStep(UPLOAD_STEPS[indexOfStep])
  }

  const nextStep = (current) => {
    const indexOfCurrent = UPLOAD_STEPS.indexOf(current)
    let nextIndex = indexOfCurrent + 1
    if (nextIndex >= UPLOAD_STEPS.length) nextIndex = 0
    const nextCurrent = UPLOAD_STEPS[nextIndex]
    setStep(nextCurrent)
  }

  const previousStep = (current) => {
    const indexOfCurrent = UPLOAD_STEPS.indexOf(current)
    let prevIndex = indexOfCurrent - 1
    if (prevIndex < 0) prevIndex = 0
    const prevCurrent = UPLOAD_STEPS[prevIndex]
    setStep(prevCurrent)
  }

  const onSelect = () => {
    nextStep(current)
  }

  const handleSubmit = (name) => {
    const newDocument = { ...currentDocument, name: name }
    setCurrentDocument(newDocument)
    props.submitDocument(newDocument as UploadedDocumentType)
    nextStep(current)
  }

  const updateImagelist = (currentImage) => {
    const newImagesList = imagesList
    newImagesList.push(currentImage)
    SetImagesList(newImagesList)
  }

  const ValidateShoot = (img) => {
    const validatedPics = { type: "image/png", imgUrl: img, validated: true }
    setCurrentImage(validatedPics)
    updateImagelist(validatedPics)
    setCount(count + 1)
    nextStep(current)
  }

  const getImage = (imgsrc) => {
    setCurrentImage({
      type: "image/png",
      imgUrl: imgsrc[0],
      validated: false,
    })

    const indexOfCurrent = UPLOAD_STEPS.indexOf(current)

    UPLOAD_STEPS[indexOfCurrent + 1].imgUrl = imgsrc
    setCountShoot(countShoot + 1)
    nextStep(current)
  }

  const getDocType = (r: Reply) => {
    const SelecteddefaultName = DocTypeToName[r.value as string]
    setDefaultName(SelecteddefaultName)
    const list = imagesList
      .filter((img) => img.validated === true)
      .map((img) => img.imgUrl)

    const NewDocument: UploadedDocumentType = {
      name: "",
      file: [...list],
      medicalType: r.value,
    }
    setCurrentDocument({ ...NewDocument })
    nextStep(current)
  }

  const AnotherDocYes = (r: Reply) => {
    r.value ? goToStep("LetsGo") : nextStep(current)
  }

  const AnotherDocNo = () => {}

  const validatePreview = (img) => {
    ValidateShoot(img)
  }

  const takeAgain = (r: Reply) => {
    previousStep(current)
  }

  const handleCannotScan = (r: Reply) => {
    goToStep(PATH.SmsOrBorn)
  }

  const handleMobileOrBornResponse = (r: Reply) => {
    if(r.value === SMS_LINK){
      props.sendMeLink(link.url as string)
      openNotification({
        type: "info",
        text: "Un SMS vient de vous être envoyé",
      })
      goToStep(PATH.Qrcode)
    }
    if(r.value=== WEBCAM){
      goToStep(PATH.GetReady)
    }
  }

  if (!current) return <Loader />
  switch (current.id) {
    case PATH.Qrcode:
      return (
        <QrCodePage
          {...{
            loading,
            status,
            current,
            link,
            handleCannotScan,
          }}
        />
      )
    case PATH.SmsOrBorn:
      return(
        <MobileOrBornPage
          {...{
            current,
            goToStep,
            handleMobileOrBornResponse,
          }}
        />
      )
    case PATH.GetReady:
      return (
        <GetReadyPage
          {...{
            current,
            previousStep,
            onSelect,
            goToStep,
          }}
        />
      )
    case PATH.IamReady:
      return (
        <IamReadyPage
          {...{
            current,
            previousStep,
            onSelect,
          }}
        />
      )
    case PATH.LetsGo:
      return (
        <ShooterPage
          {...{
            current,
            previousStep,
            goToStep,
            count,
            countShoot,
            getImage,
          }}
        />
      )

    case PATH.Preview:
      return (
        <PreviewPage
          {...{
            current,
            previousStep,
            takeAgain,
            validatePreview,
          }}
        />
      )
    case PATH.AddAnotherPage:
      return (
        <AddAnotherDocPage
          {...{
            current,
            previousStep,
            AnotherDocYes,
            AnotherDocNo,
          }}
        />
      )
    case PATH.ChooseType:
      return (
        <PickATypePage
          {...{
            current,
            previousStep,
            getDocType,
          }}
        />
      )
    case PATH.DocName:
      return (
        <NameYourDocPage
          {...{
            current,
            previousStep,
            defaultName,
            handleSubmit,
          }}
        />
      )
    case PATH.UpLoading:
      return <UpLoading {...{ current, status, nextStep }} />
    case PATH.Success:
      return (
        <YourDocHasBeenLoadedSuccessfuly
          {...{
            current,
            loading,
            status,
          }}
        />
      )
  }
}

const s = (store: GlobalStore) => {
  return { ...store.upload } as UploadDocProps
}

const p = (dispatch: Dispatcher) => ({
  reset: () => dispatch(actions.reset()),
  submitDocument: (doc) => dispatch(actions.submitDocument(doc)),
  generateQrCode: () => dispatch(actions.generateQrCode()),
  sendMeLink: (link) => dispatch(actions.SendLinkViaSMS(link)),
  fetchNewDocuments: (link_age) =>
    dispatch(actions.fetchNewDocuments(link_age)),
  resetStatus: () => dispatch(actions.resetStatus()),
})

export default connect(s, p)(Upload)
