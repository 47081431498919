import react from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"

import { Card } from "components/Card/Card"
import { FormPage } from "components/Form/Form"
import actions from "containers/Profile/services/actions";
import surveyActions from "containers/Survey/services/actions"
import {
  getCurrentSelectedPatientId,
  getCurrentPatient
} from "containers/Survey/services/selector";
import { SURVEY_PAGE } from "core/constants";
import { capitalize } from "lib/utils"
import { InputNames } from "types/props";
import { birthdateValidator } from "lib/form";
import { BirthPlace, Gender } from "types/entity";
import { useLanguage } from "locales";

const SetRelativeGender = (props) => {
  const { languages } = useLanguage()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentSelectedRelativeId = useSelector(getCurrentSelectedPatientId) || props.relativeId
    const currentSelectedRelative = useSelector(getCurrentPatient)

    const currentSelectedRelativeBirthLocation = currentSelectedRelative.birth_location
    const currentSelectedRelativeGender = currentSelectedRelative.gender

    const Firstname = capitalize(currentSelectedRelative.firstname)

    const inputs = [
        {
          name: "gender",
          required: true,
          label: `${capitalize(languages.inputThe("gender"))} ${Firstname ? `de ${Firstname}` : ''}`,
          keepLabelFormat: true
        }
    ];

    const birthLocationInput = [
      {
        name: InputNames.INSEE_CODE,
        label: "",
        type: "hidden",
      },
      {
        name: "birthdate",
        label: capitalize(languages.inputThe("birthdate")),
        required: true,
        rules: [birthdateValidator],
        type: "hidden",
      },
      { name: InputNames.BIRTH_COUNTRY,
        type: "hidden",
        default: {
        value: `${BirthPlace.FRANCE}`, label: ""
      }},
      { name: InputNames.BIRTH_LOCATION,
        keepLabelFormat: true,
        required: true,
        readOnly: false,
        label: `${capitalize(languages.inputThe(InputNames.BIRTH_LOCATION))} ${Firstname ? `de ${Firstname}`: ''}`
      },
    ]

    let finalInput = []
    if(![Gender.MALE, Gender.FEMALE].includes(currentSelectedRelativeGender as number)){
      finalInput = [...inputs]
    }
    if(!currentSelectedRelativeBirthLocation){
      finalInput = [...finalInput, ...birthLocationInput]
    }

    return (
        <Card
        subtitle={languages.enterRelativeInformations}
      >
        <FormPage
          loading={false}
          inputs={finalInput}
          prospect={currentSelectedRelative}
          onFinish={(values) => {
            const payload = {}
            if(![Gender.MALE, Gender.FEMALE].includes(currentSelectedRelativeGender as number)){
              payload["gender"] = values.gender
            }
            if(!currentSelectedRelativeBirthLocation){
              payload["birth_location"] = values.insee_code
            }
            dispatch(actions.changeRelativeRequest({id: currentSelectedRelativeId, ...payload} as any))
            navigate(SURVEY_PAGE)
          }}
          acceptText={languages.next}
          onCancel={() => {
            dispatch(surveyActions.previous())
            navigate(SURVEY_PAGE)
          }}
        />
      </Card>
    )
}

export default SetRelativeGender;