import { TitleProps } from "antd/lib/typography/Title"
import React from "react"
import TextTransition from "react-text-transition"

import styles from "./Title.module.scss"

const style = (type: string): string => {
  switch (type) {
    case "error":
      return styles.Error
    case "information":
      return styles.Information
    case "warning":
      return styles.Warning
    case "success":
      return styles.Success
    default:
      return ""
  }
}

export const Title: React.FC<{
  text?: JSX.Element | string
  type?: string
  size?: "small" | "medium" | "large"
  style?: any
  doNotAnimateTitle?: boolean;
}> = (props): JSX.Element => {
  if (!props.text || (typeof props.text === "string" && !props.text.length))
    return null
  return (
    <h1
      style={props.style}
      className={`${styles.Title} ${style(props?.type || "")} ${
        props.size
          ? props.size === "small"
            ? styles.Small
            : styles.Medium
          : null
      }`}
    >
      {
        props.doNotAnimateTitle ? props.text : (
          <TextTransition className={styles.TitleText}>
            {props.text || "no title"}
          </TextTransition>
        )
      }
    </h1>
  )
}

export const Paragraph: React.FC<{ text: string | JSX.Element }> = (
  props
): JSX.Element => (
  <TextTransition
    style={{ display: "inline" }}
    springConfig={{ mass: 1, tension: 1000, friction: 100 }}
  >
  {props.text || "no title"}
  </TextTransition>
)
export const Subtitle: React.FC<{ text: string | JSX.Element }> = (
  props
): JSX.Element => (
  <Information
    className={styles.Subtitle}
    text={
      <TextTransition
        springConfig={{ mass: 1, tension: 1000, friction: 100 }}
      >
        {props.text || "no title"}
      </TextTransition>
    }
  />
)

export const Important: React.FC<
  {
    text: string | JSX.Element
    shape?: boolean
    size?: "large"
  } & TitleProps
> = ({ size, shape, text, ...titleProps }): JSX.Element => (
  <h1
    {...titleProps}
    className={`${styles.Important} ${shape ? styles.ImportantShape : ""} ${
      size === "large" ? styles.Large : ""
    }`}
  >
    {text}
  </h1>
)

export const Information: React.FC<
  {
    text: string | JSX.Element
    shape?: boolean
    size?: "large"
  } & TitleProps
> = ({ size, text, shape, ...h1Props }): JSX.Element => (
  <span
    className={`${size === "large" ? styles.Large : null} ${
      styles.Information
    } ${shape ? styles.InformationShape : ""}`}
    {...h1Props}
  >
    {text}
  </span>
)
