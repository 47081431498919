import { Rule } from "antd/lib/form"
import { capitalize } from "lodash"

import {
  codeValidator,
  dateValidator,
  insStringValidator,
  nirLengthValidator,
  nirValidator,
  passwordValidator,
  phoneValidator,
  rppsLengthValidator,
  twoValidator,
} from "lib/form"

import { InputNames } from "types/props"
import { languages } from "locales/languages"

export const typeFromName = (name?: string): string => {
  switch (name) {
    case InputNames.BIRTH_LOCATION:
      return InputNames.BIRTH_LOCATION;
    case "email":
      return name
    case "birthdate":
    case "date":
      return "date"
    case "gender":
    case "patient":
      return "checkbox"
    case "twoFa":
      return "twoFa"
    case "nir":
      return "nir"
    case "phone":
      return "phone"
    case "new-password":
    case "password":
      return "password"
    case "code":
      return "code"
    case "rpps":
      return "rpps"
    case "first_birth_firstname":
    case "birth_lastname":
      return "ins_string"
    default:
      return "string"
  }
}
export const getNameOfCurrentInput = (name: string): string => {
  return name === "new-password" ? "password" : name
}

export const labelFromName = (name?: string): string => {
  switch (name) {
    case "insee_code":
      return languages.code_insee
    case "birth_location":
      return languages.birth_location
    case "email":
      return languages.email
    case "phone":
      return languages.phone
    case "birth_address":
      return languages.birth_location
    case "password":
    case "new-password":
    case "old-password":
      return languages.password
    case "nir":
      return languages.nir
    case "firstname":
      return languages.preferedFirstname;
    case "first_birth_firstname":
      return languages.first_birth_firstname;
    case "birth_lastname":
      return languages.birthLastname;
    case "lastname":
      return languages.preferedLastname;
    case "birthdate":
      return languages.birthdate
    case InputNames.BIRTH_LOCATION:
      return languages.birthLocation;
    case "gender":
      return languages.gender
    case "card":
      return languages.creditCard
    case "code":
    case "twoFa":
      return "code"
    case "rpps":
      return "RPPS"
    default:
      return ""
  }
}

export const rules = (
  name: string,
  required: boolean,
  defaultRules?: Rule[]
): Rule[] => {
  const label = labelFromName(name)
  const type = typeFromName(name)
  const rules: Rule[] = [
    {
      required,
      message: languages.required,
    },
  ]
  const passwordValidation = name === "new-password"
  if (defaultRules) {
    return [...rules, ...defaultRules]
  }

  if (type === "date") {
    rules.push(dateValidator)
  } else if (type === "code") {
    rules.push(codeValidator)
  } else if (type === "password" && passwordValidation) {
    rules.push(passwordValidator)
  } else if (type === "twoFa") {
    rules.push(twoValidator)
  } else if (type === "phone") {
    rules.push(phoneValidator)
  } else if (type === "nir") {
    rules.push(nirValidator)
    rules.push(nirLengthValidator)
  } else if (type === "ins_string") {
    rules.push(insStringValidator);
  } else if (type === "string") {
    rules.push({
      type,
      message: capitalize(`${languages.incorrectInput} ${label}`),
    })
  } else if (type === "email") {
    rules.push({
      type: "email",
      message: capitalize(languages.incorrectInput + " " + label),
    })
  } else if (type === "rpps") {
    rules.push(rppsLengthValidator)
  }
  return rules
}
