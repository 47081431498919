import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"

import { Card } from "components/Card/Card"
import { CarerSelector } from "containers/InsertCarer"
import { InsertProfessionalOrReferent } from "containers/InsertCarer/InsertProfessionalCarer"
import { GET_PHARMACY_CARERS_REQUEST } from "containers/InsertCarer/services/constants"
import { HOME_PAGE } from "core/constants"
import { Dispatcher } from "types/redux"
import { InsertCarerStore } from "types/store"
import { useLanguage } from "locales"

export const AdminCarer = connect(
  ({ carer }) => carer,
  (dispatch: Dispatcher): Partial<InsertCarerStore> => ({
    getPharmacyCarers: () => dispatch({ type: GET_PHARMACY_CARERS_REQUEST }),
  })
)((props: Partial<InsertCarerStore>) => {
  const { languages } = useLanguage()
  const navigate = useNavigate()
  const [insertCarer, setInsertCarer] = useState(false)
  useEffect(() => {
    props.getPharmacyCarers()
  }, [])

  return (
    <Card>
      <div>
        {insertCarer ? (
          <InsertProfessionalOrReferent
            unsecureInsertion={true}
            onFinish={() => {
              setInsertCarer(false)
            }}
            onCancel={() => {
              setInsertCarer(false)
            }}
          />
        ) : (
          <CarerSelector
            title={languages.companionIdentification}
            canDelete={true}
            carers={props.pharmacy_carers}
            isAdminList={true}
            notInTheList={() => setInsertCarer(true)}
            onCancel={() => navigate(HOME_PAGE)}
            onSelect={() => {}}
          />
        )}
      </div>
    </Card>
  )
})
