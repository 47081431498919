export const [
  SIGNUP_REQUEST,
  SIGNUP_ERROR,
  SIGNUP_SUCCESS,
  RESET_SIGNUP_STORE,
  SAVE_RELATIVES,
  SAVE_CUSTOMER,
] = [
  "SIGNUP_REQUEST",
  "SIGNUP_ERROR",
  "SIGNUP_SUCCESS",
  "RESET_SIGNUP_STORE",
  "SAVE_RELATIVES",
  "SAVE_CUSTOMER",
]
