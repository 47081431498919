import { Col, Row } from "antd"
import React, { useEffect, useState } from "react"

import { Button } from "components/Button"
import { UiArrow } from "components/Carousel/Ui"
import { cdn } from "core/cdn"

import styles from "./Call.module.scss"
import { DEVICE_STATE } from "./contexts/videoContext"
import {
  DEVICE_ID_DERMATOSCOPE,
  DEVICE_ID_OTOSCOPE,
  DEVICE_ID_STETHOSCOPE,
  DEVICE_ID_STETHOSCOPE_1,
} from "./types/types"

import * as DOMPurify from "dompurify";
import { useLanguage } from "locales"

interface Device {
  label: string
  state: DEVICE_STATE
}
interface StepProps {
  finish?: () => void
  next?: () => void
  previous?: () => void
  device: Device
}
enum FOLLOW_INSTRUCTIONS_STEP {
  TAKE = 1,
  FOLLOW_INSTRUCTIONS = 2,
  PUT_DOWN = 3,
}

enum TUTO_STEP {
  FOLLOW_INSTRUCTIONS = -21,
  // MANAGER PLUG =>
  UNPLUGGED = -1,
  PLUGGED = 0,
  // tuto =>
  TAKE = 1,
  TURN_ON = 2,
  LIGHT = 3,
  PLACING = 4,
  FOCUS = 5,
  TURN_OFF = 6,
}

enum TUTO_STETHO_STEP {
  TAKE = 1,
  PLACING = 4,
}
// WARNING_MEDIA_DEVICE_TIMER = Temps d'attente avant l'affichage automatique
// informant l'utilisateur que l'outil semble ne pas fonctionner
const [TIMER_PER_STEP_IN_SEC, WARNING_MEDIA_DEVICE_TIMER] = [20, 20]
const DO_NOT_NEED_TUTO = [DEVICE_ID_STETHOSCOPE, DEVICE_ID_STETHOSCOPE_1]
const CANNOT_TURN_OFF = [DEVICE_ID_STETHOSCOPE, DEVICE_ID_STETHOSCOPE_1]

const Timer = (props: { timer: number }): JSX.Element => {
  return (
    <div className={styles.Timer}>
      <img src={cdn("images/devices/tutorial_device/waiting.svg")} alt="" />
      <span>{props.timer}</span>
    </div>
  )
}
const NavigationStep = (props: {
  next?: () => void
  previous?: () => void
  img?: { src: string; alt: string }
  children?: JSX.Element
}) => {
  return (
    <Row gutter={[20, 20]} align="middle">
      <Col style={{ width: "100px" }}>
        <UiArrow
          side="left"
          onClick={() => props.previous && props.previous()}
          hidden={!props.previous}
        />
      </Col>
      <Col>
        {props.img && (
          <img
            className={styles.ImageTuto}
            src={props.img.src}
            alt={props.img.alt}
          />
        )}
        {props.children}
      </Col>
      <Col style={{ width: "100px" }}>
        <UiArrow
          side="right"
          onClick={() => props.next && props.next()}
          hidden={!props.next}
        />
      </Col>
    </Row>
  )
}

const TakeStep = (props: StepProps) => {
  const { languages } = useLanguage()
  return (
    <>
      <NavigationStep
        img={
          props.device.label && {
            src: deviceImage(props.device.label),
            alt: deviceName(props.device.label),
          }
        }
      />
      <span className={styles.TitleTuto}>
        {languages.take}
        <br />
        <b>{deviceName(props.device.label)}</b>
      </span>
      <Button type="primary" wide="long" onClick={props.next}>
        {languages.next}
      </Button>
    </>
  )
}

const TurnOnStep = (props: StepProps) => {
  const { languages } = useLanguage()
  const [showWarning, setShowWarning] = useState<boolean>(false)

  useEffect(() => {
    const interval = setTimeout(() => {
      setShowWarning(true)
    }, WARNING_MEDIA_DEVICE_TIMER * 1000)
    return () => clearInterval(interval)
  }, [])
  return (
    <>
      <NavigationStep
        previous={props.previous}
        img={
          props.device.label && {
            src: cdn(
              `/images/devices/tutorial_device/turn_on_${deviceName(
                props.device.label,
                "naming"
              )}.png`
            ),
            alt: deviceName(props.device.label),
          }
        }
      />
      <span
        className={`${styles.TitleTuto} uppercase-first-letter`}
        dangerouslySetInnerHTML={{
          __html:
            props.device.label === DEVICE_ID_OTOSCOPE
              ? DOMPurify.sanitize(languages.turnOnDevice_otoscope_html, { USE_PROFILES: { html: true }})
              : DOMPurify.sanitize(languages.turnOnDevice_dermatoscope_html, { USE_PROFILES: { html: true }}),
        }}
      />
      {showWarning && (
        <span className={styles.WarningDevice}>
          <img
            style={{ height: "45px" }}
            src={cdn("images/devices/tutorial_device/attention.png")}
            alt="attention_icon_picture"
          />
          {languages.seemsDeviceNotOn(deviceName(props.device.label, "short"))}
        </span>
      )}
    </>
  )
}

const LightStep = (props: StepProps) => {
  const { languages } = useLanguage()
  const [timer, setTimer] = useState<number>(TIMER_PER_STEP_IN_SEC)

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer === 0) {
        props.next && props.next()
        return () => clearInterval(interval)
      }
      setTimer((timer) => timer - 1)
    }, 1000)
    return () => clearInterval(interval)
  }, [timer])

  return (
    <>
      <NavigationStep
        next={props.next}
        img={
          props.device.label && {
            src: cdn(
              `/images/devices/tutorial_device/light_${deviceName(
                props.device.label,
                "naming"
              )}.png`
            ),
            alt: deviceName(props.device.label),
          }
        }
      />
      <span
        className={`${styles.TitleTuto} uppercase-first-letter`}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(languages.lightDevice_html, { USE_PROFILES: { html: true }}),
        }}
      />
      <Timer timer={timer} />
    </>
  )
}

const UnpluggedStep = (props: StepProps) => {
  const { languages } = useLanguage()
  return (
    <>
      <NavigationStep
        img={
          props.device.label && {
            src: deviceImage(props.device.label),
            alt: deviceName(props.device.label),
          }
        }
      />
      <span className={styles.TitleTuto}>
        <b className="uppercase-first-letter">
          {deviceName(props.device.label)}
        </b>
        <br /> {languages.isNotTurnOn}
      </span>
    </>
  )
}

const PluggedStep = (props: StepProps) => {
  const { languages } = useLanguage()
  return (
    <>
      <NavigationStep
        img={
          props.device.label && {
            src: deviceImage(props.device.label),
            alt: deviceName(props.device.label),
          }
        }
      />

      <span className={styles.TitleTuto}>
        {languages.inUsing}
        <br />
        <b>{deviceName(props.device.label)}</b>
      </span>
    </>
  )
}

const FocusStep = (props: StepProps) => {
  const { languages } = useLanguage()
  return (
    <>
      <NavigationStep
        previous={props.previous}
        img={
          props.device.label && {
            src: `/images/devices/tutorial_device/focus_${deviceName(
              props.device.label,
              "naming"
            )}.png`,
            alt: deviceName(props.device.label),
          }
        }
      />

      <span
        className={styles.TitleTuto}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(languages.focusDevice_html, { USE_PROFILES: { html: true }}),
        }}
      />
    </>
  )
}
const PlacingStep = (props: StepProps) => {
  const { languages } = useLanguage()
  const [timer, setTimer] = useState<number>(TIMER_PER_STEP_IN_SEC)

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer === 0) {
        props.next && props.next()
        return () => clearInterval(interval)
      }
      setTimer((timer) => timer - 1)
    }, 1000)
    return () => clearInterval(interval)
  }, [timer])

  return (
    <>
      <NavigationStep
        previous={props.previous}
        next={props.next}
        img={
          props.device.label && {
            src: cdn(
              `/images/devices/tutorial_device/use_${deviceName(
                props.device.label,
                "naming"
              )}.png`
            ),
            alt: deviceName(props.device.label),
          }
        }
      />

      <span
        className={`${styles.TitleTuto} uppercase-first-letter`}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(languages.useVideoDevice_html(
            deviceName(props.device.label, "naming"), { USE_PROFILES: { html: true }})
          ),
        }}
      ></span>
      <Timer timer={timer} />
    </>
  )
}
const TurnOffStep = (props: StepProps) => {
  // Pour les outils ne s'éteignant pas ( le stéthoscope ),
  // on demande de le reposer
  const { languages } = useLanguage()
  if (CANNOT_TURN_OFF.includes(props.device.label)) {
    return <PutDownStep {...props} next={props.finish} />
  }

  return (
    <>
      <NavigationStep
        img={
          props.device.label && {
            src: cdn(
              `/images/devices/tutorial_device/turn_off_${deviceName(
                props.device.label,
                "naming"
              )}.png`
            ),
            alt: deviceName(props.device.label),
          }
        }
      />

      <span
        className={`${styles.TitleTuto} uppercase-first-letter`}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(languages.turnOffDevice_html(
            deviceName(props.device.label, "short"), { USE_PROFILES: { html: true }})
          ),
        }}
      ></span>
      <Button type="primary" wide="long" onClick={props.finish}>
        {languages.iTurnOff}
      </Button>
    </>
  )
}
const deviceImage = (label: string) => {
  switch (label) {
    case DEVICE_ID_OTOSCOPE:
      return cdn("images/devices/otoscope.svg")
    case DEVICE_ID_STETHOSCOPE:
    case DEVICE_ID_STETHOSCOPE_1:
      return cdn("images/devices/stethoscope.svg")
    case DEVICE_ID_DERMATOSCOPE:
      return cdn("images/devices/dermatoscope.svg")
    default:
      return ""
  }
}

const header = (tutoStep: TUTO_STEP) => {
  switch (tutoStep) {
    case TUTO_STEP.TAKE:
      return "Tutoriel"
    case TUTO_STEP.LIGHT:
      return "1/3"
    case TUTO_STEP.PLACING:
      return "2/3"
    case TUTO_STEP.FOCUS:
      return "3/3"
    default:
      return ""
  }
}

const deviceName = (label: string, size?: "short" | "naming") => {
  switch (label) {
    case DEVICE_ID_OTOSCOPE:
      if (size === "short") return " l'otoscope"
      if (size === "naming") return "otoscope"
      return " l'otoscope (outil A)"
    case DEVICE_ID_STETHOSCOPE:
    case DEVICE_ID_STETHOSCOPE_1:
      if (size === "short") return " le stéthoscope"
      if (size === "naming") return "stethoscope"
      return " le stéthoscope (outil B)"
    case DEVICE_ID_DERMATOSCOPE:
      if (size === "short") return " le dermatoscope"
      if (size === "naming") return "dermatoscope"
      return " le dermatoscope (outil D)"
    default:
      return " l'outil en cours"
  }
}

const TutoStepper = ({
  tutoStep,
  ...props
}: {
  finish: () => void
  previous: () => void
  next: () => void
  device: Device
  tutoStep: TUTO_STEP
}): JSX.Element => {
  switch (tutoStep) {
    case TUTO_STEP.UNPLUGGED:
      return <UnpluggedStep {...props} />
    case TUTO_STEP.PLUGGED:
      return <PluggedStep {...props} />
    case TUTO_STEP.TAKE:
      return <TakeStep {...props} />
    case TUTO_STEP.TURN_ON:
      return <TurnOnStep {...props} />
    case TUTO_STEP.LIGHT:
      return <LightStep {...props} />
    case TUTO_STEP.FOCUS:
      return <FocusStep {...props} />
    case TUTO_STEP.PLACING:
      return <PlacingStep {...props} />
    case TUTO_STEP.TURN_OFF:
      return <TurnOffStep {...props} />
    default:
      return <></>
  }
}

const FollowInstructionsStep = (props: StepProps) => {
  const { languages } = useLanguage()
  const name = deviceName(props.device.label)
  return (
    <>
      <NavigationStep
        img={
          props.device.label && {
            src: cdn(
              `/images/devices/tutorial_device/use_${deviceName(
                props.device.label,
                "naming"
              )}.png`
            ),
            alt: deviceName(props.device.label),
          }
        }
      />

      <span
        className={`${styles.TitleTuto} uppercase-first-letter`}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(languages.followingInstructions_html(name), { USE_PROFILES: { html: true }}),
        }}
      />
    </>
  )
}

const PutDownStep = (props: StepProps) => {
  const { languages } = useLanguage()
  return (
    <>
      <NavigationStep
        img={
          props.device.label && {
            src: cdn(
              `/images/devices/tutorial_device/put_down_${deviceName(
                props.device.label,
                "naming"
              )}.png`
            ),
            alt: deviceName(props.device.label),
          }
        }
      />
      <span
        className={`${styles.TitleTuto} uppercase-first-letter`}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(languages.putDown(deviceName(props.device.label)), { USE_PROFILES: { html: true }}),
        }}
      />
      <Button type="primary" wide="long" onClick={props.next}>
        {languages.finish}
      </Button>
    </>
  )
}

const FollowInstructionStepper = (props: StepProps) => {
  const [followInstructionSteps, setFollowInstructionSteps] =
    useState<FOLLOW_INSTRUCTIONS_STEP>(FOLLOW_INSTRUCTIONS_STEP.TAKE)
  switch (followInstructionSteps) {
    case FOLLOW_INSTRUCTIONS_STEP.TAKE:
      return (
        <TakeStep
          device={props.device}
          next={() => {
            setFollowInstructionSteps(
              FOLLOW_INSTRUCTIONS_STEP.FOLLOW_INSTRUCTIONS
            )
          }}
        />
      )
    case FOLLOW_INSTRUCTIONS_STEP.FOLLOW_INSTRUCTIONS:
      return (
        <FollowInstructionsStep
          device={props.device}
          previous={() => {
            setFollowInstructionSteps(FOLLOW_INSTRUCTIONS_STEP.TAKE)
          }}
        />
      )
    default:
      return <></>
  }
}

export const DeviceManager = (props: {
  device?: Device
  onStop: () => void
}) => {
  const [tutoStep, setTutoStep] = useState<TUTO_STEP>(TUTO_STEP.TAKE)

  useEffect(() => {
    // Device State
    const currentDevice = props.device
    if (!currentDevice) {
      setTutoStep(TUTO_STEP.TAKE)
      return
    }

    switch (currentDevice.state) {
      case DEVICE_STATE.UNPLUGGED:
        setTutoStep(TUTO_STEP.UNPLUGGED)
        break
      case DEVICE_STATE.WAITING:
        setTutoStep(TUTO_STEP.TAKE)
        break
      case DEVICE_STATE.USING:
        if (DO_NOT_NEED_TUTO.includes(currentDevice.label))
          setTutoStep(TUTO_STEP.FOLLOW_INSTRUCTIONS)
        else setTutoStep(TUTO_STEP.LIGHT)
        break
      case DEVICE_STATE.CLOSING:
        setTutoStep(TUTO_STEP.TURN_OFF)
        break
    }
  }, [props.device])

  return (
    <div
      className={`${styles.DeviceContainer} ${
        props.device ? styles.Active : ""
      }`}
    >
      <div className={styles.HeaderDevice}>{header(tutoStep)}</div>
      {props.device &&
        (tutoStep !== TUTO_STEP.FOLLOW_INSTRUCTIONS ? (
          <TutoStepper
            previous={() => setTutoStep(tutoStep - 1)}
            next={() => setTutoStep(tutoStep + 1)}
            tutoStep={tutoStep}
            finish={props.onStop}
            device={props.device}
          />
        ) : (
          <FollowInstructionStepper device={props.device} />
        ))}
    </div>
  )
}
