import { Buttons } from "components/Card/Card"
import { Modal } from "components/Modal/Modal"
import PreviousButton from "components/Previous/PreviousButton"
import { Important } from "components/Title/Title"
import { cdn } from "core/cdn"
import { useLanguage } from "locales"
import React, { useState } from "react"

export const Waiting: React.FC<{
  remainingMinutes: number
  cancelCall: () => void
  loading: boolean
}> = ({ remainingMinutes, cancelCall, loading }): JSX.Element => {
  const { languages } = useLanguage()
  const [modalVisible, setModalVisibility] = useState(false)
  return (
    <>
      <PreviousButton
        text={languages.cancel}
        onClick={() => setModalVisibility(true)}
      />

      <Buttons direction="vertical" style={{ marginTop: "0" }}>
        <div className="bold">
          <b>{languages.answerIn} </b>
        </div>
        <div className="center flex">
          <img
            style={{ marginBottom: "6px" }}
            src={cdn("/icons/waiting.svg")}
            alt=""
          />
          <Important
            shape={true}
            text={`${remainingMinutes.toString() || "0"} min`}
          />
        </div>
      </Buttons>
      <Modal
        loading={loading}
        title={languages.confirmCancelCall}
        visible={modalVisible}
        onAccept={cancelCall}
        acceptText={languages.cancelCall}
        acceptButtonProps={{ danger: true, type: "primary" }}
        onCancel={() => setModalVisibility(false)}
      />
    </>
  )
}
