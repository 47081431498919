// In order to perform an action of type LOGIN_REQUEST
import { EmailCredential } from "types/payload"
import { Action, ActionWithoutPayload, Message } from "types/redux"
import {
  ADMIN_LOGIN_REQUEST,
  ADMIN_LOGIN_ERROR,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGOUT,
} from "./constants"

export const adminLoginRequest = (
  payload: EmailCredential
): Action<EmailCredential> => ({
  type: ADMIN_LOGIN_REQUEST,
  payload,
})

export const adminLoginError = (error: string): Action<Message> => ({
  type: ADMIN_LOGIN_ERROR,
  payload: { text: error, type: "error" },
})

export const adminLoginSuccess = (payload: string): Action<Message> => ({
  type: ADMIN_LOGIN_SUCCESS,
  payload: { text: payload, type: "success" },
})

export const adminLogout = (): ActionWithoutPayload => ({
  type: ADMIN_LOGOUT,
})
