import { CustomerType } from "types/props"
import { Action, ActionWithoutPayload, Message } from "types/redux"

import { NUMBER_OF_SLOTS_AFTER_NOW, NUMBER_OF_SLOTS_BEFORE_NOW, PATH } from "../models"
import { Answer, Question, Reply } from "../types"
import { CANCEL_SURVEY, CREATE_QUESTION, GET_ALL_DOCUMENT, GET_ALL_DOCUMENT_REQUESTS, INIT_SURVEY, NEXT_QUESTION, PREVIOUS_QUESTION, QUESTION_SLOTS, REMOVE_QUESTION, RESET_SURVEY_QUESTION_REPLIES, RESET_SURVEY_REPLY, RESET_SURVEY_STORE, SET_CUSTOMER_TYPE, SET_QUESTION, SUBMIT_SURVEY_ERROR, SUBMIT_SURVEY_REQUEST, SUBMIT_SURVEY_SUCCESS, UNSET_QUESTION } from "./constants"

const actions = {
  setStepReplies: (payload: {
    replies: Reply[]
    id?: PATH
  }): Action<{ replies: Reply[]; id?: PATH }> => ({
    type: SET_QUESTION,
    payload,
  }),

  unsetStepReplies: (payload: {
    replies?: Reply[]
    id?: PATH
  }): Action<{ replies?: Reply[]; id?: PATH }> => ({
    type: UNSET_QUESTION,
    payload,
  }),
  set: (replies: Reply[]): Action<Reply[]> => ({
    type: SET_QUESTION,
    payload: replies,
  }),

  unset: (replies?: Reply[]): Action<Reply[] | undefined> => ({
    type: UNSET_QUESTION,
    payload: replies,
  }),
  reset: (): ActionWithoutPayload => ({ type: RESET_SURVEY_STORE }),
  resetReply: (id: string) => ({ type: RESET_SURVEY_REPLY, payload: id }),
  resetReplies: (): ActionWithoutPayload => ({
    type: RESET_SURVEY_QUESTION_REPLIES,
  }),
  init: (): ActionWithoutPayload => ({ type: INIT_SURVEY }),
  cancel: (): ActionWithoutPayload => ({ type: CANCEL_SURVEY }),
  next: (): ActionWithoutPayload => ({ type: NEXT_QUESTION }),

  previous: (): ActionWithoutPayload => ({ type: PREVIOUS_QUESTION }),

  setSlots: (): Action<{ slotsBeforeNow: number; slotsAfterNow: number }> => ({
    type: QUESTION_SLOTS,
    payload: {
      slotsBeforeNow: NUMBER_OF_SLOTS_BEFORE_NOW,
      slotsAfterNow: NUMBER_OF_SLOTS_AFTER_NOW,
    },
  }),
  create: (payload: {
    question: Question
    options?: { after?: PATH; before?: PATH }
  }): Action<{
    question: Question
    options?: { after?: PATH; before?: PATH }
  }> => ({
    type: CREATE_QUESTION,
    payload,
  }),

  remove: (payload: string): Action<string> => ({
    type: REMOVE_QUESTION,
    payload,
  }),
  for: (customerType: CustomerType): Action<CustomerType> => ({
    type: SET_CUSTOMER_TYPE,
    payload: customerType,
  }),
  success: (): ActionWithoutPayload => ({ type: SUBMIT_SURVEY_SUCCESS }),

  error: (e: string): Action<Message> => ({
    type: SUBMIT_SURVEY_ERROR,
    payload: { type: "error", text: e },
  }),

  submit: (answers: Answer[]): Action<Answer[]> => {
    return { type: SUBMIT_SURVEY_REQUEST, payload: answers }
  },

  getDocuments: (): ActionWithoutPayload => ({
    type: GET_ALL_DOCUMENT_REQUESTS,
  }),
}

export default actions
