import { cdn } from "core/cdn"

export const Brand = (props: {
  brand: string
  width?: string
}): JSX.Element => {
  switch (props.brand) {
    case "visa":
      return (
        <img
          src={cdn("icons/bank/carte_credit_visa.svg")}
          {...props}
          alt="visa"
        />
      )
    case "paypal":
      return <img src={cdn("icons/bank/paypal.svg")} {...props} alt="paypal" />
    case "maestro":
      return (
        <img src={cdn("icons/bank/maestro.svg")} {...props} alt="maestro" />
      )
    case "mastercard":
      return (
        <img
          src={cdn("icons/bank/carte_credit_mastercard.svg")}
          {...props}
          alt="maestro"
        />
      )
    case "amex":
      return (
        <img
          src={cdn("icons/bank/carte_credit_american_express.svg")}
          {...props}
          alt="amex"
        />
      )
    case "generic":
    default:
      return (
        <img
          src={cdn("icons/bank/carte_credit_neutre.svg")}
          {...props}
          alt="generic"
        />
      )
  }
}
