import React from "react"

import { Buttons } from "components/Card/Card"

import styles from "./Call.module.scss"
import { useLanguage } from "locales"

export const Hangup: React.FC<unknown> = ({}): JSX.Element => {
  const { languages } = useLanguage()
  return (
    <Buttons style={{ marginTop: "0" }} direction="vertical">
      <div className={`paragraph ${styles.HangupButton}`}>
        {languages.waitInFrontOfTheScreen}
      </div>
      <p className={styles.InformationDoctorWaiting}>
        {languages.doctorIsWritingYourDocs}
        <br />
        {languages.stepCanTake} <b>{languages.timeWaitingHungup}</b>
      </p>
    </Buttons>
  )
}
