import { first } from "lodash"
import React, { useEffect, useState } from "react"
import { connect, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { Button } from "components/Button"
import { Card } from "components/Card/Card"
import { UiCarousel } from "components/Carousel/Index"
import { UiFooter } from "components/Carousel/Ui"
import Loader from "components/Loaders/Loader/Loader"
import { GenericModal, UploadModal } from "components/Modal/Modal"
import PreviousButton from "components/Previous/PreviousButton"
import { Steps } from "components/Steps"
import { InsertCarerModal } from "containers/InsertCarer"
import { PAYMENT_PAGE, PROFILE_PAGE } from "core/constants"
import { Carer } from "types/entity"
import { Dispatcher } from "types/redux"
import { SurveyStore } from "types/store"

import { PATH, PATH_DEEP, subTitles } from "./models"
import actions from "./services/actions"
import { getCurrentSelectedPatientId } from "./services/selector"
import { ACTION, Answer, QUESTION_TYPE, Reply, SurveyProps, Value } from "./types"
import { useLanguage } from "locales"

const DefaultReply = (props: { reply: Reply; onSelect: () => void }) => {
  return (
    <Button onClick={props.onSelect} wide="long" type={props.reply?.ui?.type}>
      {props.reply.title}
    </Button>
  )
}

const Index: React.FC<SurveyProps> = (props): JSX.Element => {
  const { languages } = useLanguage()
  const { current: question, answer: selectedItems, answers } = props
  const [tooYoungModalVisible, setTooYoungModalVisible] = useState(false)
  const [insertCarerModalVisible, setInsertCarerModalVisible] = useState(false)
  const [uploadModalDisplayCount, setUploadModalDisplayCount] = useState(0)
  const [showUploadRecommendedModal, setShowUploadRecommendedModal] =
    useState(false)
  const [TLCReason, setTLCReason] = useState("")
  const [uploadModalTitle, setUploadModalTitle] = useState("")
  const currentPatientId = useSelector(getCurrentSelectedPatientId)
  const navigate = useNavigate()

  useEffect(() => {
    if (question.id === PATH.selected_documents) {
      props.resetReplies()
      props.getUploadedDocuments()

      const interval = setInterval(() => {
        props.getUploadedDocuments()
      }, 5000)
      return () => clearInterval(interval)
    }
    if (question.id === PATH.upload_documents) {
      const reason = answers.find((a) => a.questionId === "reason")
        .value[0] as string
      setTLCReason(reason)
    }
  }, [question.id])

  useEffect(() => {
    if (
      question.id === PATH.selected_documents &&
      question.replies.length > 0
    ) {
      question.replies.forEach((reply) => {
        if (reply.preselect) props.set([reply])
      })
    }
  }, [question.id, question.replies.length])

  useEffect(() => {
    if (props.current.type === QUESTION_TYPE.redirect)
      navigate(props.current.value)
  }, [props.current])

  useEffect(() => {
    switch (props.status) {
      case "finish":
        props.submit(props.answers)
        return navigate(PAYMENT_PAGE)
      case "cancel":
        props.reset()
        return navigate(PROFILE_PAGE)
      case "on_going":
        break
    }
  }, [props.status])

  const next = () => {
    props.next()
  }

  const onSelect = (r: Reply) => {
    if (
      question.id === PATH.upload_documents &&
      !r.value &&
      uploadModalDisplayCount === 0 &&
      [languages.Renewal, languages.lab_results].includes(TLCReason)
    ) {
      const isRenewal = languages.Renewal===TLCReason
      setUploadModalTitle(isRenewal? languages.UploadRecommendedText : languages.photoTransferText)
      setShowUploadRecommendedModal(true)
      setUploadModalDisplayCount((c) => c + 1)
      return
    }

    if (r.action === ACTION.redirect) {
      navigate(r.value as string)
      return
    }
    if (r.action === ACTION.inability) {
      if (r.value === "relative_too_young") {
        setTooYoungModalVisible(true)
      }
      return
    }
    if (!question.multipleReplies) {
      const oldValues = question.replies.filter((old) => old.value !== r.value)
      props.unset(oldValues)
      props.set([r])
      next()
      return
    }
    props.set([r])
  }

  const unSelect = (r: Reply) => {
    if (!question.multipleReplies) {
      const oldValue = question.replies.filter((old) => old.value === r.value)
      if (oldValue) {
        props.unset([r])
        props.set([r])
        next()
      }
    } else {
      props.unset([r])
    }
  }

  const handleUploadModalAccept = () => {
    setShowUploadRecommendedModal(false)
    onSelect(question.replies[0])
  }

  const handleUploadModalCancel = () => {
    setShowUploadRecommendedModal(false)
    onSelect(question.replies[1])
  }

  const arrayStep = props.questions.map((question) => question.id)
  const currentStep = first(question.id.split(PATH_DEEP)) as string
  if (!question) return <Loader />
  return (
    <>
      <Steps array={arrayStep} current={currentStep} />
      <PreviousButton onClick={props.previous} />
      <Card
        title={question.title}
        subtitle={question.subtitle}
        imgUrl={question.imgUrl}
        titleCustomStyle={
          question.id === PATH.upload_documents
            ? { marginBottom: 20 }
            : undefined
        }
      >
        {question.replies.length ? (
          <UiCarousel
            items={question.replies}
            selected={selectedItems}
            onSelect={onSelect}
            unSelect={unSelect}
            ui={question.ui}
          />
        ) : null}
        {question.informationText && (
          <div className="information-card">{question.informationText}</div>
        )}
        <UiFooter
          style={question.id === PATH.inform && { paddingTop: 0 }}
          nextVisible={!!question.multipleReplies}
          nextDisabled={!selectedItems.length}
          onFinish={props.next}
        >
          {question.defaultReply && (
            <DefaultReply
              reply={question.defaultReply}
              onSelect={() => {
                // default reply pass on the next direct
                onSelect(question.defaultReply as Reply)
              }}
            />
          )}
        </UiFooter>
        {question.id === PATH.inform && (
          <div>
            <span
              className="small-link"
              onClick={() => setInsertCarerModalVisible(true)}
            >
              {currentPatientId
                ? languages.ImCaredForThisTlc_relative
                : languages.ImCaredForThisTlc}
            </span>
            <InsertCarerModal
              visible={insertCarerModalVisible}
              onFinish={(carer: Carer) => {
                setInsertCarerModalVisible(false)
                next()
              }}
              onCancel={() => setInsertCarerModalVisible(false)}
            />
          </div>
        )}

        <GenericModal
          visible={tooYoungModalVisible}
          title={languages.notSuitableTLC}
          acceptText={languages.IGotIt}
          onAccept={() => {
            setTooYoungModalVisible(false)
          }}
          contentText={languages.notSuitableTLCContent}
        />
        <UploadModal
          title={uploadModalTitle}
          onAccept={handleUploadModalAccept}
          onCancel={handleUploadModalCancel}
          visible={showUploadRecommendedModal}
        />
      </Card>
    </>
  )
}

const mapStateToProps = ({ survey }: { survey: SurveyStore }): SurveyProps => {
  const current = survey.current

  if (current.id === PATH.upload_documents) {
    const reason = survey.answers.find((a) => a.questionId === "reason")
      .value[0]
    const subtitle = subTitles.filter((elem) => elem.reason === reason)[0]
      .subtitle
    current.subtitle = subtitle
  }
  const answer: Value[] =
    survey.answers.find(({ questionId }: Answer) => questionId === current?.id)
      ?.value || []

  return {
    current,
    answer,
    status: survey.status,
    answers: survey.answers,
    questions: survey.questions,
  } as SurveyProps
}

const mapDispatchToProps = (dispatch: Dispatcher): Partial<SurveyProps> => {
  return {
    set: (replies_to_add: Reply[]) => dispatch(actions.set(replies_to_add)),
    unset: (replies_to_remove?: Reply[]) =>
      dispatch(actions.unset(replies_to_remove)),
    next: () => dispatch(actions.next()),
    previous: () => dispatch(actions.previous()),
    reset: () => dispatch(actions.reset()),
    resetReplies: () => dispatch(actions.resetReplies()),
    cancel: () => dispatch(actions.cancel()),
    submit: (a: Answer[]) => dispatch(actions.submit(a)),
    getUploadedDocuments: () => dispatch(actions.getDocuments()),
  } as Partial<SurveyProps>
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
