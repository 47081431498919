// CALL
export const CALL_REQUEST = "CALL_REQUEST"
export const CALL_SUCCESS = "CALL_SUCCESS"
export const CALL_ERROR = "CALL_ERROR"
export const CALL_HAS_CHANGED = "CALL_HAS_CHANGED"

export const CALL_JUST_STARTED = "CALL_JUST_STARTED"
export const GET_CALL = "GET_CALL"

export const CALL_HANGUP_REQUEST = "CALL_HANGUP_REQUEST"
export const CALL_HANGUP_SUCCESS = "CALL_HANGUP_SUCCESS"
export const CALL_HANGUP_ERROR = "CALL_HANGUP_ERROR"

export const CALL_CANCEL_REQUEST = "CALL_CANCEL_REQUEST"
export const CALL_CANCEL_SUCCESS = "CALL_CANCEL_SUCCESS"
export const CALL_CANCEL_ERROR = "CALL_CANCEL_ERROR"
