import { concat } from "lodash"
import { useEffect, useMemo, useState } from "react"
import { connect, useSelector } from "react-redux"

import { Card } from "components/Card/Card"
import { FormPage } from "components/Form/Form"
import Loader from "components/Loaders/Loader/Loader"
import Message from "components/Message/Message"
import PreviousButton from "components/Previous/PreviousButton"
import { getCurrentPatient, getCurrentSelectedPatientId } from "containers/Survey/services/selector"
import { Carer, Customer, Relative } from "types/entity"
import { Dispatcher } from "types/redux"
import { ClientStore, InsertCarerStore, NirReaderResponse } from "types/store"

import { CarerSelector, InsertCarerProps } from "."
import { ADD_PATIENT_CARERS_REQUEST, ADD_PHARMACY_CARERS_REQUEST, ADD_RELATIONSHIP_PATIENT_CARERS_REQUEST, CARERS_CLEAR_MESSAGE } from "./services/constants"
import { useLanguage } from "locales"

const getSelectedCarersList = ({ currentPatient, carers, patients }) => {
  const getParsedName = (patient) =>
    `${patient.firstname} ${patient.lastname}`.toLowerCase()
  const list = concat(
    carers.map((carer) => ({ ...carer, is_carer: true })),
    [patients.patient]
      .filter(
        (main) =>
          !carers
            .map((carer) => getParsedName(carer))
            .includes(getParsedName(main))
      )
      .map((carer) => ({ ...carer, is_carer: false })),
    patients.relatives
      .filter(
        (relative) =>
          !carers
            .map((carer) => getParsedName(carer))
            .includes(getParsedName(relative))
      )
      .map((carer) => ({ ...carer, is_carer: false }))
  ).filter((carer) => getParsedName(carer) !== getParsedName(currentPatient))
  return list as []
}

export const SelectRelativeCarer = connect(
  ({ carer }) => carer,
  null
)((props: Partial<InsertCarerProps & InsertCarerStore>) => {
  const patients: NirReaderResponse = useSelector(
    ({ client }: { client: ClientStore }) => ({
      patient: client.customer,
      relatives: client.relatives,
    })
  )
  const { languages } = useLanguage()
  const currentPatient: Customer | Relative = useSelector(getCurrentPatient)
  const currentPatientId = useSelector(getCurrentSelectedPatientId)
  const [insertCarer, setInsertCarer] = useState(null)

  const list = useMemo(
    () =>
      getSelectedCarersList({
        currentPatient,
        carers: props.patient_carers || [],
        patients,
      }),
    [props.patient_carers]
  )
  useEffect(() => {
    if (!list.length) setInsertCarer({})
    else setInsertCarer(null)
  }, [list])

  return insertCarer ? (
    <InsertRelativeCarer
      onFinish={(c) => {
        props.onFinish(c)
        setInsertCarer(null)
      }}
      partialCarer={insertCarer}
      onCancel={() => (list.length ? setInsertCarer(null) : props.onCancel())}
    />
  ) : (
    <CarerSelector
      title={
        currentPatientId
          ? languages.whoIsCaringYou_relative
          : languages.whoIsCaringYou
      }
      canDelete={false}
      carers={list}
      onSelect={(patient: Partial<Carer & { is_carer: boolean }>) => {
        if (patient.is_carer) props.onFinish(patient as Carer)
        else setInsertCarer(patient)
      }}
      notInTheList={() => setInsertCarer({})}
      onCancel={props.onCancel}
    />
  )
})

export const SelectRelativeToBeCarer = (
  props: Partial<InsertCarerProps & InsertCarerStore>
) => {
  const { languages } = useLanguage()
  // Ici, soit il y a des relative, donc on affiche les relatives,
  // soit il y en a pas, donc on lance le formulaire pour créer un accompagnant
  const [insertCarer, setInsertCarer] = useState(null)
  const currentPatientId = useSelector(getCurrentSelectedPatientId)
  const patients: NirReaderResponse = useSelector(
    ({ client }: { client: ClientStore }) => ({
      patient: client.customer,
      relatives: client.relatives,
    })
  )

  let patientsCouldBeCarer = []
  if (currentPatientId === 0) {
    patientsCouldBeCarer = [...(patients.relatives as unknown as Carer[])]
  } else {
    patientsCouldBeCarer = [
      patients.patient as unknown as Carer,
      ...patients.relatives.filter(({ id }) => id !== currentPatientId),
    ]
  }
  if (!patientsCouldBeCarer?.length || insertCarer) {
    return (
      <InsertRelativeCarer
        onFinish={(c) => {
          props.onFinish(c)
        }}
        partialCarer={insertCarer}
        onCancel={() => (insertCarer ? setInsertCarer(null) : props.onCancel())}
      />
    )
  }
  return (
    <Card title={languages.isCarerInTheList}>
      <PreviousButton onClick={() => props.onCancel()} />
      <CarerSelector
        carers={patientsCouldBeCarer}
        onSelect={(patient: Partial<Carer>) => {
          setInsertCarer(patient)
        }}
        onCancel={props.onCancel}
        notInTheList={() => setInsertCarer({})}
      />
    </Card>
  )
}
export const InsertRelativeCarer = connect(
  ({ carer }) => carer,
  (dispatch: Dispatcher): Partial<InsertCarerStore> => ({
    setRelationship: (payload: { carer; relationship; relativeId }) =>
      dispatch({ type: ADD_RELATIONSHIP_PATIENT_CARERS_REQUEST, payload }),
    patient_insert: (carer: Carer) =>
      dispatch({ type: ADD_PATIENT_CARERS_REQUEST, payload: carer }),
    pharma_insert: (carer: Carer) =>
      dispatch({ type: ADD_PHARMACY_CARERS_REQUEST, payload: carer }),
    clearMessage: () => dispatch({ type: CARERS_CLEAR_MESSAGE }),
  })
)(
  (
    props: Partial<InsertCarerProps & InsertCarerStore> & {
      partialCarer?: Partial<Carer>
      pharmacy?: boolean
    }
  ) => {
    const { languages } = useLanguage()
    const [newCarer, setNewCarer] = useState<Carer>()
    const [inputs, setInputs] = useState([])
    useEffect(() => {
      if (props.success && newCarer) {
        const carers = props.pharmacy
          ? props.pharmacy_carers
          : props.patient_carers
        const createdCarer = carers.find(
          (carer) =>
            carer.firstname.toLowerCase() ===
              newCarer.firstname.toLowerCase() &&
            carer.lastname.toLowerCase() === newCarer.lastname.toLowerCase()
        )
        if (createdCarer) {
          props.onFinish(createdCarer)
        }
      }
      props.clearMessage()
    }, [props.success])

    const insertCarer = (c: Carer) => {
      setNewCarer(c)
      if (props.pharmacy) props.pharma_insert(c)
      else props.patient_insert(c)
    }
    useEffect(() => {
      const fieldsUnknown = getUnFilledFields(props.partialCarer)

      setInputs(fieldsUnknown)
      if (!fieldsUnknown.length) {
        insertCarer(props.partialCarer as Carer)
      }
    }, [props.partialCarer])

    const getUnFilledFields = (carer: Partial<Carer>) => {
      const inputs = []
      if (!carer?.firstname)
        inputs.push({
          name: "firstname",
          required: true,
          label: languages.insertFirstnameCarer,
        })
      if (!carer?.lastname)
        inputs.push({
          name: "lastname",
          required: true,
          label: languages.insertLastnameCarer,
        })
      if (!carer?.phone)
        inputs.push({
          name: "phone",
          label: languages.insertPhoneCarer,
          required: true,
        })
      return inputs
    }

    if (!inputs?.length) return <Loader />
    return (
      <Card subtitle={languages.addingCarer}>
        <Message message={props.message} />
        <FormPage
          inputs={inputs}
          prospect={props.partialCarer}
          acceptText={languages.next}
          onFinish={(carer: Carer) => {
            const c = { ...props.partialCarer, ...carer }
            insertCarer(c)
          }}
          loading={props.loading}
          onCancel={() => {
            props.clearMessage()
            props.onCancel()
          }}
        />
      </Card>
    )
  }
)
