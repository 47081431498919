import React, { useState } from "react"
import { getCountries, getCountryCallingCode } from "react-phone-number-input"
import Flags from "country-flag-icons/react/3x2"
import styles from "./PhoneInput.module.scss"
import { GenericModal, Modal } from "components/Modal/Modal"
import { Button } from "antd"
import countryNames from "react-phone-number-input/locale/fr.json"
import { useLanguage } from "locales"

const authorizedCountries: string[] = [
  // International
  // "GB", // angleterre
  // "ES", // espagne
  // "DE", // allemagne
  // "US", // us
  // "IT", // italia
  // "PT", // Portugal
  // // Francophonie
  // "FR",
  // "CH", // suisse
  // "CA", // canada
  // "LU", // Luxembourg
  // "BE", // Belgique
  // // DOM
  // "GP", // guadeloupe
  // "YT", // Mayotte
  // "MQ", // Martinique
  // "GF", // guinée francaise
  // "RE", // reunion
  // // MAGREBH
  // "MA", // maroc
  // "AL", // algérie
  // "TN", // Tunisie
  // "LY", // Libye
  // "EH", // Western Sahara
  // AFRIQUE
  // "CM", // cameroun
  // "CD", // rep.dem.congo
  // "CG", // congo
]

interface CountrySelectProps {
  disabled?: boolean
  onChange: (country: string) => void
  value: string
}
export const CountrySelect: React.FC<CountrySelectProps> = (
  props
): JSX.Element => {
  const { languages } = useLanguage()
  const [visibility, setVisibility] = useState(false)
  const CurrentFlag = Flags[props.value]
  const countriesList = getCountries()
    // .filter((e: string) => authorizedCountries.includes(e))
    .map((country: string) => {
      const CountryFlag = Flags[country]

      return (
        <div className={styles.FlagContainer} key={country}>
          <Button
            className={`${styles.ButtonFlag} ${styles.ButtonSelector}`}
            onClick={() => {
              props.onChange(country)
              setVisibility(false)
            }}
          >
            <CountryFlag className={styles.Flag} />+
            {getCountryCallingCode(country)}
          </Button>
          {(countryNames as any)[country]}
        </div>
      )
    })

  return (
    <>
      <GenericModal
        noFooter
        closable
        onClose={() => {
          setVisibility(false)
        }}
        title={languages.choseYourCountry}
        visible={visibility}
      >
        <div className={styles.PopoverFrame}>{countriesList}</div>
      </GenericModal>
      <Button
        className={`${styles.ButtonFlag} ${styles.ButtonSelected}`}
        onClick={() => {
          !props.disabled && setVisibility(true)
        }}
      >
        <CurrentFlag className={styles.Flag} />
      </Button>
    </>
  )
}
