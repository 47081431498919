import { request } from "lib/request"
import { all, call, put, takeLatest } from "redux-saga/effects"
import { CustomerApi, ResponseSignup } from "types/payload"
import { Action, GFlow, GWatcher } from "types/redux"
import { SIGNUP_REQUEST } from "./constants"
import config from "react-global-configuration"
import signupActions from "./actions"
import { SET_CUSTOMER } from "client/services/constants"
import Compliance, { formatCustomerINSPayload } from "lib/api-compliance"
import { Customer } from "types/entity"

/*
 *  API REQUESTS
 */
async function signupApi(payload: CustomerApi): Promise<ResponseSignup> {
  return await request(config.get("patient.signup"), {
    method: "POST",
    payload,
  })
}
/*
 * FLOWS
 */
function* signupFlow({ payload }: Action<CustomerApi>): GFlow<ResponseSignup> {
  const formattedPayload = formatCustomerINSPayload(payload);
  try {
    const response: ResponseSignup = yield call(signupApi, formattedPayload)
    if (response) {
      yield all([
        // L'api ne nous renvoie pas le patient
        put({
          type: SET_CUSTOMER,
          payload: {
            ...new Compliance<CustomerApi, Customer>({
              ...formattedPayload,
              is_verified: false,
            }).please(),
          },
        }),
        put(signupActions.success()),
      ])
    }
  } catch (error) {
    console.error(error, {
      route: config.get("patient.signup")
    })
    yield all([put(signupActions.error(error as string))])
  }
}

function* signupWatcher(): GWatcher {
  yield takeLatest(SIGNUP_REQUEST, signupFlow)
}

export default signupWatcher
