import { Steps as AntdSteps } from "antd"
import { concat, find } from "lodash"
import React from "react"

export const Steps: React.FC<{
  array: unknown[]
  current: string | number
}> = ({ array, current }): JSX.Element => {
  const _length = concat(["home"], [...array])

  const getCurrent = (): number => {
    let realCurrent = 0

    if (typeof current === "string") {
      const keys: Array<number | undefined> = _length.map((item, key) => {
        if (item === current) return key
      })
      realCurrent = (find(keys, (key) => key) ?? 0) as number
    } else realCurrent = current + 1

    return realCurrent
  }
  return (
    <div style={{ position: "absolute", top: "38px", left: "75px" }}>
      <AntdSteps progressDot current={getCurrent()}>
        {_length.map((_v, key) => (
          <AntdSteps.Step key={key} />
        ))}
      </AntdSteps>
    </div>
  )
}
