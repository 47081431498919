import { Checkbox, Col, Divider, Row } from "antd"
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import { find, first } from "lodash"
import React, { useEffect, useState } from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { Button } from "components/Button"
import { Buttons, Card } from "components/Card/Card"
import PreviousButton from "components/Previous/PreviousButton"
import Consent from "containers/Consent/Consent"
import { PaymentCard } from "containers/Stripe"
import { PATH } from "containers/Survey/models"
import actionsSurvey from "containers/Survey/services/actions"
import { Answer } from "containers/Survey/types"
import { cdn } from "core/cdn"
import { CALL_PAGE, SURVEY_PAGE } from "core/constants"
import { AvailableDoctor, DoctorType } from "types/entity"
import { Dispatcher, Message } from "types/redux"
import { PaymentStore } from "types/store"

import { logTerminalInformation } from "../../client/socket/actions"
import styles from "./Payment.module.scss"
import actions from "./services/actions"
import { useLanguage } from "locales"

const enum PaymentState {
  INITIALIZE = "INITIALIZE",
  EXEMPTED = "EXEMPTED",
  PAYMENT = "PAYMENT",
}

const Payment = (props: PaymentStore) => {
  const { languages } = useLanguage()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [paymentState, setPaymentState] = useState(PaymentState.INITIALIZE)
  const [exempted, setExempted] = useState(false)
  const [consentVisibility, setConsentVisibility] = useState(false)
  const answers = useSelector(({ survey }) => survey.answers)
  const [consentChoice, setConsentChoice] = useState<boolean>(false)

  useEffect(() => {
    // Retrieve terminal information and send them to API
    if (props.success) {
      dispatch(logTerminalInformation())
    }
  }, [props.success])
  // réception des réponses du questionnaire
  useEffect(() => {
    if (!props.answers) {
      // Nous n'avons pas accès aux réponses du questionnaire
      // Le patient ne peux pas lancer de TLC
      navigate(SURVEY_PAGE)
    } else {
      // Nous vérifions l'état de son exonération
      // Par défaut, false, puis nous mettons à jour l'état de la page
      // exmption ? PaymentState.EXEMPTED : PaymentState.PAYMENT
      const exempted = props.answers.find(
        (answer: Answer) =>
          answer.questionId === PATH.exemption &&
          Array.isArray(answer.value) &&
          first(answer.value) === true
      )
      const hasSelectedDoctorReply = props.answers.find(
        (answer: Answer) =>
          answer.questionId === PATH.choice_of_doctor &&
          Array.isArray(answer.value) &&
          typeof first(answer.value) === "object"
      )
      if (hasSelectedDoctorReply) {
        const chosenDoctor = first(hasSelectedDoctorReply?.value) as
          | AvailableDoctor
          | undefined
        if (chosenDoctor?.category_id === DoctorType.LABOUR_DOCTOR)
          setExempted(true)
        else {
          // Si le patient a choisi un docteur qui n'est pas
          // médecin du travail, on passe par la page de paiement
          setExempted(false)
        }
      } else setExempted(!!exempted)
    }
  }, [props.answers])

  // Réception du prix
  useEffect(() => {
    const relative = find(
      props.answers,
      (answer) => answer.questionId === PATH.relative
    )
    !props.price &&
      props.getPrice({ relativeId: first(relative?.value) as string })
  }, [props.price])

  useEffect(() => {
    if (props.success) {
      navigate(CALL_PAGE)
      props.reset()
    }
  }, [props.success])

  switch (paymentState) {
    case PaymentState.PAYMENT:
    default:
      return (
        <Card
          titleSize="small"
          title={languages.youWillLaunchATLC}
          message={props.message}
        >
          <PreviousButton
            onClick={() => {
              setPaymentState(PaymentState.INITIALIZE)
            }}
            to={SURVEY_PAGE}
          />
          <div className={styles.PaymentInformation}>
            <Row align="middle" justify="center" gutter={[10, 10]}>
              <Col span={4}>
                <img src={cdn("/images/paiement_picto.svg")} alt="" />
              </Col>
              <Col span={20}>
                {props?.price?.price && (
                  <span className={styles.PaymentInformationText}>
                    {languages.price} : <b>{props?.price?.price} €</b>{" "}
                    <i>{props?.price?.content}</i>
                  </span>
                )}
              </Col>
            </Row>
          </div>
          <Divider />
          <PaymentCard
            onPay={(pay: Answer[]) => {
              const send = [...answers, ...pay]
              props.tlcRequest(send)
            }}
          />
        </Card>
      )
    case PaymentState.INITIALIZE:
    case PaymentState.EXEMPTED:
      return (
        <Card
          title={languages.putHeadPhones}
          subtitle={!exempted && languages.nextMeansYouAcceptCGU}
          imgUrl={cdn("/images/casque_illustration.svg")}
          message={props.message}
        >
          <PreviousButton
            onClick={() => {
              props.reset()
              dispatch(actionsSurvey.previous())
            }}
            to={SURVEY_PAGE}
          />
          {exempted ? (
            <Buttons direction="vertical">
              <div style={{ marginTop: "-80px"}}>
                <Checkbox
                  checked={consentChoice}
                  onChange={(event: CheckboxChangeEvent) => {
                    setConsentChoice(event.target.checked)
                  }}
                >
                  {languages.IConfirmMyConsentDataChoiceExempte}
                  <span
                    className="more-information"
                    onClick={(ev) => {
                      ev.preventDefault()
                      setConsentVisibility(true)
                    }}
                  >
                    {" "}
                    {languages.MoreDetails}
                  </span>
                </Checkbox>
              </div>
            </Buttons>
          ) : null}
          <Buttons>
            <Button
              type="primary"
              disabled={exempted ? !consentChoice : props.loading}
              loading={props.loading}
              onClick={() => {
                exempted && props.tlcRequest(answers)
                !exempted && setPaymentState(PaymentState.PAYMENT)
              }}
            >
              {exempted ? languages.createCall : languages.next}
            </Button>
          </Buttons>
          <Consent
            visible={consentVisibility}
            onCancel={() => {
              setConsentVisibility(false)
            }}
            onAccept={() => {
              setConsentChoice(true)
              setConsentVisibility(false)
            }}
          ></Consent>
        </Card>
      )
  }
}

const mapDispatchToProps = (dispatch: Dispatcher): Partial<PaymentStore> => {
  return {
    getPrice: ({ relativeId }: { relativeId?: string }) =>
      dispatch(actions.priceRequest({ relativeId })),
    tlcRequest: (a: Answer[]) => dispatch(actions.submit(a)),
    reset: () => dispatch(actions.reset()),
  }
}
// Give the store inside the props
export default connect(
  ({
    payment,
    stripe,
  }: {
    payment: PaymentStore
    stripe: { message?: Message }
  }) => {
    return {
      ...payment,
      message: payment.message ?? stripe.message,
    }
  },
  mapDispatchToProps
)(Payment)
