import { Button } from "components/Button"
import { Buttons } from "components/Card/Card"
import { Title } from "components/Title/Title"
import { cdn } from "core/cdn"
import { ModalProps } from "types/props"

import * as DOMPurify from "dompurify";

import { GenericModal } from "./Modal"
import { useLanguage } from "locales"

export const WarningModifyInformationModal: React.FC<ModalProps> = (
  props
): JSX.Element => {
  const { languages } = useLanguage()
  return (
    <GenericModal {...props} noFooter style={{ width: "80%" }}>
      <div className="flex-column flex-center" style={{ padding: "0 100px" }}>
        <img
          width="150"
          style={{ marginBottom: "20px" }}
          src={cdn("images/caution_picto.svg")}
          alt=""
        />
        <Title text={languages.warning} />
        <p
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(languages.modalContent_changePatientInformation_html, { USE_PROFILES: { html: true }}),
          }}
        />
        <Buttons size="large" direction="vertical">
          <Button type="primary" wide="long" onClick={props.onCancel}>
            {languages.cancel}
          </Button>
          <Button danger type="link" onClick={props.onAccept}>
            {languages.modifyDatas}
          </Button>
        </Buttons>
      </div>
    </GenericModal>
  )
}
