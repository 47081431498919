import { useState } from "react"
import { Subject } from "rxjs"

export interface OurFormInstance {
  on: (nameEvent: "reset" | "set", func: (args: any) => void) => void
  reset: (payload: string[]) => void
  set: (payload: any) => void
}

function on<T>(event: Subject<T>, func: (payload: T) => void) {
  event.subscribe({
    next: (payload: T) => {
      func(payload)
    },
  })
}

export default function useOurForm() {
  const _resetEvent = new Subject<string[] | undefined>()
  const _setEvent = new Subject<any>()
  const [ourForm] = useState({
    on: async (wish: string, func: (a: any) => void) => {
      switch (wish) {
        case "reset":
          on(_resetEvent, func)
          break
        case "set":
          on(_setEvent, func)
          break
        default:
          break
      }
    },
    reset: (inputs?: string[]) => _resetEvent.next(inputs),
    set: (inputs?: any) => _setEvent.next(inputs),
  })

  return ourForm
}
