import React from "react"
import { Row, Col } from "antd"
import { Customer } from "types/entity"
import { PROFILE_PAGE, VERIFY_PAGE } from "core/constants"
import { cdn } from "core/cdn"
import styles from "./Header.module.scss"
import { useNavigate, useLocation } from "react-router-dom"
import FlagButton from "containers/Language/LanguageButton"
import Support from "components/Support"


const Header: React.FC<{ patient?: Customer, showLanguageButton?: boolean }> = ({ patient, showLanguageButton }): JSX.Element => {
  const location = useLocation()
  const navigate = useNavigate()
  const doNotdisplayUserProfile = [VERIFY_PAGE, PROFILE_PAGE].includes(
    location.pathname
  )
  return (
    <Row>
      <Col span={10}></Col>
      <Col span={4} style={{ textAlign: "center" }}>
        {patient?.is_verified && !doNotdisplayUserProfile && (
          <span
            onClick={() => navigate(PROFILE_PAGE)}
            className={styles.ProfileLink}
          >
            <img height="30px" src={cdn("/images/people_picto.svg")} alt="" />
            <span className={styles.Nowrap}>
              {patient.firstname} {patient.lastname}
            </span>
          </span>
        )}
      </Col>
      <Col span={showLanguageButton? 8 : 9}></Col>
      <Col span={1} style={{ textAlign: "right", paddingRight: "5px"}}>
        <Support/>
      </Col>
      {showLanguageButton && <Col span={1} style={{ textAlign: "right" }}>
        <FlagButton/>
      </Col>
      }
    </Row>
  )
}

export default Header
