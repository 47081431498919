import { AvailableDoctor } from "types/entity"
import React from "react"

import styles from "./Doctors.module.scss"

export const compareAvailability = (a: AvailableDoctor, b: AvailableDoctor) => {
  if (a.is_available && !b.is_available) return -1
  if (!a.is_available && b.is_available) return 1
  return 0
}

export const getFilteredDoctorsByRequest = (
  doctors: AvailableDoctor[],
  request: string
): AvailableDoctor[] => {
  const requestLower = request.toLowerCase()
  const filteredList: AvailableDoctor[] = []
  doctors.forEach(function (doctorObject) {
    const name = doctorObject.firstname + " " + doctorObject.lastname

    const toLowerCase = name.toLowerCase()
    const removeAccent = toLowerCase
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")

    if (removeAccent.indexOf(requestLower) > -1) {
      filteredList.push(doctorObject)
    }
  })
  filteredList.sort((a, b) => compareAvailability(a, b))
  return filteredList
}

export const analyzeNameFromRequest = (name: string, request?: string) => {
  if (request) {
    const analyzed = name
      .toUpperCase()
      .replace(request, `<span style="color:#4381E5">${request}</span>`)
    return `Dr <br/><b>${analyzed}</b>`
  } else return `Dr <br/><b>${name}</b>`
}

export const AnalyzeNameFromRequest: React.FC<{
  request?: string
  name: string
}> = (props): JSX.Element => {
  const analyzed = props.name
    .toUpperCase()
    .replace(props.request || "", `::${props.request}::`)
  const [firstPart, requestor, secondPart] = analyzed.split("::")

  return (
    <span>
      Dr <br />
      <b>{firstPart}</b>
      <u className={styles.Requestor}>{requestor}</u>
      <b>{secondPart}</b>
    </span>
  )
}
