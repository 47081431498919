import { cdn } from "core/cdn"
import { useState } from "react"
import styles from "./support.module.scss"
import QRCode from "react-qr-code"
import { GenericModal } from "components/Modal/Modal"
import { languages } from "locales/languages"

const qrCodeUrl = "https://medadom.zendesk.com/hc/fr?preopen_messenger"


const Support=()=> {
  const [open, setOpen] = useState(false)

  return (
    <>
    <div className={styles.support} role="button" onClick={()=>setOpen(true)}>
    <img width="100%" src={cdn("/images/picto_question.png")} />
    </div>
    {open &&
    <GenericModal
            onClose={()=>setOpen(false)}
            closable
            visible={open}
            noFooter={true}
            style={{ width: "60vw"}}
            onCancel={()=>setOpen(false)}
            title={
            <div className={styles.title__block}>
             <h1 className={styles.title__text}>{languages.scanTogetSupport}</h1>
            </div>
            }
          >
        <div style={{display: "flex", flexDirection: "column", justifyContent: "space-evenly", width: "850px", margin: "auto", height: "600px"}}>
        <div
          style={{
            height: "auto",
            margin: "0 auto",
            maxWidth: 350,
            width: "100%",
          }}
        >
          <QRCode
            size={256}
            style={{ height: "auto", width: "100%", margin: "auto" }}
            value={qrCodeUrl}
            viewBox={`0 0 256 256`}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "auto",
            width: "100%",
          }}
        >
            <div style={{color: "#211452", fontSize: "28px", fontWeight: 600}}>{languages.youCanContactSupport}</div>
            <div style={{color: "#9B4F86", fontSize: "28px", fontWeight: 600}}>{languages.mondayToSaturday}</div>
            <div style={{color: "#9B4F86", fontSize: "28px", fontWeight: 600}}>{languages.atSupportNumber}</div>
        </div>

        </div>
      </GenericModal>}
    </>
  
  )
}

export default Support;