import { Relative, Customer } from "types/entity"
import { ClientStore, NirReaderResponse } from "types/store"

const getCustomer = ({
  client,
}: {
  client: ClientStore
}): Customer | undefined => client.customer

const getRelatives = ({ client }: { client: ClientStore }): Relative[] => {
  const relatives: Relative[] = client.relatives || []
  return relatives
}
const getRelative =
  (relativeId: number) =>
  ({ client }: { client: ClientStore }): Relative | undefined => {
    if (client.relatives) {
      const relative: Relative | undefined = client.relatives.find(
        ({ id }) => id === relativeId
      )
      return relative
    } else {
      return undefined
    }
  }

const getNirReaderResponse = ({
  client,
}: {
  client: ClientStore
}): NirReaderResponse | undefined => {
  return client.nirReaderResponse
}
export { getCustomer, getRelatives, getRelative, getNirReaderResponse }
