import { notification } from "antd"
import React, { useEffect } from "react"

import { Information } from "../../components/Title/Title"
import { Message as MessageInterface } from "../../types/redux"
import styles from "./Message.module.scss"

interface MessageProps {
  message?: MessageInterface
}

export const openNotification = (message: MessageInterface) => {
  notification[message.type]({
    message: "",
    className: styles.Message,
    duration: message.delay ?? 5,
    description: (
      <Information style={{ marginBottom: "0px" }} text={message.text} />
    ),
  })
}
const Message: React.FC<MessageProps> = ({ message }) => {
  useEffect(() => {
    if (message) openNotification(message as MessageInterface)
  }, [message])
  return <></>
}

export default Message
