import {
  DOCUMENTS_SUCCESS,
  DOCUMENTS_ERROR,
  DOCUMENTS_REQUEST,
  SEND_DOCUMENTS_REQUEST,
  SEND_DOCUMENTS_SUCCESS,
  SEND_DOCUMENTS_ERROR,
} from "./constants"
import { DocumentType } from "client/documents/types"
import { Action, Message } from "types/redux"

export const actions = {
  success: (response: string): Action<string> => {
    return {
      type: DOCUMENTS_SUCCESS,
      payload: response,
    }
  },
  error: (error: string): Action<Message> => {
    return {
      type: DOCUMENTS_ERROR,
      payload: { type: "error", text: error || "Error document" },
    }
  },

  // Request(callid, documentsType) if documentsType = undefined, get * available documents by default
  request: (
    callId: string | number,
    documentsType?: DocumentType[]
  ): Action<{ documentsType?: DocumentType[]; callId: string | number }> => ({
    type: DOCUMENTS_REQUEST,
    payload: { documentsType, callId },
  }),

  sendDocumentsRequest: (payload: { id: number }): Action<{ id: number }> => ({
    type: SEND_DOCUMENTS_REQUEST,
    payload,
  }),

  sendDocumentsSuccess: (s: string): Action<Message> => ({
    type: SEND_DOCUMENTS_SUCCESS,
    payload: { type: "success", text: s },
  }),

  sendDocumentsError: (e: string): Action<Message> => ({
    type: SEND_DOCUMENTS_ERROR,
    payload: { type: "error", text: e },
  }),
}
