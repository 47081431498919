// LOGIN
export const LOGOUT = "LOGOUT"
export const LOGIN_REQUEST = "LOGIN_REQUEST"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_ERROR = "LOGIN_ERROR"
export const RESET_LOGIN_STORE = "RESET_LOGIN_STORE"
export const TOGGLE_2FA = "TOGGLE_2FA"
export const RESEND_2FA = "RESEND_2FA"
export const VERIFY_2FA = "VERIFY_2FA"
export const CANCEL_2FA = "CANCEL_2FA"
export const _2FA_INITIAL_STEP_SUCCESS = "_2FA_INITIAL_STEP_SUCCESS"
export const _2FA_RESEND_SUCCESS = "_2FA_RESEND_SUCCESS"
export const _2FA_RESEND_ERROR = "_2FA_RESEND_ERROR"
export const _2FA_VERIFY_ERROR = "_2FA_VERIFY_ERROR"
export const ASKING_FOR_2FA_VERIFY = "ASKING_FOR_2FA_VERIFY"
export const ASKING_FOR_2FA_TOGGLE = "ASKING_FOR_2FA_TOGGLE"
export const _2FA_TOGGLE_ERROR = "_2FA_TOGGLE_ERROR"
export const _2FA_CANCELED = "_2FA_CANCELED"
export const REQUEST_ONGOING ="REQUEST_ONGOING"
export const RESEND_REQUEST_SUCCESS = "RESEND_REQUEST_SUCCESS"
export const TWO_FA_VERIFY_ERROR = "TWO_FA_VERIFY_ERROR"
