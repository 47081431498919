import config from "react-global-configuration";
import { Dayjs } from "dayjs"
import { BirthPlace } from "types/entity";
import { API_DATE_FORMAT } from "lib/utils";
import { getCurrentLanguage } from "locales/languages";
const FRENCH_LOCATION = ['city_district', 'municipality', 'oversea_community_municipality'];
const FOREIGN_LOCATION = ['foreign_territory'];
const UNKNOWN_INSEE_CODE = "99999";

export const getDefaultLocalisationList = (birth_location = `${BirthPlace.FRANCE}`, searchedValue:string) => {
const fr = getCurrentLanguage()==="fr"

 return [
  {
    fullname_with_suffix : fr? "Inconnu" : "Unknown",
    insee_code: searchedValue? UNKNOWN_INSEE_CODE : "99999"
  }
];
}

interface locationsPayload {
    created_at: string,
    fullname: string,
    fullname_with_junction: string,
    fullname_with_suffix: string,
    insee_code: string,
    location_type: string,
    name: string,
}

export default function searchLocation(searchedValue: string = "", birthdate, selectedBirthLocation = `${BirthPlace.FRANCE}`){
    const bd = (birthdate && typeof birthdate==='string') ?
    `${birthdate[4]}${birthdate[5]}${birthdate[6]}${birthdate[7]}-${birthdate[2]}${birthdate[3]}-${birthdate[0]}${birthdate[1]}` 
    : birthdate ? (birthdate as Dayjs).format(API_DATE_FORMAT) : ""

    const payload = {
        capitalize_article: true,
        name: searchedValue,
        location_types: selectedBirthLocation === `${BirthPlace.FRANCE}` ? FRENCH_LOCATION : FOREIGN_LOCATION,
    }
    if(bd){
        payload["date"] = bd
    }
    return fetch(`/api${config.get("locations")}`, {
        method: "POST",
        headers: {'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    }).then((res: any) => {
        return res.json();
    })
    .then((r: { status: string, locations: Array<locationsPayload>}) => {
        if(r.status === "ok") {
            return r.locations;
        } else {
            return getDefaultLocalisationList(selectedBirthLocation, searchedValue);
        }
    })
    .catch((error) => {
        console.error(error, {
            route: `${config.get("locations")}`
        });
        return getDefaultLocalisationList(selectedBirthLocation, searchedValue);
    })
}