import { Col, Row } from "antd"
import React from "react"
import { CardInterface } from "types/stripe"

import { Brand } from "./Brand"
import styles from "./Stripe.module.scss"

export const Card: React.FC<{ card: CardInterface }> = (props) => {
  return (
    <Row style={{ alignItems: "center" }} gutter={[20, 20]}>
      <Col>
        <Brand width="80px" brand={props.card.brand} />
      </Col>
      <Col>
        <span className={styles.LastNumber}>{props.card.brand}</span>
      </Col>
      <Col>
        <span className={styles.LastNumber}>
          <b>{props.card.last4}</b>
        </span>
      </Col>
    </Row>
  )
}
