import { put, takeLatest, select } from "@redux-saga/core/effects"
import config from "react-global-configuration"
import {
  getCurrentSelectedPatientId,
} from "containers/Survey/services/selector"
import {
  SUBMIT_DOCUMENT_FAILURE,
  SUBMIT_DOCUMENT_ONGOING,
  SUBMIT_DOCUMENT_REQUEST,
  SEND_ME_UPLOAD_LINK_REQUEST,
  SEND_ME_UPLOAD_LINK_VIA_SMS_REQUEST,
  FETCH_NEW_DOCUMENT_REQUEST
} from "./constants"
import { savePdf } from "../utils"
import { handleApiErrors } from "lib/api-errors"
import actions from "./actions"
import moment from "moment"
import { isThisDocNewlyUpdated } from "containers/Survey/utils"
import { request } from "lib/request"

function createFile(filename: string, fileContent: string, type: string, relative_id: string = "") {
  const myFile = new File([fileContent], filename, {
    type: `application/pdf`,
  })
  const file = new FormData()
  file.append("file", myFile)
  file.append("type", type)
  if(relative_id) file.append("relative_id", relative_id)
  return file
}

function* requestLink(){
  const currentCustomerId = yield select(getCurrentSelectedPatientId)
  
  const isPatient = (currentCustomerId === 0 )|| (currentCustomerId === undefined)
  const baseUrl = config.get("patient.uploadLink")
  const url = isPatient ? 
    `${baseUrl}`
    :`${baseUrl}?relative_id=${currentCustomerId.toString()}`

  yield put({
    type: SUBMIT_DOCUMENT_ONGOING,
  })

  try {
    const response = yield request(url, {
      method: "GET",
    })
    if (response.status === "ok") {
      yield put(actions.QrcodeLinkSuccess({
        url : response.link,
        created_at: new Date(),
        expiration: moment().unix() + 900,
        SendBySms : false
        }))
    }
  } catch(err) {
    console.error(err, {
      route: url
    })
    yield put({
      type: SUBMIT_DOCUMENT_FAILURE,
      payload: { type: "error", text: err },
    })
  }
}

function * sendLinkViaSms(action){

  // const token =action.payload
  const currentCustomerId = yield select(getCurrentSelectedPatientId)
  yield put({
    type: SUBMIT_DOCUMENT_ONGOING,
  })

  try {
    const baseUrl = config.get("patient.linkBySms")
    const isPatient = (currentCustomerId === 0 )|| (currentCustomerId === undefined)
    const url = isPatient ?
      `${baseUrl}`
      :`${baseUrl}?relative_id=${currentCustomerId.toString()}`
    const response = yield request(
      url, {
      method: "GET",
    })
    if (response.status === "ok") {
      yield put(actions.linkViaSmsSuccess())
    }
  } catch(err) {
    console.error(err, {
      route: config.get("patient.linkBySms")
    })
    yield put({
      type: SUBMIT_DOCUMENT_FAILURE,
      payload: { type: "error", text: err },
    })
  }
}

function* uploadDocument(action) {
  const currentCustomerId = yield select(getCurrentSelectedPatientId)
  const { name, file, medicalType } = action.payload
  const pdffile = yield savePdf(file)

  yield put({
    type: SUBMIT_DOCUMENT_ONGOING,
  })


  try {
    let filedata = {} as FormData

    if (currentCustomerId === 0 || currentCustomerId === undefined )
    {
      filedata = createFile(name, pdffile, medicalType)
    }else {
      filedata = createFile(name, pdffile, medicalType, currentCustomerId.toString())
    }

    yield fetch(`api/documents`, {
      method: "POST",
      headers: {
        "X-User-Token": sessionStorage.getItem("X-USER-Token") || "",
      },
      body: filedata,

    }).then(handleApiErrors)

    yield put(actions.success())

  } catch (e) {
    console.error(e, {
      route: "saga::uploadDocument"
    })
    yield put({
      type: SUBMIT_DOCUMENT_FAILURE,
      payload: { type: "error", text: e },
    })
  }
}
function* fetchNewDocument(action){
  const currentCustomerId = yield select(getCurrentSelectedPatientId)
  try {
    const response = yield request(`${config.get("patient.uploadedDocuments")}`, {
      method: "GET",
    })
    const CreatedRecently =  response.filter((doc) => isThisDocNewlyUpdated(doc, action.payload));
    if (currentCustomerId === 0)
    {
      const customerNewDocs = CreatedRecently
                  .filter(res => res.relative_id === null)

      const NDC : number = parseInt(localStorage.getItem("NDC")) | 0
      if (customerNewDocs.length > NDC){
        localStorage.setItem("NDC", customerNewDocs.length)
      yield put (actions.newDocsAreAvailable(customerNewDocs.length))}
    }
    else if (currentCustomerId === undefined ){
      yield put (actions.goBackToSurvey())
    }
    else {
      const NDR : number = parseInt(localStorage.getItem("NDR")) | 0
      const relativesNewDocs = CreatedRecently
                  .filter( res => res.relative_id === currentCustomerId)
      if (relativesNewDocs.length > NDR){
        yield put (actions.newDocsAreAvailable(relativesNewDocs.length))}
    }
  } catch(err) {
    console.error(err, {
      route: `${config.get("patient.uploadedDocuments")}`
    })
  }
}

export default function* rootSaga() {
  yield takeLatest(SUBMIT_DOCUMENT_REQUEST, uploadDocument)
  yield takeLatest(SEND_ME_UPLOAD_LINK_REQUEST, requestLink)
  yield takeLatest(SEND_ME_UPLOAD_LINK_VIA_SMS_REQUEST, sendLinkViaSms)
  yield takeLatest(FETCH_NEW_DOCUMENT_REQUEST, fetchNewDocument)
}
