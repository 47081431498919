import { ArrowLeftOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { HOME_PAGE } from "core/constants"
import style from "./PreviousButton.module.scss"
import { LOGOUT } from "containers/Login/services/constants"
import React from "react"
import { useLanguage } from "locales"
interface PreviousProps {
  onClick?: () => void
  text?: string
  reset?: boolean
  to?: string
  danger?: boolean
}

const PreviousButton: React.FC<PreviousProps> = ({
  onClick,
  text,
  to,
  reset = false,
  danger,
}) => {
  const { languages } = useLanguage()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const click = () => {
    if (onClick) {
      onClick()
    }
    if (to) {
      navigate(to)
    } else if (reset) {
      dispatch({ type: LOGOUT })
      navigate(HOME_PAGE)
    } else {
      !onClick && navigate(-1)
    }
  }
  return (
    <span
      className={`${style.Previous} ${danger && style.Danger}`}
      onClick={click}
    >
      <ArrowLeftOutlined />
      {text || languages.previous}
    </span>
  )
}

export default PreviousButton
