import { ElementType } from "react"
import { SurveyStore } from "types/store"
import { UploadDocStore } from "types/store"

export type UiView =
  | "carousel"
  | "checkbox"
  | "selector"
  | "inline"
  | "icon"
  | "fat-button"
  | "button"
export type UiPosition = "left" | "right" | "middle"
export type UiStyle = "primary" | "default" | "accent" | "warning" | "info"
export type Value = string | number | boolean | undefined | unknown

export enum ACTION {
  default = "default",
  subquestion = "subquestion",
  redirect = "redirect",
  nothing = "nothing",
  inability = "inability",
}
export enum QUESTION_TYPE {
  redirect = "redirect",
  default = "question",
}

export interface UiSurvey {
  view?: UiView
  position?: UiPosition
}

export interface QuestionLanguage {
  id: string
  patient?: { title?: string; subtitle?: string; defaultReply?: string }
  relative?: { title?: string; subtitle?: string; defaultReply?: string }
}
export interface AlertMessage {
  type: "info" | "warning" | "danger" | "success"
  description?: string | JSX.Element
}
export interface Question {
  title: string
  subtitle?: string
  id: string
  subquestion?: Question
  multipleReplies?: boolean
  replies: Reply[]
  defaultReply?: Reply
  informationText?: string
  ui?: UiSurvey
  imgUrl?: string
  // if type === redirect,
  // the object need a value
  // it means the step is not a question but an action, redirect to an other page.
  // (Nir page for instance)
  alert?: AlertMessage
  type?: QUESTION_TYPE
  value?: string
}
interface Ui {
  style?: UiStyle
  type?: "link" | "text" | "ghost" | "default" | "primary" | "dashed"
  view?: UiView
}
export interface ReplyAction {
  value: Value
  title: string
  action?: ACTION
  imgUrl?: string
  icon?: JSX.Element
  ui?: Ui
  preselect?: boolean
}
export interface Reply extends ReplyAction {
  additionalActions?: ReplyAction[]
}

export type Status = "processing" | "answered" | "to_ask"

export interface Answer {
  value: Value[]
  questionId: string
}

export interface SurveyProps extends SurveyStore {
  answer: Value[]
  redirect: boolean
  loading: boolean
  previous: () => void
  next: () => void
  set: (r: Reply[]) => void
  unset: (r?: Reply[]) => void
  cancel: () => void
  reset: () => void
  resetReplies: () => void
  submit: (a: Answer[]) => void
  history: { push: (a: string) => void }
  getUploadedDocuments: () => void
  setStepReplies: (payload) => void
}

export interface Step {
  title: string
  subtitle?: string
  id: string
  multipleReplies?: boolean
  replies: Reply[]
  defaultReply?: Reply
  servicesToDisplay?: string
  ui?: UiSurvey
  imgUrl?: string[] | string
  type?: QUESTION_TYPE
  value?: string
}

export interface DocType {
  type: string
  imgUrl: string
  validated: boolean
}

export interface UploadedDocumentType {
  medicalType: Value
  name: string
  relativeId?: string
  file: string[]
}

export interface UploadDocProps extends UploadDocStore {
  answer: Value[]
  redirect: boolean
  loading: boolean
  previous: () => void
  addImage: (imgSrc: DocType[]) => void
  submitDocument: (doc: UploadedDocumentType) => void
  sendMeLink: (link) => void
  generateQrCode: () => void
  fetchNewDocuments: (age) => void
  next: () => void
  set: (r: Reply[]) => void
  unset: (r?: Reply[]) => void
  cancel: () => void
  reset: () => void
  resetStatus: () => void
  submit: (a: Answer[]) => void
  history: { push: (a: string) => void }
  documents: UploadedDocumentType[]
}
