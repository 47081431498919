import React, { useEffect, useState } from "react"
import styles from "./Footer.module.scss"
import Clock from "components/Clock/Clock"

import { Medadom } from "components/Logo/Medadom"
import { HOME_PAGE } from "core/constants"
import { useLanguage } from "locales"

const Footer: React.FC = () => {
  const { languages } = useLanguage()

  const [supportStyle, setSupportStyle] = useState({});
  let footerWidth = {};
  useEffect(() => {
    if(window.location.pathname === HOME_PAGE) {
      setSupportStyle({
        color: "#211452",
        fontSize: "18px",
        display: "block",
      })
      footerWidth = {width: "40%"};
    } else {
      if(supportStyle && supportStyle['display'] !== "none") {
        setSupportStyle({display: "none"});
      }
    }

  }, [window.location.pathname])

  return (
    <div className={styles.Footer} style={footerWidth} data-testid="Footer">
      <a href="/">
        <Medadom />
      </a>
      <span style={supportStyle}>{languages.supportNumber}</span>
      <span className={styles.Clock}>
        <Clock />
      </span>
    </div>
  )
}
export default Footer
