import { Tabs, Card as AntCard } from "antd"
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { Card } from "components/Card/Card"
import { GenericModal } from "components/Modal/Modal"
import PreviousButton from "components/Previous/PreviousButton"
import { cdn } from "core/cdn"
import { HOME_PAGE } from "core/constants"
import { TutoState } from "types/props"
import { useLanguage } from "locales"

import styles from "./Tuto.module.scss"
import { languages } from "locales/languages"
import { items } from "./models"


const { Meta } = AntCard;

interface ItemProps {
  videoList: VideoProps[]
  displayVideo: (Video: VideoProps) => void;
}
const TabItemPage = ({ videoList, displayVideo }: ItemProps) => {

  return (
    <div style={{display: "flex", justifyContent: "space-around", alignItems: "center", paddingTop: "50px", paddingBottom: "50px", width: "1400px"}}>
        {videoList.map(({title, description, cover, url}) =>
            <VideoCard 
                key={title}
                title={title}
                description={description}
                cover={cover}
                onClick={()=>displayVideo({title, description, cover, url})}
            />
        )}
    </div>
  )
}

const VideoCard = ({title, description, url, cover, onClick }:{title:string, description:string, url?: string, cover: string; onClick: ()=>void;}) => {
  return <AntCard
    onClick={onClick}
    style={{ width: 427, borderRadius: "15px", cursor: "pointer", boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)"}}
    cover={
    <div style={{borderRadius:"15px 15px 0 0"}}>
        <img src={cover} alt={description} width="100%" style={{borderRadius:"15px 15px 0 0"}}/>
    </div>
    }
  >
    <Meta
      title={<span style={{color: "#211452", fontWeight: "bold", fontSize: "24px"}}>{title}</span>}
      description={<span style={{color: "#211452", fontSize: "18px", fontWeight: 400}}>{description}</span>}
    />
  </AntCard>
};

interface TutoProps {
  onChoice: (state: TutoState) => void
}
interface VideoDisplayerModalProps {
  open: boolean;
  onClose: ()=> void;
  selectedVideo: VideoProps;
  tabVideoList: VideoProps[];
}

const VideoDisplayerModal=({selectedVideo, open, onClose, tabVideoList}: VideoDisplayerModalProps)=>{
  const { languages } = useLanguage()
  const [displayed, setDisplayed] = useState(selectedVideo)
  const [hide, setHide] = useState({ 
    left: tabVideoList.findIndex((video)=>video.url===selectedVideo.url)<=0,
    right: tabVideoList.findIndex((video)=>video.url===selectedVideo.url)>= tabVideoList.length - 1
  })

  const selectPreviousTab = () =>{
    const index = tabVideoList.findIndex((video)=>video.url===displayed.url)
    if(index <= 0){
      return
    }
    const newVideo = tabVideoList[index - 1]
    setDisplayed(newVideo)
  }

  const selectNextTab = () => {
    const index = tabVideoList.findIndex((video)=>video.url===displayed.url)
    if(index >= tabVideoList.length - 1){
      return
    }
    const newVideo = tabVideoList[index + 1]
    setDisplayed(newVideo)
  }

  useEffect(()=>{
    setHide({ 
      left: tabVideoList.findIndex((video)=>video.url===displayed.url)<=0,
      right: tabVideoList.findIndex((video)=>video.url===displayed.url)>= tabVideoList.length - 1
    })
  }, [displayed, tabVideoList, setHide])

  return (
    <GenericModal
        style={{ padding: "10px", maxWidth: "80vh" }}
        cancelText={languages.previous}
        onClose={onClose}
        title={displayed?.title}
        visible={open}
        noFooter
        closable
      >
        <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center"}}>
        <CaretLeftOutlined
          style={{
            color: hide.left? "#E0E0E0" : "#27224F",
            fontSize:"65px",
            marginRight: "20px",
            cursor: "pointer"
          }}
          onClick={selectPreviousTab}
          disabled={hide.left}
          />
          <div key={displayed.url} >
            <video
              key={displayed.url}
              style={{ borderRadius:"15px", width: "1300px", border: "1px solid black"}}
              controls
            >
              <source src={displayed.url} type="video/mp4" />
            </video>
          </div>
          <CaretRightOutlined
            style={{
              color: hide.right? "#E0E0E0" : "#27224F",
              fontSize:"70px",
              marginLeft: "20px",
              cursor: "pointer"
            }}
            onClick={selectNextTab}
            disabled={hide.right}
          />
        </div>
      </GenericModal>
  )
}

interface VideoProps{
  title:string, description:string, url:string, cover:string;
}

export const Tutorial: React.FC<TutoProps> = () => {
  const [activeKey, setActiveKey] = useState("1")
  const [open, setOpen] = useState(false)
  const [selectedVideo, setSelectedVideo] = useState<VideoProps|null>()
  const [pageVideoList, setPageVideoList] = useState<VideoProps[]|null>(items[0].videoList)

  const navigate = useNavigate()

  const selectPreviousTab = () =>{
    const index = items.findIndex(item => item.key === activeKey)
    if(index <= 0){
      return
    }
    const newKey = items[index - 1].key
    setPageVideoList(items[index - 1].videoList)
    setActiveKey(newKey)
  }

  const selectNextTab = () => {
    const index = items.findIndex(item => item.key === activeKey)
    if(index >= items.length - 1){
      return
    }
    const newKey = items[index + 1].key
    setPageVideoList(items[index + 1].videoList)
    setActiveKey(newKey)
  }

  const handleDisplayVideo =(video: VideoProps)=>{
    setSelectedVideo({...video})
    setOpen(true)
  }

  const handleChangeTab=(key:string)=>{
    setActiveKey(key)
    const item = items.find(item => item.key === key)
    setPageVideoList(item.videoList)
  }

  return (
      <>
      <Card title={languages.videoManual} style={{ maxWidth: "85vw"}}>
      <PreviousButton onClick={()=> navigate(HOME_PAGE)} />
      <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: "50px", paddingBottom: "50px"}}>
      <CaretLeftOutlined
          style={{
            color: items.findIndex((item)=>item.key===activeKey)<=0? "#E0E0E0" : "#27224F",
            fontSize:"65px",
            marginRight: "20px", 
          }}
          onClick={selectPreviousTab}
          disabled={items.findIndex((item)=>item.key===activeKey)<=0}
      />
      <Tabs activeKey={activeKey} onChange={handleChangeTab}>
        {items.map(item =>
          <Tabs.TabPane
            tab={<span style={{color: "#27224F", fontSize: "24px"}}>{item.label}</span>}
            key={item.key}
          >
            <TabItemPage key={item.key} videoList={item.videoList} displayVideo={handleDisplayVideo}/>
          </Tabs.TabPane>
        )}
      </Tabs>
      <CaretRightOutlined
          style={{
            color: items.findIndex((item)=>item.key===activeKey)>= items.length - 1? "#E0E0E0" : "#27224F",
            fontSize:"70px",
            marginLeft: "20px"
          }}
          onClick={selectNextTab}
          disabled={items.findIndex((item)=>item.key===activeKey)>= items.length - 1}
      />
      </div>
    </Card>
      {open &&
      <VideoDisplayerModal 
          open={open}
          tabVideoList={pageVideoList}
          selectedVideo={selectedVideo}
          onClose={()=>{
            setOpen(false);
            setSelectedVideo(null)
          }}/>
      }
      </>
  )
}
