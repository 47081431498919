import { first } from "lodash"
import config from "react-global-configuration"

import { DOCTOR_AVAILABLE, PATH } from "containers/Survey/models"
import { Answer, Value } from "containers/Survey/types"
import { AvailableDoctor, DoctorType } from "types/entity"
import { Call, CallRequest, ResponsePrice } from "types/payload"
import { Action, ActionWithoutPayload, Message } from "types/redux"

import { PAYMENT_REQUIRES_ACTION, PRICE_ERROR, PRICE_REQUEST, PRICE_SUCCESS, RESET_PAYMENT_STORE, TLC_ERROR, TLC_REQUEST, TLC_SUCCESS } from "./constants"
import { languages } from "locales/languages"

const actions = {
  reset: (): ActionWithoutPayload => ({ type: RESET_PAYMENT_STORE }),
  submit: (answers?: Answer[]): Action<CallRequest> => {
    if (!answers)
      return { type: TLC_REQUEST, payload: { ...config.get("mock.get.call") } }

    const complyResponse = (wantedId: string): Value | undefined => {
      const responses: Value[] | undefined =
        answers.find(({ questionId }) => questionId === wantedId)?.value ||
        undefined
      if (!responses) return undefined
      const complied =
        responses.length > 1 ? responses.join(", ") : first(responses)

      return complied
    }

    const uploadDocumentResponse = (wantedId: string): Value | undefined => {
      const responses: Value[] | undefined =
        answers.find(({ questionId }) => questionId === wantedId)?.value ||
        undefined
      if (!responses) return undefined
      return responses
    }

    // @BUSINESS-SIDE
    const getDoctorChoice = () => {
      const doctors = answers.find(
        ({ questionId }) => questionId === PATH.choice_of_doctor
      )?.value as AvailableDoctor[] | undefined
      const doctor = first(doctors) as AvailableDoctor | undefined

      // doctor =
      // - mon médecin n'est pas dispo doctor_available === true
      // - je n'ai pas de médecin traitant doctor_available === false
      const doctor_available_response =
        typeof complyResponse(PATH.choice_of_doctor_doctor_available) ===
        "boolean"
          ? complyResponse(PATH.choice_of_doctor_doctor_available)
          : complyResponse(PATH.doctor_available)

      if (!doctor)
        return {
          // [doctor] est une valeur indiquant si le patient a un médecin traitant (true || false)
          doctor:
            typeof doctor_available_response === "boolean"
              ? doctor_available_response
              : DOCTOR_AVAILABLE.EXISTS_BUT_UNAVAILABLE,
          // [doctor disponible]
          doctor_available: false,
        }

      const exemptedByLabourDoctor = doctor.category_id ===
        DoctorType.LABOUR_DOCTOR && { exemption: true }

      return {
        doctor_id: doctor.id,
        clinic_id: doctor.clinic_id,
        doctor: true,
        doctor_available: true,
        ...exemptedByLabourDoctor,
      }
    }
    const payload: CallRequest = {
      ...config.get("mock.call"),
      relative_id: complyResponse(PATH.relative),
      medical: {
        illness: complyResponse(PATH.illness),
        illness_details: complyResponse(PATH.illness_details),
        allergies: complyResponse(PATH.allergy),
        allergies_details: complyResponse(PATH.allergy_details),
        operations: complyResponse(PATH.operation),
        pregnant: complyResponse(PATH.pregnant),
        drugs: complyResponse(PATH.drug),
        reason: complyResponse(PATH.reason),
      },
      documents: uploadDocumentResponse(PATH.selected_documents),
      payment_method_id: complyResponse("payment_method_id"),
      payment_intent_id: complyResponse("payment_intent_id"),
      exemption: complyResponse(PATH.exemption) ?? false,
      share_personal_data: complyResponse(PATH.consent) ?? true,
      carer_guid: complyResponse(PATH.selected_carer),
      // Le patient a choisi sont docteur ?
      // on créer une payload businessSide
      ...getDoctorChoice(),
    }
    return {
      type: TLC_REQUEST,
      payload,
    }
  },

  error: (error: string): Action<Message> => ({
    type: TLC_ERROR,
    payload: { text: error || languages.cannotStartTLC, type: "error" },
  }),

  success: (payload: Call): Action<Call> => ({
    type: TLC_SUCCESS,
    payload,
  }),
  paymentRequiresAction: (
    payment_intent_client_secret: string
  ): Action<string> => ({
    type: PAYMENT_REQUIRES_ACTION,
    payload: payment_intent_client_secret,
  }),
  priceRequest: (payload: {
    relativeId?: string
  }): Action<{ relativeId?: string }> => ({
    type: PRICE_REQUEST,
    payload,
  }),
  priceError: (payload: string): Action<Message> => ({
    type: PRICE_ERROR,
    payload: { type: "error", text: payload },
  }),
  priceSuccess: (payload: ResponsePrice): Action<ResponsePrice> => ({
    type: PRICE_SUCCESS,
    payload,
  }),
}

export default actions
