import { Action, ActionWithoutPayload, Message } from "types/redux"
import {
  CANCEL_SIGNUP_ERROR,
  CANCEL_SIGNUP_REQUEST,
  CANCEL_SIGNUP_SUCCESS,
  SEND_AGAIN_ERROR,
  SEND_AGAIN_REQUEST,
  SEND_AGAIN_SUCCESS,
  SET_PHONE_CODE_ERROR,
  SET_PHONE_CODE_REQUEST,
  SET_PHONE_CODE_SUCCESS,
  RESET_VERIFYPHONE_STORE,
} from "./constants"

const actions = {
  request: (payload: {
    code: string
    phone: string
  }): Action<{ code: string; phone: string }> => ({
    type: SET_PHONE_CODE_REQUEST,
    payload,
  }),
  success: (success: string): Action<Message> => ({
    type: SET_PHONE_CODE_SUCCESS,
    payload: { type: "success", text: success },
  }),
  error: (error: string): Action<Message> => ({
    type: SET_PHONE_CODE_ERROR,
    payload: { type: "error", text: error },
  }),
  cancelRequest: (): ActionWithoutPayload => ({ type: CANCEL_SIGNUP_REQUEST }),
  cancelSuccess: (payload: string): Action<Message> => ({
    type: CANCEL_SIGNUP_SUCCESS,
    payload: { type: "success", text: payload },
  }),
  cancelError: (error: string): Action<Message> => ({
    type: CANCEL_SIGNUP_ERROR,
    payload: { type: "error", text: error },
  }),
  reset: () => ({ type: RESET_VERIFYPHONE_STORE }),
  sendAgainRequest: (): ActionWithoutPayload => ({ type: SEND_AGAIN_REQUEST }),
  sendAgainSuccess: (payload: string): Action<Message> => ({
    type: SEND_AGAIN_SUCCESS,
    payload: { type: "success", text: payload },
  }),
  sendAgainError: (payload: string): Action<Message> => ({
    type: SEND_AGAIN_ERROR,
    payload: { type: "error", text: payload },
  }),
}
export default actions
