import { Statistics } from "types/payload"
import { Action, Message } from "types/redux"
import { StatisticsStore } from "types/store"
import { GET_STATISTICS_SUCCESS } from "./constants"

const initialState: Partial<StatisticsStore> = {
  loading: false,
  success: false,
}
const reducer = function statisticsReducer(
  state = initialState,
  action: Action<Statistics | Message>
): Partial<StatisticsStore> {
  switch (action.type) {
    case GET_STATISTICS_SUCCESS:
      return { ...state, statistics: action.payload as Statistics }
    default:
      return { ...state }
  }
}

export default reducer
