export const [
    CREATE_STEP,
    INIT_DOCUMENT_LOAD,
    NEXT_STEP,
    PREVIOUS_STEP,
    SUBMIT_DOCUMENT_REQUEST,
    RESET_UPLOAD_STORE,
    ADD_DOCUMENTS_TO_STORE,
    QUESTION_DOCUMENT,
    GET_ALL_DOCUMENT,
    SUBMIT_DOCUMENT_ONGOING,
    SUBMIT_DOCUMENT_SUCCESS,
    SUBMIT_DOCUMENT_FAILURE,
    RESET_UPLOAD_STATUS,
    SEND_ME_UPLOAD_LINK_REQUEST,
    QR_CODE_LINK_SUCCESS,
    SEND_ME_UPLOAD_LINK_VIA_SMS_REQUEST,
    UPLOAD_LINK_VIA_SMS_REQUEST_SUCCESS,
    FETCH_NEW_DOCUMENT_REQUEST,
    NEW_DOCS_TRUE,
    GO_BACK_TO_SURVEY,
  ] = [
    "CREATE_STEP",
    "INIT_DOCUMENT_LOAD",
    "NEXT_STEP",
    "PREVIOUS_STEP",
    "SUBMIT_DOCUMENT_REQUEST",
    "RESET_UPLOAD_STORE",
    "ADD_DOCUMENTS_TO_STORE",
    "QUESTION_DOCUMENT",
    "GET_ALL_DOCUMENT",
    "SUBMIT_DOCUMENT_ONGOING",
    "SUBMIT_DOCUMENT_SUCCESS",
    "SUBMIT_DOCUMENT_FAILURE",
    "RESET_UPLOAD_STATUS",
    "SEND_ME_UPLOAD_LINK_REQUEST",
    "QR_CODE_LINK_SUCCESS",
    "SEND_ME_UPLOAD_LINK_VIA_SMS_REQUEST",
    "UPLOAD_LINK_VIA_SMS_REQUEST_SUCCESS",
    "FETCH_NEW_DOCUMENT_REQUEST",
    "NEW_DOCS_TRUE",
    "GO_BACK_TO_SURVEY",
  ]
  
  export const [
    STATUS_RESOLVED,
    STATUS_REJECTED,
    STATUS_PENDING,
  ] = [
    "RESOLVED",
    "REJECTED",
    "PENDING"
  ]

  export const [
    WEB_IMPORT,
    MOBILE_IMPORT,
    SMS_LINK,
    WEBCAM
  ] = [
    "WEB",
    "MOBILE",
    "SMSLINK",
    "WEBCAM"
  ]