import { Doctor } from "types/entity"
import { Action, ActionWithoutPayload, Message } from "types/redux"
import {
  GET_DOCTORS_ERROR,
  GET_DOCTORS_REQUEST,
  GET_DOCTORS_SUCCESS,
  DOCTORS_RESET_STORE,
} from "./constants"

const actions = {
  get: (): ActionWithoutPayload => ({ type: GET_DOCTORS_REQUEST }),
  success: (payload: Doctor[]): Action<Doctor[]> => ({
    type: GET_DOCTORS_SUCCESS,
    payload,
  }),
  error: (payload: string): Action<Message> => ({
    type: GET_DOCTORS_ERROR,
    payload: { type: "error", text: payload },
  }),
  reset: (): ActionWithoutPayload => ({ type: DOCTORS_RESET_STORE }),
}

export default actions
