import { Modal } from "antd"
import React, { useEffect, useState } from "react"
import config from "react-global-configuration"
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"

import { Button } from "components/Button"
import { Buttons, Card } from "components/Card/Card"
import { FormPage } from "components/Form/Form"
import { Code, CodeInputProps } from "components/Input/Code/Index"
import PreviousButton from "components/Previous/PreviousButton"
import { Steps } from "components/Steps"
import { cdn } from "core/cdn"
import { LOBBY_PAGE, PROFILE_PAGE, RESET_PASSWORD_PAGE } from "core/constants"
import { Keyboard } from "hocs/withKeyboard"
import { PhoneCredential, twoFaData, Verify2Fa } from "types/payload"
import { Dispatcher, Message } from "types/redux"
import { LoginStore } from "types/store"

import styles from "./Login.module.scss"
import { activateFa, cancel2FaRequest, loginRequest, resend2FaRequest, resetLogin } from "./services/actions"
import { verify2FaRequest } from "./services/actions"
import { useLanguage } from "locales"

const LOGIN_STEP = "LOGIN_STEP"
const _2FA_STEP = "_2FA_STEP"
const TOGGLE_STEP = "TOGGLE_STEP"

const ResendBtn = ({
  text,
  Img,
  onClick,
}: {
  text: string
  Img: string
  onClick: () => void
}): JSX.Element => {
  return (
    <div className={styles.twoFaModalBtn} onClick={onClick}>
      <img className={styles.resendBtnImage} src={Img} />
      <span>{text}</span>
    </div>
  )
}

const TwoFaModal = (props: any): JSX.Element => {
  const { languages } = useLanguage()
  const { showModal, hasValidEmail, handleCloseModal, handleResendBy } = props
  return (
    <>
      <Modal
        width="100%"
        centered
        closable={false}
        title={
          <p className={styles.modalTitle}>
            {languages.twoFa_choseMethodForResend}
          </p>
        }
        open={showModal}
        onCancel={handleCloseModal}
        footer={[
          <Buttons
            key="resend"
            style={{ paddingTop: 0, marginTop: 0, marginBottom: "60px" }}
          >
            <ResendBtn
              text={languages.sms}
              Img={cdn("images/sms_picto.svg")}
              onClick={() => handleResendBy("sms")}
            />
            <ResendBtn
              text={languages.call}
              Img={cdn("images/voice_picto.svg")}
              onClick={() => handleResendBy("voice")}
            />
            {hasValidEmail ? (
              <ResendBtn
                text={languages.Email}
                Img={cdn("images/mail_picto.svg")}
                onClick={() => handleResendBy("email")}
              />
            ) : null}
          </Buttons>,
          <Buttons key="cancel">
            <Button wide="long" onClick={handleCloseModal}>
              {languages.cancel}
            </Button>
          </Buttons>,
        ]}
      />
    </>
  )
}

interface twoFaProps {
  handle2faSubmit: (code) => void
  handleResend: (value: string) => void
  cancel2FaLogin: () => void
  message: Message
  twoFaData: twoFaData
  loading: boolean
}

const TwoFaPage: React.FC<twoFaProps> = ({
  handle2faSubmit,
  handleResend,
  cancel2FaLogin,
  message,
  twoFaData,
  loading,
}): JSX.Element => {
  const { languages } = useLanguage()
  const INITIAL_TIME = config.get("timeout.TwoFa_CodeResend_timer")
  const [code, setCode] = useState("")
  const [showModal, setShowModal] = useState<boolean>(false)
  const [errorMessage, SetErrorMessage] = useState<string | null>()
  const [subtitleCompletion, setSubtitleCompletion] = useState<string>(``)
  const [hasValidEmail, SethasValidEmail] = useState<boolean>(
    twoFaData?.has_valid_email ? true : false
  )
  const [displayButton, setDisplayButton] = useState<boolean>(false)
  const [timer, setTimer] = useState<number>(INITIAL_TIME)

  useEffect(() => {
    if (twoFaData?.transport) {
      switch (twoFaData?.transport) {
        default:
        case "sms":
          return setSubtitleCompletion(`${languages.bySMSAt} ${twoFaData.phone}`)
        case "email":
          return setSubtitleCompletion(languages.byEmail)
        case "voice":
          return setSubtitleCompletion(`${languages.byPhoneAt} ${twoFaData.phone}`)
      }
    }
    if (twoFaData?.phone) {
      setSubtitleCompletion(`${languages.bySMSAt} ${twoFaData.phone}`)
    }
  }, [twoFaData])

  useEffect(() => {
    if (isSendable(code) && message?.text === languages.twoFa_invalidCode) {
      // flush when incorrect code is submitted
      setCode("")
    }
  }, [message, code])

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer === 0) {
        setDisplayButton(true)
        return clearInterval(interval)
      } else {
        setTimer((timer) => timer - 1)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [timer, showModal])

  const isSendable = (code: string) => {
    const OnlyNumbers = /^\d+$/.test(code)
    const sixChar = code.length === 6
    if (OnlyNumbers && sixChar) return true
    return false
  }

  const handleCodeChange = (code: string) => {
    if (code !== "") SetErrorMessage(null)
    setCode(code)
    if (isSendable(code)) {
      handle2faSubmit(code)
    }
  }

  const codeProps = {
    onChange: (code) => setCode(code),
    numberOfChar: 6,
  }

  const handleCloseModal = (event) => {
    event.preventDefault()
    setShowModal(false)
  }

  const handleResendBy = (value: string) => {
    setShowModal(false)
    handleResend(value)
    setTimer(INITIAL_TIME)
    setDisplayButton(false)
  }

  const validateCode = (e) => {
    e.preventDefault()
    if (isSendable(code)) {
      handle2faSubmit(code)
    } else {
      SetErrorMessage(languages.twoFa_enterValideCode)
    }
  }

  return (
    <>
      <Steps array={[1, 2, 3]} current={3} />
      <PreviousButton onClick={cancel2FaLogin} />
      <Card
        title={languages.twoFa_Titre}
        subtitle={`${languages.twoFa_enterRecievedCode} ${subtitleCompletion}`}
        message={message}
      >
        <form className={styles.twoFaForm} onSubmit={validateCode}>
          <Keyboard
            options={{
              type: "twoFa",
              inputName: "twoFa",
            }}
            value={code}
            onChange={handleCodeChange}
          >
            <Code value={code} {...(codeProps as unknown as CodeInputProps)} readOnly/>
            {errorMessage ? (
              <div className={styles.errorMessage}>{errorMessage}</div>
            ) : null}
          </Keyboard>
          <Buttons style={{ marginTop: "-50px" }}>
            <Button
              type="primary"
              wide="long"
              disabled={loading}
              loading={loading}
            >
              {languages.validate}
            </Button>
          </Buttons>
        </form>
        <Buttons style={{ paddingTop: 10 }}>
          {displayButton ? (
            <Button type="link" size="small" onClick={() => setShowModal(true)}>
              {languages.twoFa_resendCode}
            </Button>
          ) : (
            <div className={styles.ResendInTime}>
              {`${languages.sendBackTheCode}  ${timer} ${
                timer <= 1 ? "SECONDE" : "SECONDES"
              }`}
            </div>
          )}
        </Buttons>

        <TwoFaModal
          {...{
            showModal,
            hasValidEmail,
            handleCloseModal,
            handleResendBy,
          }}
        />
      </Card>
    </>
  )
}

export const Login: React.FC<LoginStore> = (props): JSX.Element => {
  const { languages } = useLanguage()
  const { loading, twoFaData, activate2Fa, message } = props
  const isDataAvailable = loading === false && twoFaData !== undefined
  const [step, setStep] = useState(LOGIN_STEP)
  const navigate = useNavigate()

  useEffect(() => {
    if (props.success) {
      props.reset()
      navigate(PROFILE_PAGE)
    }
    if (isDataAvailable && !activate2Fa) {
      setStep(_2FA_STEP)
    }
    if (isDataAvailable && activate2Fa) {
      setStep(TOGGLE_STEP)
    }
  }, [props, isDataAvailable, activate2Fa])

  const handleLogin = (values: PhoneCredential) => {
    props.login(values)
  }

  const handle2faSubmit = (token: string) => {
    if (!twoFaData) props.reset()
    props.verify2Fa({
      token: token,
      mfa_verify_id: twoFaData.mfa_verify_id,
      toggle: twoFaData.toggle,
    })
  }

  const handleResend = (value: string) => {
    const data = {
      ...twoFaData,
      toggle: twoFaData.toggle,
      transport: value,
    }
    props.resend2fa(data)
  }

  const cancel2FaLogin = () => {
    props.cancel2Fa({ mfa_verify_id: twoFaData.mfa_verify_id })
  }

  const inputs = [
    { name: "phone", required: true },
    { name: "password", required: true },
  ]

  switch (step) {
    case LOGIN_STEP:
      return (
        <Card subtitle={languages.loginTitle} message={message}>
          <FormPage
            inputs={inputs}
            onFinish={(v) => handleLogin(v as PhoneCredential)}
            acceptText={languages.login}
            loading={props.loading}
            onCancel={() => navigate(LOBBY_PAGE)}
          />
          <Buttons style={{ paddingTop: 20 }}>
            <Button
              type="link"
              size="small"
              onClick={() => navigate(RESET_PASSWORD_PAGE)}
            >
              {languages.forgottenPassword}
            </Button>
          </Buttons>
        </Card>
      )
    case TOGGLE_STEP:
      return (
        <>
          <PreviousButton text={languages.cancel} reset />
          <Card
            title={languages.twoFa_activateTitle}
            subtitle={languages.twoFa_activateSubtitle}
            imgUrl={cdn("images/two_fa_picto.svg")}
          >
            <Buttons style={{ paddingTop: 20 }}>
              <Button
                type="primary"
                wide="long"
                size="large"
                onClick={() => props.activateFa(twoFaData)}
              >
                ACTIVER
              </Button>
            </Buttons>
          </Card>
        </>
      )
    case _2FA_STEP:
      return (
        <TwoFaPage
          {...{
            handle2faSubmit,
            handleResend,
            cancel2FaLogin,
            message,
            twoFaData,
            loading: props.loading,
          }}
        />
      )
  }
}

const mapStateToProps = (state: { login: LoginStore }): LoginStore => {
  return state.login
}

const mapDispatchToProps = (dispatch: Dispatcher): Partial<LoginStore> => ({
  login: (credentials: PhoneCredential) => dispatch(loginRequest(credentials)),
  verify2Fa: (data: Verify2Fa) => dispatch(verify2FaRequest(data)),
  resend2fa: (value: twoFaData) => dispatch(resend2FaRequest(value)),
  cancel2Fa: (value: { mfa_verify_id: string }) =>
    dispatch(cancel2FaRequest(value)),
  reset: () => dispatch(resetLogin()),
  activateFa: (data: twoFaData) => dispatch(activateFa(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
