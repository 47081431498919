import { useEffect, useState } from "react"
import config from "react-global-configuration"
import { LocalAudioTrack, LocalTrack, LocalVideoTrack } from "twilio-video"

import { Button } from "components/Button"
import { Buttons, Card } from "components/Card/Card"
import PreviousButton from "components/Previous/PreviousButton"
import { Information } from "components/Title/Title"
import { createUserLocalTracks } from "containers/Call/utils"
import { cdn } from "core/cdn"
import { areConnectedDeviceOff } from "../Call/utils/index"
import { useLanguage } from "locales"
interface DiagnosisProps {
  onFinish: () => void
  onStop: () => void
}
const getStep = (languages) => {
  return {
    INITIALIZING: {
      id: "INITIALIZING",
      title: "",
      Content: (props: any) => <></>,
    },
    DEVICES_SUCCESS: {
      id: "DEVICES_SUCCESS",
      title: languages.diagnosis_title_success,
      Content: (props: any) => (
        <Buttons>
          <img
            width="140"
            src={cdn("/icons/camera_access_picto.svg")}
            alt="camera  ok !"
          />
          <img
            width="140"
            src={cdn("/icons/micro_access_picto.svg")}
            alt="micro ok !"
          />
        </Buttons>
      ),
    },
    WAITING_DEVICES_ACCESS: {
      id: "WAITING_DEVICES_ACCESS",
      title: languages.diagnosis_title_waiting_device,
      Content: (props: any) => (
        <>
          <Buttons>
            <img
              width="140"
              src={cdn("/icons/camera_picto.svg")}
              alt="camera unauthorized"
            />
            <img
              width="140"
              src={cdn("/icons/micro_picto.svg")}
              alt="micro unauthorized"
            />
            <Information text={languages.diagnosis_information_waiting_device} />
          </Buttons>
          <div style={{ marginTop: "30px" }} className="center">
            <img
              height="300"
              src={cdn("/images/waiting_devices.gif")}
              alt="waiting devices"
            />
          </div>
        </>
      ),
    },
    PERMISSION_DENIED: {
      id: "PERMISSION_DENIED",
      title: languages.diagnosis_title_permission_denied,
      Content: (props: any) => (
        <>
          <Buttons>
            <img
              width="140"
              src={cdn("/icons/camera_denied_picto.svg")}
              alt="camera access denied"
            />
            <img
              width="140"
              src={cdn("/icons/micro_denied_picto.svg")}
              alt="micro access denied"
            />
            <Information
              text={languages.diagnosis_information_permission_denied}
            />
          </Buttons>
          <div style={{ marginTop: "30px" }} className="i-card">
            <img src={cdn("/icons/i_picto.svg")} alt="info" />
            {languages.diagnosis_contact_support}
            <b>{config.get("support")}</b>
          </div>
        </>
      ),
    },
    UNKNOWN_ERROR: {
      id: "UNKNOWN_ERROR",
      title: languages.diagnosis_title_unknown_error,
      Content: (props: any) => (
        <>
          <Buttons>
            <img
              width="140"
              src={cdn("/icons/camera_cross_picto.svg")}
              alt="camera error"
            />
            <img
              width="140"
              src={cdn("/icons/micro_cross_picto.svg")}
              alt="micro error"
            />
            <Information text={languages.diagnosis_information_unknown_error} />
          </Buttons>
          <div style={{ marginTop: "30px" }} className="i-card">
            <img src={cdn("/icons/i_picto.svg")} alt="info" />
            {languages.diagnosis_contact_support}
            <b>{config.get("support")}</b>
          </div>
        </>
      ),
    },
    TURN_OFF_DEVICES: {
      id: "TURN_OFF_DEVICES",
      title: <img
        width="280"
        src={cdn("/icons/dermatoscope.png")}
        alt="dertmatoscope"
      />,
      Content: (props: any) => (
        <>
          <Information style={{ lineHeight: "75px", fontSize: "35px" }} text={<b>{languages.deviceAreON}<br /> <span style={{ color: "#d64949" }}>{languages.turnThemOff}</span> </b>} />
        </>
      )
    }
  }
}
let timerInterval;

const checkLocalDevicesOff = (onFinish) => {
  navigator.mediaDevices.ondevicechange = () => {
    areConnectedDeviceOff()
      .then((devicesAreOff) => {
        if (devicesAreOff) {
          onFinish()
        }
      })
  };
}

const OneSecondInMs = 1000;

export const Diagnosis = (props: DiagnosisProps) => {
  const { languages } = useLanguage()
  const STEPS = getStep(languages)
  const [loading, setLoading] = useState(true)
  const [steps, setSteps] = useState(STEPS.WAITING_DEVICES_ACCESS)
  const [timer, setTimer] = useState(0);
  if (timer && !timerInterval) {
    let time = timer;
    timerInterval = setInterval(() => {
      time -= 1;
      setTimer(time)
    }, OneSecondInMs)
  }
  if (timer === 0 && timerInterval) {
    clearInterval(timerInterval);
    setLoading(false);
    setTimer(null);
    timerInterval = null;
  }

  useEffect(() => {
    checkLocalDevices()
  }, [])

  const checkLocalDevices = () => {
    setLoading(true)
    setSteps(STEPS.WAITING_DEVICES_ACCESS)
    createUserLocalTracks()
      .then((e) => {
        return e.forEach(
          (track: LocalVideoTrack | LocalAudioTrack) =>
            track.stop && track.stop()
        )
      })
      .then(() => {
        return areConnectedDeviceOff()
          .then((result) => {
            if (result) {
              props.onFinish();
            } else {
              setSteps(STEPS.TURN_OFF_DEVICES)
              checkLocalDevicesOff(props.onFinish);
              setTimer(10);
            }
          }
          )
          .catch((e) => {
            console.error(e);
            setLoading(false);
            props.onFinish();
          })
      })
      .catch((e) => {
        setLoading(false)
        if (e.message.includes("denied")) setSteps(STEPS.PERMISSION_DENIED)
        else setSteps(STEPS.UNKNOWN_ERROR)
      })
  }
  return (
    <Card title={steps.title}>
      <PreviousButton onClick={props.onStop} />
      <steps.Content />
      <Buttons>
        <Button
          disabled={loading}
          loading={loading}
          type="primary"
          onClick={() => {
            if (steps?.id === STEPS.TURN_OFF_DEVICES?.id) {
              props.onFinish()
            }
            else {
              checkLocalDevices()
            }
          }}
        >
          {
            `${timer && timer !== 0 ? timer : ""} `
          }
          {loading && languages.diagnosis_authorization_on_going}
          {!loading && steps?.id !== STEPS.TURN_OFF_DEVICES?.id && languages.retry}
          {!loading && steps?.id === STEPS.TURN_OFF_DEVICES?.id && languages.iTurnedDevicesOff}
        </Button>
      </Buttons>
    </Card>
  )
}
