import { ButtonProps } from "antd"
import styles from "./Medadom.module.scss"
import React from "react"
export const Medadom: React.FC<ButtonProps> = (props): JSX.Element => {
  return (
    <p {...props} className={styles.Medadom}>
      Medadom
      <span className={styles.BlueFrench}></span>
      <span className={styles.RedFrench}></span>
    </p>
  )
}
